import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { useTranslation } from "react-i18next";

const cssDescription = css`
  display: grid;
  color: ${theme.colors.neutral007};
  font-size: 0.875rem;
  grid-template-columns: repeat(auto-fill, minmax(min(36rem, 100%), 1fr));
`;

const Description = memo(function Description() {
  const { t } = useTranslation();
  return (
    <div css={cssDescription}>
      {t("organization.chatSettings.userRecognition.description")}
    </div>
  );
});

export { Description };
