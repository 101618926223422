import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import {
  type FC,
  type ReactNode,
  type Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { HIERARCHY_STRING_JOINER } from "@/appConstant";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { HighlightedCopyInput } from "@/components/Input";
import { Modal } from "@/components/Modal";
import type { QrCode } from "@/components/QrCode";
import { QrCodeBlock } from "@/components/QrCode/QrCodeBlock";
import { Select, type SelectProps, type SelectRef } from "@/components/Select";
import { ChannelSelect } from "@/components/Select/ChannelSelect";
import { TeamNameById } from "@/resources/team/TeamNameById";
import { UserNameById } from "@/resources/user/UserNameById";
import { defineStyles } from "@/shared/emotion";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

namespace ParamsContext {
  export interface Props {
    orgId: CantataTypes["Org"]["id"];

    /**
     * Team ID can be specified to hide the assignee selector.
     *
     * Set `NaN` as no team user.
     */
    teamId?: CantataTypes["Team"]["id"];

    userId: CantataTypes["User"]["id"];
  }
}

const ParamsContext = createContext<ParamsContext.Props>({
  name: "ParamsContext",
});

function useUserAssignmentQrcodeQueries() {
  const options = ParamsContext.useContext();
  const channelsQuery = cantata.channel.useList(
    {
      params: {
        orgId: options.orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      select: (data) => data.channels,
    },
  );
  const userQrcodesQuery =
    cantata.assignmentQrcode.useListUserAssignmentQrcodes(
      {
        params: {
          orgId: options.orgId,
          userId: options.userId,
        },
      },
      {
        suspense: true,
        useErrorBoundary: true,
        select: (data) => data.assignmentQrcodes,
      },
    );
  const usersQuery = cantata.user.useList(
    {
      params: {
        orgId: options.orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      select: (data) => data.users,
    },
  );
  const teamsQuery = cantata.team.useList(
    {
      params: {
        orgId: options.orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      select: (data) => data.teams,
    },
  );
  const userInTeamQrcodesQuery =
    cantata.assignmentQrcode.useListAllUserTeamsAssignmentQrcodes(
      {
        params: {
          orgId: options.orgId,
          userId: options.userId,
        },
      },
      {
        select: (data) => data.assignmentQrcodes,
        suspense: true,
        useErrorBoundary: true,
      },
    );
  const queries = useMemo(
    () => ({
      channels: channelsQuery,
      userQrcodes: userQrcodesQuery,
      users: usersQuery,
      teams: teamsQuery,
      userInTeamQrcodes: userInTeamQrcodesQuery,
    }),
    [
      channelsQuery,
      teamsQuery,
      userInTeamQrcodesQuery,
      userQrcodesQuery,
      usersQuery,
    ],
  );
  return queries;
}

const UserAssignmentQrcodeQueries =
  createQueriesContext<ReturnType<typeof useUserAssignmentQrcodeQueries>>();

const QueriesContextProvider: FC<{
  children: ReactNode;
}> = (props) => {
  const queries = useUserAssignmentQrcodeQueries();
  return (
    <UserAssignmentQrcodeQueries.Provider queries={queries}>
      {props.children}
    </UserAssignmentQrcodeQueries.Provider>
  );
};

const styles = defineStyles({
  modalContent: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 16,
    "&>*": {
      width: "100%",
    },
  }),
  descriptionBlock: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "&>*": {
      width: "100%",
    },
  }),
  title: css({
    marginBottom: 8,
    color: theme.colors.neutral009,
    fontSize: "0.875rem",
    fontWeight: 500,
  }),
  desc: css({
    marginBottom: 16,
    color: theme.colors.neutral007,
    fontSize: "0.875rem",
    fontWeight: 400,
  }),
});

const assigneeNoTeam = "user";
const assigneeTeamPrefix = "team-";

namespace AssigneeSelector {
  export type ValueType =
    | typeof assigneeNoTeam
    | `${typeof assigneeTeamPrefix}${CantataTypes["Team"]["id"]}`;
  export interface Props extends Omit<SelectProps<ValueType>, "mode"> {}
  export interface Ref extends SelectRef {}
}

const AssigneeSelector = forwardRef<
  AssigneeSelector.Ref,
  AssigneeSelector.Props
>(function AssigneeSelector(props, ref) {
  const value = useMemo(
    () =>
      props.options?.some((option) => option.value === props.value)
        ? props.value
        : assigneeNoTeam,
    [props.options, props.value],
  );
  return <Select {...props} value={value} ref={ref} />;
});

namespace Content {
  export interface Props
    extends Omit<ComponentProps<typeof Modal>, "children" | "footer"> {
    imperativeHandleRef?: Ref<{
      download: () => void;
    } | null>;
  }
}

const Content: FC<Content.Props> = ({ imperativeHandleRef }) => {
  const { t } = useTranslation();
  const params = ParamsContext.useContext();
  const { users, teams, channels, userQrcodes, userInTeamQrcodes } =
    UserAssignmentQrcodeQueries.useData();
  const localeCompare = useLocaleCompare();
  const mergeFormDisabled = useMergeFormDisabled();
  /**
   * If `teamId` is not specified, the team selector is enabled.
   */
  const [teamId, setTeamId] = useState<CantataTypes["Team"]["id"]>(
    params.teamId ?? Number.NaN,
  );
  const isTeamIdChangable = typeof params.teamId !== "number";
  const user = useMemo(
    () => users.find((user) => user.id === params.userId),
    [params.userId, users],
  );
  const teamsWithQrcode = useMemo(() => {
    return teams
      .filter((team) =>
        userInTeamQrcodes.some(
          (userQrcode) => userQrcode.teamId === team.id && userQrcode.qrcodeUrl,
        ),
      )
      .toSorted((a, b) => localeCompare(a.name, b.name));
  }, [localeCompare, teams, userInTeamQrcodes]);
  const teamOptions = useMemo<
    Array<
      NonNullable<AssigneeSelector.Props["options"]>[number] & {
        value: AssigneeSelector.ValueType;
      }
    >
  >(() => {
    return [
      {
        label: <UserNameById userId={params.userId} />,
        value: assigneeNoTeam,
      },
      ...teamsWithQrcode.map((team) => ({
        label: (
          <>
            <TeamNameById orgId={params.orgId} teamId={team.id} />
            {" - "}
            <UserNameById userId={params.userId} />
          </>
        ),
        value: `${assigneeTeamPrefix}${team.id}` as const,
      })),
    ];
  }, [params.orgId, params.userId, teamsWithQrcode]);

  const assignee = useMemo<AssigneeSelector.Props["value"]>(() => {
    return Number.isNaN(teamId) ||
      !teamOptions.some((option) => option.value === `team-${teamId}`)
      ? "user"
      : `team-${teamId}`;
  }, [teamId, teamOptions]);

  const assigneeSelectorDisabled = useMemo(
    () => mergeFormDisabled(!isTeamIdChangable),
    [isTeamIdChangable, mergeFormDisabled],
  );

  /**
   * Filters out channels that don't have a corresponding QR code.
   */
  const getAvailableChannels = useCallback(
    function getAvailableChannels(teamId: CantataTypes["Team"]["id"]) {
      return channels.filter((channel) =>
        Number.isNaN(teamId)
          ? userQrcodes.some(
              (qrCode) => qrCode.channel.id === channel.id && qrCode.qrcodeUrl,
            )
          : userInTeamQrcodes.some(
              (userQrcode) =>
                userQrcode.teamId === teamId &&
                userQrcode.channel.id === channel.id &&
                userQrcode.qrcodeUrl,
            ),
      );
    },
    [channels, userInTeamQrcodes, userQrcodes],
  );

  const setAssignee = useHandler<AssigneeSelector.Props["onChange"]>(
    (value) => {
      const nextTeamId: typeof teamId = (() => {
        if (value === assigneeNoTeam) return Number.NaN;
        return teamOptions.some((option) => option.value === value)
          ? Number(value.slice(assigneeTeamPrefix.length))
          : Number.NaN;
      })();
      if (teamId === nextTeamId) return;
      setTeamId(nextTeamId);
      const nextChannels = getAvailableChannels(nextTeamId);
      // If the current channel is in the team, keep it.
      if (nextChannels.some((channel) => channel.id === channelId)) return;

      // Select the first channel with QR code in the team by default.
      setChannelId(nextChannels[0]?.id ?? Number.NaN);
    },
  );

  const channelsWithQrCode = useMemo(
    () =>
      getAvailableChannels(teamId).toSorted((a, b) =>
        localeCompare(a.name, b.name),
      ),
    [getAvailableChannels, localeCompare, teamId],
  );

  const [channelId, setChannelId] = useState<CantataTypes["Channel"]["id"]>(
    /**
     * Select the first channel with QR code by default.
     */
    channelsWithQrCode[0]?.id ?? Number.NaN,
  );
  const channel = useMemo<
    undefined | NonNullable<typeof channels>[number]
  >(() => {
    if (!channelId) return undefined;
    return channelsWithQrCode.find((channel) => channel.id === channelId);
  }, [channelId, channelsWithQrCode]);
  const showQRCode = !Number.isNaN(channelId);
  const [qrCodeImperativeHandle, setQrCodeImperativeHandle] =
    useState<QrCode.ImperativeHandle | null>(null);
  const url = useMemo(() => {
    if (!channelId) return;
    return Number.isNaN(teamId)
      ? userQrcodes.find((qrCode) => qrCode.channel.id === channelId)?.qrcodeUrl
      : userInTeamQrcodes.find(
          (userQrcode) =>
            userQrcode.teamId === teamId && userQrcode.channel.id === channelId,
        )?.qrcodeUrl;
  }, [channelId, teamId, userInTeamQrcodes, userQrcodes]);

  const download = useHandler(function download() {
    if (!qrCodeImperativeHandle) return;
    const team = teams.find((team) => team.id === teamId);
    /**
     * Asana: [[P1] download qrcode show channel name + assignee name as the img file name](https://app.asana.com/0/0/1208779492285880/1208784591944462/f)
     *
     * - `{user} - {channel}`
     * - `{team} - {user} - {channel}`
     *
     * If an entity is not found, its ID will be used as a fallback.
     */
    const fileName = [
      ...(!teamId ? [] : [team?.name || teamId]),
      user?.name || params.userId,
      channel?.name || channelId,
    ].join(HIERARCHY_STRING_JOINER);
    qrCodeImperativeHandle.download(fileName);
  });

  /**
   * useMemo is required to prevent infinite loop.
   */
  const imperativeHandle = useMemo(
    () => ({
      download,
    }),
    [download],
  );

  useImperativeHandle(imperativeHandleRef, () => imperativeHandle, [
    imperativeHandle,
  ]);

  return (
    <div css={styles.modalContent}>
      <div css={styles.descriptionBlock}>
        <div css={styles.title}>
          {t("resource.assignmentQrcode.assigneeSelector.label")}
        </div>
        <AssigneeSelector
          options={teamOptions}
          value={assignee}
          onChange={setAssignee}
          disabled={assigneeSelectorDisabled}
        />
      </div>
      <div css={styles.descriptionBlock}>
        <div css={styles.title}>{t("myProfile.qrCodeChannel")}</div>
        <div css={styles.desc}>{t("myProfile.qrCodeModal.desc")}</div>
        <ChannelSelect
          channels={channelsWithQrCode}
          value={channel?.id ?? Number.NaN}
          onChange={setChannelId}
          placeholder={t("myProfile.selectChannel")}
        />
      </div>
      {!showQRCode ? null : !url ? (
        <Alert type="error" message="LINE channel's line id is not provide." />
      ) : (
        <>
          <QrCodeBlock
            style={{ width: 160, margin: "auto" }}
            text={url}
            imperativeHandleRef={setQrCodeImperativeHandle}
          />
          <HighlightedCopyInput value={url} />
        </>
      )}
    </div>
  );
};

namespace UserAssignmentQrcodeModal {
  export interface Props
    extends Overwrite<
      Omit<ComponentProps<typeof Modal>, "children" | "footer">,
      ParamsContext.Props
    > {}
}

type InferRef<T> = T extends Ref<infer R> ? R : never;

const UserAssignmentQrcodeModal: FC<UserAssignmentQrcodeModal.Props> = ({
  orgId,
  teamId,
  userId,
  ...props
}) => {
  const params = useMemo(
    () => ({
      orgId,
      ...(teamId === undefined ? {} : { teamId }),
      userId,
    }),
    [orgId, teamId, userId],
  );

  const { t } = useTranslation();
  const [contentImperativeHandle, setContentImperativeHandle] =
    useState<InferRef<NonNullable<Content.Props["imperativeHandleRef"]>>>(null);
  const open = useMemo(
    () =>
      !params.userId
        ? /**
           * If `userId` is falsy, modal is closed.
           */
          false
        : "open" in props
          ? /**
             * Props `open` overrides default behavior.
             */
            props.open
          : /**
             * Default behavior is to open the modal if `userId` is truthy.
             */
            Boolean(params.userId),
    [params.userId, props],
  );

  return (
    <ParamsContext.Provider value={params}>
      <Modal
        title={t("myProfile.createQRCode")}
        destroyOnClose
        {...props}
        open={open}
        onOk={
          !contentImperativeHandle
            ? undefined
            : contentImperativeHandle.download
        }
        okText={t("myProfile.downloadQRCode")}
        okButtonProps={
          contentImperativeHandle
            ? undefined
            : {
                style: {
                  display: "none",
                },
              }
        }
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        <ErrorBoundary.Alert>
          <QueriesContextProvider>
            <Content imperativeHandleRef={setContentImperativeHandle} />
          </QueriesContextProvider>
        </ErrorBoundary.Alert>
      </Modal>
    </ParamsContext.Provider>
  );
};

export { UserAssignmentQrcodeModal };
