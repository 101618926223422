import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { memo } from "@zeffiroso/utils/react/memo";
import type {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  Key,
  ReactNode,
} from "react";
import { useMemo } from "react";

const StyledUl = styled.ul`
  all: unset;
  display: inline;
`;

const cssLiBase = css`
  all: unset;
  display: inline;

  &:not(:first-child)::before {
    content: ", ";
  }
`;

type SimpleItem = ReactNode;

type SimpleList = SimpleItem[];

type KeyPairItem = {
  key: Key;
  el: ReactNode;
};

type KeyPairList = KeyPairItem[];

type List = SimpleList | KeyPairList;

function isKeyPairList(list: List): list is KeyPairList {
  return (
    list.length > 0 &&
    list[0] instanceof Object &&
    "key" in list[0] &&
    "el" in list[0]
  );
}

type InlineListProps = {
  items: List;
  ulProps?: ComponentPropsWithRef<"ul">;
  liProps?: ({
    key,
    el,
    index,
  }: {
    key: Key;
    el: ReactNode;
    index: number;
  }) => ComponentPropsWithoutRef<"li">;
  separator?: string;
};

const InlineList = memo(function InlineList({
  items,
  ulProps,
  liProps,
  separator = ", ",
}: InlineListProps) {
  const cssLi = useMemo(
    () => css`
      ${cssLiBase}
      &:not(:first-child)::before {
        content: "${separator}";
      }
    `,
    [separator],
  );
  return (
    <StyledUl {...ulProps} ref={ulProps?.ref}>
      {isKeyPairList(items)
        ? items.map(({ key, el }, index) => (
            <li {...liProps?.({ key, el, index })} key={key} css={cssLi}>
              {el}
            </li>
          ))
        : items.map((el, index) => (
            <li
              {...liProps?.({ key: index, el, index })}
              key={index}
              css={cssLi}
            >
              {el}
            </li>
          ))}
    </StyledUl>
  );
});

assignDisplayName(InlineList, "InlineList");

export { InlineList };
export type {
  InlineListProps,
  KeyPairItem,
  KeyPairList,
  List,
  SimpleItem,
  SimpleList,
};
