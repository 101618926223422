import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import {
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type ReactNode,
  useMemo,
} from "react";

import { ResultListItemDescription } from "@/components/ResultList/ResultListItemDescription";
import { ResultListItemHeader } from "@/components/ResultList/ResultListItemHeader";
import { defineStyles } from "@/shared/emotion";

const defaultRootElement = "li";

const styles = defineStyles({
  ResultListItem: css({
    color: theme.colors.neutral009,
    listStyle: "none",
    padding: "0 0 0 16px",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    gap: 4,

    "&:before": {
      position: "absolute",
      inset: 0,
      display: "block",
      content: "''",
      "--width": "4px",
      width: "var(--width)",
      minWidth: "var(--width)",
    },
  }),
});

const colorStyles = defineStyles({
  info: css({
    "&:before": {
      backgroundColor: theme.colors.neutral006,
    },
  }),
  success: css({
    "&:before": {
      backgroundColor: theme.colors.success,
    },
  }),
  warning: css({
    "&:before": {
      backgroundColor: theme.colors.warning,
    },
  }),
  error: css({
    "&:before": {
      backgroundColor: theme.colors.error,
    },
  }),
});

namespace ResultListItem {
  export type DefaultRootElement = typeof defaultRootElement;
  export interface BaseRootElementProps
    extends Pick<ComponentProps<DefaultRootElement>, "className"> {}
  export type BaseRootElementType = ElementType<BaseRootElementProps>;
  export interface OwnProps {
    color?: keyof typeof colorStyles;
    component?: BaseRootElementType;
    children: ReactNode;
  }
  export type Props<
    TRootElement extends BaseRootElementType = DefaultRootElement,
  > = Overwrite<ComponentProps<TRootElement>, OwnProps>;
  export interface Type {
    <TRootElement extends BaseRootElementType = DefaultRootElement>(
      props: Overwrite<
        Props<TRootElement>,
        {
          component: TRootElement;
        }
      >,
      ref?: ElementRef<TRootElement>,
    ): ReactNode;
    (
      props: DistributiveOmit<Props<DefaultRootElement>, "component">,
      ref?: ElementRef<DefaultRootElement>,
    ): ReactNode;
  }
}

const ResultListItemInternal: ResultListItem.Type = forwardRef(
  function ResultListItemInternal(
    {
      color = "info",
      component: Component = defaultRootElement,
      ...props
    }: ResultListItem.OwnProps,
    ref?: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    const mergedCss = useMemo(
      () => css([styles.ResultListItem, color ? colorStyles[color] : null]),
      [color],
    );
    return (
      <Component
        css={mergedCss}
        {...props}
        {...(!ref ? null : ({ ref } as Record<never, unknown>))}
      />
    );
  },
) as unknown as ResultListItem.Type;

/**
 * A styled `ResultList.Item` component.
 */
const ResultListItem = Object.assign(ResultListItemInternal, {
  defaultRootElement,
  styles,
  colorStyles,
  Header: ResultListItemHeader,
  Description: ResultListItemDescription,
});

assignDisplayName(ResultListItem, "ResultList.Item");

export { ResultListItem };
