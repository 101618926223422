import { CloseOutlined } from "@ant-design/icons";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import objectInspect from "object-inspect";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { Empty } from "@/routes/Chat/ui/ChatPanel/Pin/Content/Empty";
import { Loading } from "@/routes/Chat/ui/ChatPanel/Pin/Content/Loading";
import { Some } from "@/routes/Chat/ui/ChatPanel/Pin/Content/Some";
import { useDropDownSwitch } from "@/routes/Chat/ui/ChatPanel/Pin/useDropDownSwitch";
import { cssWrap } from "@/shared/emotion";

const componentCssVariableNamePrefix = `--chat-pin-${random()}-`;

const Header = memo(function Header() {
  const { t } = useTranslation();
  const [, dropDownSwitch] = useDropDownSwitch();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();
  const queryMyPinnedMessages = cantata.message.useListPinnedMessages({
    params: {
      orgId,
      memberId,
    },
  });
  return (
    <div
      css={css`
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(${`${componentCssVariableNamePrefix}padding`});
        padding-bottom: var(${`${componentCssVariableNamePrefix}gap`});
        background-color: ${theme.colors.white};
        color: ${theme.colors.neutral007};
        font-size: 12px;
        gap: var(${`${componentCssVariableNamePrefix}gap`});
      `}
    >
      <div>
        {!queryMyPinnedMessages.isSuccess ||
        queryMyPinnedMessages.data.messages.length === 0
          ? null
          : t("chat.pinMessage.dropdown.count", {
              count: queryMyPinnedMessages.data.messages.length,
            })}
      </div>
      <NarrowIconButton
        iconSize={12}
        icon={<CloseOutlined />}
        onClick={dropDownSwitch.off}
      />
    </div>
  );
});

const Container = styled.div`
  ${cssWrap}
  ${`${componentCssVariableNamePrefix}gap`}: 8px;
  ${`${componentCssVariableNamePrefix}padding`}: 12px;

  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;
  align-items: stretch;
`;

export const Content = memo(function Content() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();
  const queryMyPinnedMessages = cantata.message.useListPinnedMessages({
    params: {
      orgId,
      memberId,
    },
  });
  return (
    <Container>
      <div
        css={css`
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: stretch;
          padding: var(${`${componentCssVariableNamePrefix}padding`});
          padding-top: 0;
          gap: var(${`${componentCssVariableNamePrefix}gap`});
          overflow-y: auto;
        `}
      >
        {queryMyPinnedMessages.status === "loading" ? (
          <Loading />
        ) : queryMyPinnedMessages.status === "error" ? (
          <div>
            <Alert
              type="error"
              message={objectInspect(queryMyPinnedMessages.error)}
            />
          </div>
        ) : queryMyPinnedMessages.data.messages.length === 0 ? (
          <Empty />
        ) : (
          <Some />
        )}
      </div>
      <Header />
    </Container>
  );
});
