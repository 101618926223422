/**
 * Steps of adding new locales:
 * 1. Import new locale file
 * 2. Add other language setting in `availableLocales`
 * 3. Go to `src/utils/LangsLocalesUtils.ts` and add new strategy in `langStrategies`
 * 4. Use strategy in `formatLangCode`, and `makeFallbackLangs`
 */

import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import useEnhancedEffect from "@mui/utils/useEnhancedEffect";
import {
  DEVELOPMENT_MODE,
  FIREBASE_LOCALE_STORAGE_PATH_PREFIX,
} from "@zeffiroso/env";
import { MotifFontLoader } from "@zeffiroso/theme/font/MotifFontLoader";
import { memo } from "@zeffiroso/utils/react/memo";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import path from "pathe";
import { Fragment } from "react";
import { initReactI18next, useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { useFeatureFlag, withFeatureFlagWrapper } from "@/app/featureFlag";
import { cantata } from "@/cantata";
import { firebaseTools as firebase } from "@/lib/firebase";
import { logError } from "@/shared/application/logger/sentry";
import { availableLocales } from "@/shared/g11n/config";
import { formatLangCode } from "@/shared/hooks/formatLangCode";

i18n
  .use(ChainedBackend)
  .use(
    new LanguageDetector(null, {
      order: ["navigator"],
      caches: [], // must set empty or i18next will generate data in local storage
      convertDetectedLanguage: formatLangCode,
    }),
  )
  .use(initReactI18next)
  .init({
    debug: DEVELOPMENT_MODE,
    lowerCaseLng: true, // all lowercase for third-part library / frontend / backend consistency, eg. zh-Hant -> zh-hant
    fallbackLng: "en",
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend((language: string) => {
          // Fall back to local translation files
          return import(
            `../../../node_modules/.cache/zeffiroso/i18n/${language}/translation.json`
          );
        }),
      ],
      backendOptions: [
        {
          loadPath: async (languages: string[], namespaces: string[]) => {
            const [lng] = languages;
            const [ns] = namespaces;
            const availableLocaleCodes = availableLocales.map(
              (locale) => locale.code,
            ) as string[];

            // This prevents fetching additional duplicate locale files
            if (!availableLocaleCodes.includes(lng)) return undefined;

            try {
              return await firebase.getDownloadUrl(
                "locale",
                path.join(
                  FIREBASE_LOCALE_STORAGE_PATH_PREFIX,
                  formatLangCode(lng),
                  `${ns}.json`,
                ),
              );
            } catch (error) {
              logError(error);
            }
            return undefined;
          },
        },
      ],
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss,
      format: (value, format) => {
        if (format === "thousandth") return value.toLocaleString();

        return value;
      },
    },
    saveMissing: true,
    missingKeyHandler: (languages, namespace, key) => {
      logError(
        inspectMessage`Missing translation key: ${key} in ${namespace} of ${languages}`,
      );
    },
    missingInterpolationHandler: (text, value) => {
      logError(
        inspectMessage`Missing interpolation value: ${value} in ${text}`,
      );
    },
  });

const ApplyI18nFromServer = withFeatureFlagWrapper(
  "quickI18n",
  // Disable when i18n feature flag is enabled.
  Fragment,
  memo(function ApplyI18nFromServer() {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const meQuery = cantata.user.useGetMe({
      params: {
        orgId,
      },
    });
    const { i18n } = useTranslation();
    useEnhancedEffect(() => {
      if (!meQuery.isSuccess) return;
      if (i18n.language === meQuery.data.languageCode) return;
      let cancel = false;
      (async () => {
        // This delay is required to prevent the language from being changed
        // during the initial render, ensuring that the language change is fully
        // applied.
        await new Promise((resolve) => requestAnimationFrame(resolve));
        if (cancel) return;
        i18n.changeLanguage(meQuery.data.languageCode);
      })();
      return function cleanup() {
        cancel = true;
      };
    }, [i18n, meQuery.data?.languageCode, meQuery.isSuccess]);
    return null;
  }),
);

const ApplyI18nFromFeatureFlag = withFeatureFlagWrapper(
  "quickI18n",
  memo(function ApplyI18nFromDevMode() {
    const { i18n } = useTranslation();
    const targetI18n = useFeatureFlag("quickI18n");
    useEnhancedEffect(() => {
      if (!targetI18n) return;
      if (i18n.language === targetI18n) return;
      i18n.changeLanguage(targetI18n);
    }, [i18n, targetI18n]);
    return null;
  }),
);

MotifFontLoader.loadFont(i18n);

export { ApplyI18nFromFeatureFlag, ApplyI18nFromServer, formatLangCode, i18n };
