import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { DndContext } from "@dnd-kit/core";
import {
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  horizontalListSortingStrategy,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import type { Overwrite } from "@mui/types";
import { omit, pick } from "lodash-es";
import type { FC } from "react";

const sortableContextPropsKeys = [
  "children",
  "items",
  "strategy",
  "id",
  "disabled",
] as const satisfies Array<keyof ComponentProps<typeof SortableContext>>;

namespace SortableDndContext {
  export type Direction = "vertical" | "horizontal";
  export interface Props
    extends Overwrite<
      Overwrite<
        Omit<ComponentProps<typeof DndContext>, "modifiers">,
        Omit<ComponentProps<typeof SortableContext>, "strategy">
      >,
      {
        direction: Direction;
      }
    > {}
}
const defaultPropsRecord: Record<
  SortableDndContext.Direction,
  {
    modifiers: NonNullable<ComponentProps<typeof DndContext>["modifiers"]>;
    strategy: NonNullable<ComponentProps<typeof SortableContext>["strategy"]>;
  }
> = {
  vertical: {
    modifiers: [restrictToVerticalAxis],
    strategy: verticalListSortingStrategy,
  },
  horizontal: {
    modifiers: [restrictToHorizontalAxis],
    strategy: horizontalListSortingStrategy,
  },
};

/**
 * Shortcut for creating a sortable DndContext with a SortableContext.
 *
 * ```tsx
 * <SortableDndContext direction="vertical" items={items}>
 *   {items.map((item) => (
 *     <SortableItem key={item.id} id={item.id} />
 *   ))}
 * </SortableDndContext>
 * ```
 */
const SortableDndContext: FC<SortableDndContext.Props> = ({
  direction,
  ...props
}) => {
  const sortableContextProps = pick(props, sortableContextPropsKeys) as Pick<
    typeof props,
    (typeof sortableContextPropsKeys)[number] & keyof typeof props
  >;
  const dndContextProps = omit(props, sortableContextPropsKeys);
  const { modifiers, strategy } = defaultPropsRecord[direction];

  return (
    <DndContext {...dndContextProps} modifiers={modifiers}>
      <SortableContext
        {...sortableContextProps}
        items={sortableContextProps.items}
        strategy={strategy}
      />
    </DndContext>
  );
};

export { SortableDndContext };
