import { CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { Checkbox, Skeleton } from "antd";
import { type FC, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { useOpenZendesk } from "@/app/zendesk";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { Form } from "@/components/Form";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { Modal } from "@/components/Modal";
import { useMessage } from "@/internal/message";
import { useCanAssignMember } from "@/resources/user/canAssignMember";
import { AssigneeSelector } from "@/routes/Chat/components/Assignee/AssigneeSelector";
import type {
  AssigneeFormProps,
  Values as AssigneeFormValues,
} from "@/routes/Chat/components/Assignee/utils";
import { initialValues } from "@/routes/Chat/components/Assignee/utils";
import { bulkActionMutations } from "@/routes/Chat/ui/MembersPanel/bulkActionMutations";
import {
  BulkReassignControllerProvider,
  useBulkReassignController,
} from "@/routes/Chat/ui/MembersPanel/controllers/bulkReassignController";
import { useMembersController } from "@/routes/Chat/ui/MembersPanel/controllers/membersController";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { useSelectionController } from "@/routes/Chat/ui/MembersPanel/controllers/selectionController";
import {
  useProcessingStateResultCountQueries,
  useSearchResultCountQueries,
} from "@/routes/Chat/ui/MembersPanel/countQueriesHooks";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";
import { UserTransferOutlined } from "@/shared/icons/common/UserTransferOutlined";

const styles = defineStyles({
  skeleton: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "&>*": {
      height: "1em",
      minWidth: 0,
      width: "auto",
      maxWidth: "6em",
      flex: 1,
    },
  }),
});

/**
 * Display the number of members in the current processing state.
 */
const ProcessingStateResultCount: FC = () => {
  const processingStateResultCountQueries =
    useProcessingStateResultCountQueries();

  if (!processingStateResultCountQueries.isSuccess) return null;

  return (
    <Trans
      i18nKey="chat.memberPanel.infoBar.result"
      values={{
        count:
          processingStateResultCountQueries.pinnedQuery.data.count +
          processingStateResultCountQueries.unpinnedQuery.data.count,
      }}
    />
  );
};

/**
 * Display the number of members in the search result.
 */
const SearchResultCount = () => {
  const { countQuery } = useSearchResultCountQueries();

  if (!countQuery.isSuccess) return null;

  return (
    <Trans
      i18nKey="chat.memberPanel.infoBar.result"
      values={{ count: countQuery.data.count }}
    />
  );
};

const SearchByNotesResultCount: FC = () => {
  const membersController = useMembersController();
  /**
   * No pagination for notes search.
   */
  const members = membersController.useMembers();
  return (
    <Trans
      i18nKey="chat.memberPanel.infoBar.result"
      values={{ count: members.length }}
    />
  );
};

const ToggleBulkAction = memo(function ToggleBulkAction() {
  const selectionController = useSelectionController();
  const enableSelection = selectionController.useEnableSelection();
  const membersController = useMembersController();
  const members = membersController.useMembers();
  return (
    <Button
      css={css`
        &:not(:disabled, :active, :hover, :focus) {
          color: ${theme.colors.blue006};
        }
      `}
      type="text"
      size="small"
      onClick={enableSelection}
      disabled={members.length === 0}
    >
      <Trans i18nKey="chat.memberPanel.bulkAction.select" />
    </Button>
  );
});

const BulkReassignForm = createEasyForm<AssigneeFormValues>();

const BulkReassignModal: FC<{
  mutation: ReturnType<typeof bulkActionMutations.useBulkReassignMutation>;
  onCancel: () => void;
}> = ({ mutation, onCancel }) => {
  const [_easyForm, form] = BulkReassignForm.useForm();
  const mergeFormDisabled = useMergeFormDisabled();
  const isMutating = bulkActionMutations.useIsBulkActionMutating();
  const selectionController = useSelectionController();
  const selectedMembers = selectionController.useSelectedMembers();
  const noMemberSelected = selectedMembers.length === 0;
  const disabled = mergeFormDisabled(isMutating || noMemberSelected);
  const handleBulkReassign = useHandler<AssigneeFormProps["onFinish"]>(
    function handleBulkReassign(values) {
      if (!values.assignee) return;
      const assignee = values.assignee;
      const memberIds = selectedMembers.map((m) => m.id);
      mutation.mutate({
        memberIds,
        ...(assignee.type === "unassigned"
          ? {
              assignType: "unassigned",
              assignmentRelationship: {
                userId: null,
                teamId: null,
              },
            }
          : {
              assignType: null,
              assignmentRelationship: {
                userId: !("userId" in assignee) ? null : assignee.userId,
                teamId: !("teamId" in assignee) ? null : assignee.teamId,
              },
            }),
      });
    },
  );
  return (
    <BulkReassignForm
      form={form}
      onFinish={handleBulkReassign}
      disabled={disabled}
      initialValues={initialValues}
    >
      <Modal
        open
        onCancel={onCancel}
        title={
          <Trans i18nKey="chat.memberPanel.bulkAction.assignModal.title" />
        }
        footer={[
          <Button key="cancel" type="default" onClick={onCancel}>
            <Trans i18nKey="common.cancel" />
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={form.submit}
            loading={mutation.isLoading}
          >
            <Trans i18nKey="common.confirm" />
          </Button>,
        ]}
      >
        <div
          css={css`
            margin-bottom: 0.75rem;
            color: ${theme.colors.neutral009};
            font-size: 0.875rem;
            line-height: 1.25rem;
          `}
        >
          <Trans i18nKey="chat.memberPanel.bulkAction.assignModal.assignee.label" />
        </div>
        <div
          css={css`
            max-width: 16.5rem;
          `}
        >
          <Form.Item
            name="assignee"
            noStyle
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AssigneeSelector />
          </Form.Item>
        </div>
      </Modal>
    </BulkReassignForm>
  );
};

const BulkReassignResultModal = memo(function BulkReassignResultModal() {
  const bulkReassignController = useBulkReassignController();
  const bulkReassignResult = bulkReassignController.useBulkReassignResult();
  const openZendesk = useOpenZendesk();
  const handleSupport = useHandler(function handleSupport() {
    openZendesk();
    bulkReassignController.closeReportModal();
  });

  if (bulkReassignResult === null) return null;

  return (
    <Modal
      open
      onCancel={bulkReassignController.closeReportModal}
      title={
        <Trans i18nKey="chat.memberPanel.bulkAssign.assignCompleteModal.title" />
      }
      footer={[
        <Button key="cancel" type="default" onClick={handleSupport}>
          <Trans i18nKey="chat.memberPanel.bulkAssign.assignCompleteModal.cancel" />
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={bulkReassignController.closeReportModal}
        >
          <Trans i18nKey="common.confirm" />
        </Button>,
      ]}
    >
      <div
        css={css`
          color: ${theme.colors.green006};
        `}
      >
        <Trans
          i18nKey="chat.memberPanel.bulkAssign.assignCompleteModal.success"
          values={{
            success: bulkReassignResult.success,
            total: bulkReassignResult.total,
          }}
        />
      </div>
      <div
        css={css`
          margin-bottom: 2rem;
          color: ${theme.colors.red006};
        `}
      >
        <Trans
          i18nKey="chat.memberPanel.bulkAssign.assignCompleteModal.failed"
          values={{ failed: bulkReassignResult.failed }}
        />
      </div>
      <div
        css={css`
          color: ${theme.colors.neutral010};
        `}
      >
        <Trans i18nKey="chat.memberPanel.bulkAssign.assignCompleteModal.description" />
      </div>
    </Modal>
  );
});

const BulkReassign = memo(function BulkReassign() {
  const mergeFormDisabled = useMergeFormDisabled();
  const [openAssignModal, toggleOpenAssignModal] = useSwitch(false);
  const bulkReassignController = useBulkReassignController();
  const { t } = useTranslation();
  const message = useMessage();
  const selectionController = useSelectionController();
  const isNoneSelected = selectionController.useIsNoneSelected();
  const mutation = bulkActionMutations.useBulkReassignMutation({
    onSuccess(result) {
      selectionController.reset();
      if (result.failed === 0) {
        message.success(
          t("chat.memberPanel.bulkAssign.assign.success", {
            count: result.success,
          }),
        );
        return;
      }

      bulkReassignController.openReportModal(result);
    },
  });

  const disabled = mergeFormDisabled(isNoneSelected);

  return (
    <span css={cssFlexInheritAndFill}>
      <NarrowIconButton
        size="middle"
        iconSize="small"
        onClick={toggleOpenAssignModal.on}
        icon={<UserTransferOutlined />}
        tooltipProps={{
          title: <Trans i18nKey="chat.memberPanel.bulkAction.assignTooltip" />,
        }}
        disabled={disabled}
      />
      {!openAssignModal ? null : (
        <BulkReassignModal
          mutation={mutation}
          onCancel={toggleOpenAssignModal.off}
        />
      )}
    </span>
  );
});

const BulkResolve = memo(function BulkResolve() {
  const { t } = useTranslation();
  const mergeFormDisabled = useMergeFormDisabled();
  const selectionController = useSelectionController();
  const selectedMembers = selectionController.useSelectedMembers();
  const memberIds = useMemo(
    function computeMemberIds() {
      return selectedMembers.map((m) => m.id);
    },
    [selectedMembers],
  );
  const noMemberSelected = selectedMembers.length === 0;
  const message = useMessage();
  const mutation = bulkActionMutations.useBulkResolveMutation({
    onSuccess(result) {
      selectionController.reset();
      message.success(
        t("chat.memberPanel.bulkAssign.resolved.success", {
          count: result.success,
        }),
      );
    },
  });
  const handleBulkResolve = useHandler(function handleBulkResolve() {
    mutation.mutate({ memberIds, processingState: "resolved" });
  });

  const disabled = mergeFormDisabled(noMemberSelected);

  return (
    <NarrowIconButton
      size="middle"
      iconSize="small"
      disabled={disabled}
      icon={<CheckCircleOutlined />}
      onClick={handleBulkResolve}
      tooltipProps={{
        title: <Trans i18nKey="chat.memberPanel.bulkAction.resolveTooltip" />,
      }}
    />
  );
});

const cssBulkActionButtonGroup = css`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  .ant-btn:not(:disabled, :focus) svg {
    color: ${theme.colors.blue006};

    :hover {
      color: ${theme.colors.blue005};
    }

    :active {
      color: ${theme.colors.blue007};
    }
  }
`;

const cssInfoText = css`
  flex: 1;
  color: ${theme.colors.neutral007};
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const ToggleSelectAllCheckbox = (function declareToggleSelectAllCheckbox() {
  const cssCount = css`
    ${cssInfoText}
    &::before {
      content: "(";
    }

    &::after {
      content: ")";
    }
  `;
  return memo(function ToggleSelectAllCheckbox() {
    const selectionController = useSelectionController();
    const selectionMembers = selectionController.useSelectionMembers();
    const selectionMembersCount = selectionMembers.length;
    const selectedMembers = selectionController.useSelectedMembers();
    const selectedMembersCount = selectedMembers.length;
    const isReachSelectionLimit = selectionController.useIsReachedLimit();
    const isAllSelected = selectionController.useIsAllSelected();
    const showCheckAll = isReachSelectionLimit || isAllSelected;
    const isAllUnselected = selectionController.useIsNoneSelected();
    const indeterminate = !showCheckAll && !isAllUnselected;
    const mergeFormDisabled = useMergeFormDisabled();
    const disabled = mergeFormDisabled(selectionMembersCount === 0);
    return (
      <Checkbox
        indeterminate={indeterminate}
        {...(showCheckAll
          ? {
              checked: true,
            }
          : indeterminate
            ? {
                indeterminate: true,
              }
            : isAllUnselected
              ? {
                  checked: false,
                }
              : null)}
        disabled={disabled}
        onChange={selectionController.toggleAllMemberSelection}
      >
        <span css={cssCount}>{selectedMembersCount}</span>
      </Checkbox>
    );
  });
})();

const BulkActionInfo = memo(function BulkActionInfo() {
  const selectionController = useSelectionController();
  const { processingState } = searchController.useStore(
    (state) => state.filter,
  );

  const canAssignMember = useCanAssignMember();
  const canResolve =
    processingState === "new" || processingState === "follow-up";

  return (
    <>
      <ToggleSelectAllCheckbox />
      <div css={cssBulkActionButtonGroup}>
        {!canResolve ? null : <BulkResolve />}
        {!canAssignMember ? null : <BulkReassign />}
        <NarrowIconButton
          size="middle"
          iconSize="small"
          icon={<CloseOutlined />}
          onClick={selectionController.reset}
          tooltipProps={{
            title: (
              <Trans i18nKey="chat.memberPanel.bulkAction.cancelTooltip" />
            ),
          }}
          disabled={false}
        />
      </div>
    </>
  );
});

const MemberPanelInfo = memo(function MemberPanelInfo() {
  const isSearching = searchController.useStore(
    searchController.selectors.isSearching,
  );
  const isSearchingNotes = searchController.useStore(
    (state) =>
      searchController.selectors.isSearching(state) &&
      state.search.action === "notes",
  );
  const { processingState } = searchController.useStore(
    (state) => state.filter,
  );
  const canAssignMember = useCanAssignMember();
  const canResolve =
    processingState === "new" || processingState === "follow-up";

  return (
    <>
      <span css={cssInfoText}>
        <ErrorBoundary.Alert>
          <Suspense fallback={<Skeleton.Input css={styles.skeleton} />}>
            {isSearchingNotes ? (
              <SearchByNotesResultCount />
            ) : isSearching ? (
              <SearchResultCount />
            ) : (
              <ProcessingStateResultCount />
            )}
          </Suspense>
        </ErrorBoundary.Alert>
      </span>
      <div>{!canAssignMember && !canResolve ? null : <ToggleBulkAction />}</div>
    </>
  );
});

const cssInfoBar = css`
  display: flex;
  width: 100%;
  min-height: 40px;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid ${theme.colors.neutral003};
`;

const InfoBar = memo(function InfoBar() {
  const selectionController = useSelectionController();
  const isSelecting = selectionController.useIsSelecting();
  return (
    <BulkReassignControllerProvider>
      <div css={cssInfoBar}>
        {isSelecting ? <BulkActionInfo /> : <MemberPanelInfo />}
      </div>
      <BulkReassignResultModal />
    </BulkReassignControllerProvider>
  );
});

export { InfoBar };
