import { useHandler } from "@chatbotgang/etude/react/useHandler";
import useChange from "@react-hook/change";
import { memo } from "@zeffiroso/utils/react/memo";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { useEffect, useId } from "react";
import { createWithEqualityFn } from "zustand/traditional";

/**
 * Create a stack that you can push a value into when the component is
 * mounted and pop it when the component is unmounted.
 *
 * @example
 *
 * ```ts
 * const { useItem, Item, useStack } = createStack<string>();
 *
 * useItem('foo')
 * <Item value={'bar'} />
 *
 * useStack() // ['foo', 'bar']
 * ```
 */
function createStack<T = undefined>() {
  const useStore = createWithEqualityFn<{
    stack: {
      id: ReturnType<typeof useId>;
      value: T;
    }[];
  }>()(
    () => ({
      stack: [],
    }),
    shallow,
  );
  function useItem(input: T = undefined as T) {
    const id = useId();
    const push = useHandler(function push() {
      useStore.setState((current) => {
        if (current.stack.some((item) => item.id === id))
          return {
            stack: current.stack.map((item) =>
              item.id === id ? { id, value: input } : item,
            ),
          };
        return {
          stack: [...current.stack, { id, value: input }],
        };
      });
    });
    const pop = useHandler(function pop() {
      const current = useStore.getState().stack;
      const item = current.find((item) => item.id === id);
      if (!item) return;
      useStore.setState(() => ({
        stack: current.filter((item) => item.id !== id),
      }));
    });
    useEffect(() => {
      push();
      return pop;
    }, [pop, push]);
    useChange(input, push);
  }
  const Item = memo(function Item({ value }: { value: T }) {
    useItem(value);
    return null;
  });
  function useStack() {
    return useStore((state) => state.stack.map((item) => item.value));
  }
  return {
    useItem,
    Item,
    useStack,
    useStore,
  };
}

export { createStack };
