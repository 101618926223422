import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import type { FC } from "react";

import type { CantataTypes } from "@/cantata/types";
import { Avatar } from "@/components/Avatar";
import { ChannelName } from "@/resources/channel/ChannelName";
import { GroupMemberAvatarById } from "@/resources/groupMember/GroupMemberAvatarById";
import { GroupMemberNameById } from "@/resources/groupMember/GroupMemberNameById";
import { Unknown } from "@/resources/groupMember/Unknown";
import { getMemberDisplayName } from "@/resources/member/displayName";
import { OrgAvatar } from "@/resources/organization/OrgAvatar";
import { UserAvatarById } from "@/resources/user/UserAvatarById";
import { UserNameById } from "@/resources/user/UserNameById";
import {
  cssFlexInheritAndFill,
  cssOneLine,
  defineStyles,
} from "@/shared/emotion";

const styles = defineStyles({
  sender: css([
    cssFlexInheritAndFill,
    {
      fontSize: "0.75rem",
      flexDirection: "row",
      gap: "inherit",
      overflow: "hidden",
      alignItems: "center",
      "& .ant-avatar": {
        borderRadius: "50%",
      },
    },
  ]),
  senderName: cssOneLine,
});

declare namespace AvatarAndName {
  export interface Props
    extends Overwrite<
      Omit<ComponentProps<"div">, "children">,
      {
        orgId: CantataTypes["Org"]["id"];
        member: Pick<
          CantataTypes["Member"],
          | "id"
          | "type"
          | "avatar"
          | "displayName"
          | "originalName"
          | "channelId"
        >;
        message: Pick<
          CantataTypes["MessagePinned"],
          "id" | "senderType" | "speakerId" | "userName"
        >;
        avatarProps?: Omit<ComponentProps<typeof Avatar>, "src">;
        nameProps?: Omit<ComponentProps<"div">, "children">;
      }
    > {}
}

const AvatarAndName: FC<AvatarAndName.Props> = ({
  orgId,
  member,
  message,
  avatarProps,
  nameProps,
  ...props
}) => {
  if (message.senderType === "user") {
    if (message.speakerId === null) {
      return (
        <div css={styles.sender} {...props}>
          <Avatar {...avatarProps} />
          <div css={styles.senderName} {...nameProps}>
            <Unknown />
          </div>
        </div>
      );
    }

    return (
      <div css={styles.sender} {...props}>
        <UserAvatarById {...avatarProps} userId={message.speakerId} />
        <div css={styles.senderName} {...nameProps}>
          <UserNameById userId={message.speakerId} />
        </div>
      </div>
    );
  }

  if (message.senderType === "member") {
    if (member.type === "line_group") {
      if (message.speakerId === null)
        throw new Error(
          inspectMessage`Unexpected message:${message.id} with speakerId null`,
        );

      return (
        <div css={styles.sender} {...props}>
          <GroupMemberAvatarById
            {...avatarProps}
            groupId={member.id}
            memberId={message.speakerId}
          />
          <div css={styles.senderName} {...nameProps}>
            <GroupMemberNameById
              groupId={member.id}
              memberId={message.speakerId}
            />
          </div>
        </div>
      );
    }
    return (
      <div css={styles.sender} {...props}>
        <Avatar {...avatarProps} src={member.avatar} />
        <div css={styles.senderName} {...nameProps}>
          {getMemberDisplayName(member)}
        </div>
      </div>
    );
  }

  // Fallback to channel name and org avatar
  return (
    <div css={styles.sender} {...props}>
      <OrgAvatar {...avatarProps} orgId={orgId} />
      <div css={styles.senderName} {...nameProps}>
        <ChannelName orgId={orgId} channelId={member.channelId} />
      </div>
    </div>
  );
};

export { AvatarAndName };
