import "@/init";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { App, AppProviders } from "@/App";

const container = document.getElementById("root");

if (!container) throw new Error("No root element found");

const root = createRoot(container);

root.render(
  <StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </StrictMode>,
);
