import { css } from "@emotion/react";
import { memo } from "@zeffiroso/utils/react/memo";
import { useTranslation } from "react-i18next";

import { FormItem } from "@/components/Form";
import { BoldText } from "@/components/Typography";
import { TeamRoutingRuleSelect } from "@/resources/team/TeamRoutingRuleSelect";

const RoutingRuleField = memo(function RoutingRuleField() {
  const { t } = useTranslation();

  return (
    <FormItem
      required
      name="routingRule"
      data-test="team-routing-rule-select"
      label={<BoldText>{t("team.form.routingRule.label")}</BoldText>}
      css={css`
        width: 336px;
        max-width: 100%;
      `}
      rules={[
        {
          required: true,
          message: t("team.form.routingRule.placeholder"),
        },
      ]}
    >
      <TeamRoutingRuleSelect />
    </FormItem>
  );
});

export { RoutingRuleField };
