import { memo } from "@zeffiroso/utils/react/memo";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import type { SelectProps } from "@/components/Select";
import { Select } from "@/components/Select";
import { AutoAssignmentRuleConditionCategoryLabel } from "@/routes/Settings/Assignment/pages/RoutingRules/components/condition/AutoAssignmentRuleConditionCategoryLabel";
import { cssColumnField } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/style";
import type { AutoAssignmentRuleConditionRequest } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/type";

export const CategorySelector = memo(function CategorySelector({
  ...props
}: Omit<
  SelectProps<
    AutoAssignmentRuleConditionRequest["category"] | undefined,
    {
      value: AutoAssignmentRuleConditionRequest["category"];
      label: ReactNode;
    }
  >,
  "options"
>) {
  const { t } = useTranslation();
  return (
    <Select
      css={cssColumnField}
      placeholder={t("assignment.condition")}
      options={[
        {
          value: "all-messages",
          label: (
            <AutoAssignmentRuleConditionCategoryLabel category="all-messages" />
          ),
        },
        {
          value: "keywords",
          label: (
            <AutoAssignmentRuleConditionCategoryLabel category="keywords" />
          ),
        },
        // Hide tag for now
        // {
        //   value: 'tags',
        //   label: <AutoAssignmentRuleConditionCategoryLabel category="tags" />,
        // },
      ]}
      {...props}
    />
  );
});
