import type { RoleSchema } from "@zeffiroso/cantata/models";
import type { z } from "zod";

type BaseRolePermissionMap = Record<
  string,
  Array<z.infer<typeof RoleSchema>["type"]>
>;

/**
 * The following is a hardcoded list of special permissions that are not
 * managed by the permission record.
 */
const rolePermissionMap = {
  /**
   * @see {@link https://www.notion.so/cresclab/Chat-Room-with-Team-c1b54d0407da4f8880bf206a157e63f2?pvs=4#414a0d02178f4746bde00346e131a9c6}
   */
  unlimitedTeamScope: ["owner", "primary_admin"],
  /**
   * @see {@link https://docs.google.com/spreadsheets/d/1b790_BKzXbcw2nbj_moML3RujTGGNxbDT6IuG_GEf50/edit#gid=502134957&range=43:43}
   */
  canAssignMembersInMyTeams: ["admin"],
} satisfies BaseRolePermissionMap;

type RolePermission = keyof typeof rolePermissionMap;

export { rolePermissionMap };
export type { RolePermission };
