import { css } from "@emotion/react";
import { memo } from "@zeffiroso/utils/react/memo";
import { Badge } from "antd";
import type { ReactNode } from "react";

import type { CantataTypes } from "@/cantata/types";
import { Flex } from "@/components/Box";
import { Dot } from "@/resources/user/onDutyStatus/Dot";

export const OnDutyStatusBadge = memo(function OnDutyStatusBadge({
  onDutyStatus,
  children,
}: {
  onDutyStatus: CantataTypes["TeamUser"]["onDutyStatus"];
  children: ReactNode;
}) {
  const count = (
    <span>
      <Flex
        css={css`
          align-items: center;
          justify-content: center;
          transform: translate(-50%, 50%);
        `}
      >
        <Dot size={8} status={onDutyStatus} />
      </Flex>
    </span>
  );

  return <Badge count={count}>{children}</Badge>;
});
