import { memo } from "@zeffiroso/utils/react/memo";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { TabsProps } from "@/components/Tabs";
import { Tabs } from "@/components/Tabs";
import { ConversationHistory } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory";

const Engagement = memo(function Engagement() {
  const { t } = useTranslation();

  const items = useMemo<TabsProps["items"]>(
    function declareItems() {
      let key = 0;
      const items: TabsProps["items"] = [
        {
          key: String(key++),
          label: t(
            "chat.memberProfilePanel.tabs.engagement.tags.conversationHistory.label",
          ),
          children: <ConversationHistory />,
        },
      ];
      return items;
    },
    [t],
  );
  return <Tabs items={items} />;
});

export { Engagement };
