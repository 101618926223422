import type { FC } from "react";

import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { Default } from "@/routes/Chat/ui/MemberProfilePanel/Membership/Member/Default";
import { EcProfile } from "@/routes/Chat/ui/MemberProfilePanel/Membership/Member/EcProfile";

const Member: FC = () => {
  const orgData = orgQueriesContext.useData();

  // not integrated with commerce platform
  if (!orgData.org.ecType) {
    return <Default />;
  }

  return <EcProfile />;
};

export { Member };
