import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { constant } from "lodash-es";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Avatar } from "@/components/Avatar";
import { FormItem } from "@/components/Form";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import type { SelectProps } from "@/components/Select";
import { Select } from "@/components/Select";
import { CrescendoLab as LogoSvg } from "@/shared/icons/common/CrescendoLab";
import type { ArrayPredicate } from "@/shared/types/ArrayPredicate";

type OptionType = {
  key: number;
  value: number;
  label: ReactNode;
  text: string;
};

export type TeamSelectorProps = Omit<
  SelectProps<number, OptionType>,
  "options" | "mode"
> & {
  filter?: ArrayPredicate<CantataTypes["Team"]>;
};

/**
 * Team selector
 *
 * You can customize the selector with all props of Select component other than `options` and `mode`.
 *
 * Additional props:
 *
 * @params value - Team ID. `NaN` means no team selected.
 * @params filter - filter function to filter teams.
 */
export const TeamSelector = memo<TeamSelectorProps>(function TeamSelector({
  filter = constant(true),
  ...props
}) {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.team.useList({
    params: {
      orgId,
    },
  });
  const options = useMemo<SelectProps<number, OptionType>["options"]>(() => {
    if (!query.isSuccess) return [];

    return query.data.teams.filter(filter).map((team) => ({
      key: team.id,
      value: team.id,
      text: team.name,
      label: (
        <ItemWithIcon
          gap={16}
          startIcon={
            <Avatar
              style={{ backgroundColor: theme.colors.blue006, fontSize: 10 }}
              icon={<LogoSvg />}
              size={16}
            />
          }
        >
          {team.name}
        </ItemWithIcon>
      ),
    }));
  }, [filter, query.data, query.isSuccess]);
  if (query.isLoading) return <Select {...props} loading disabled />;

  if (query.isError) {
    return (
      <FormItem
        help={inspectMessage`query error: ${query.error}`}
        validateStatus="error"
      >
        <Select {...props} loading disabled />
      </FormItem>
    );
  }
  return (
    <Select<number, OptionType>
      showSearch
      placeholder={t("assignment.searchTeam")}
      {...props}
      optionFilterProp="text"
      options={options}
    />
  );
});
