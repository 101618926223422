import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { ExternalLink } from "@/components/ExternalLink";
import { Pill } from "@/components/Pill";
import { Radio, RadioGroup } from "@/components/Radio";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  IndividualAssignmentSetting: css({
    maxWidth: 776,
    display: "flex",
    flexDirection: "column",
    gap: 24,
  }),
  group: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  item: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
  h3: css({
    alignSelf: "stretch",
    color: theme.colors.neutral010,
    fontSize: "1rem",
    fontWeight: 500,
  }),
  title: css({
    alignSelf: "stretch",
    color: theme.colors.neutral010,
    fontSize: "0.875rem",
    fontWeight: 500,
  }),
  description: css({
    alignSelf: "stretch",
    color: theme.colors.neutral007,
    fontSize: "0.875rem",
  }),
  preview: css({
    alignSelf: "stretch",
    padding: 12,
    borderRadius: theme.shape.borderRadiusLarge,
    background: theme.colors.neutral001,
    color: theme.colors.neutral007,
    width: "100%",
    maxWidth: 488,
  }),
  tag: css({
    borderRadius: theme.shape.borderRadius,
  }),
});

const IndividualAssignmentSetting: FC = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.IndividualAssignmentSetting}>
      <div css={styles.item}>
        <div css={styles.h3}>
          {t("page.settings.assignment.duplicateAssignment.title")}
        </div>
        <div css={styles.description}>
          {t("page.settings.assignment.duplicateAssignment.description")}
        </div>
        <div>
          <RadioGroup value="noOverride">
            <Radio name="duplicateAssignment" value="noOverride" disabled>
              {t("page.settings.assignment.duplicateAssignment.noOverride")}
            </Radio>
          </RadioGroup>
        </div>
      </div>
      <div css={styles.group}>
        <div css={styles.item}>
          <div css={styles.h3}>
            {t("page.settings.assignment.individualAssignmentSetting.title")}
          </div>
          <div css={styles.description}>
            {t("page.settings.assignment.individualAssignmentSetting.desc")}
          </div>
          <ExternalLink
            href={t(
              "page.settings.assignment.individualAssignmentSetting.link",
            )}
            trailingIcon
          >
            {t("common.learnMore")}
          </ExternalLink>
        </div>
        <div css={styles.item}>
          <div css={styles.title}>
            {t(
              "page.settings.assignment.individualAssignmentSetting.active.title",
            )}
          </div>
          <div css={styles.preview}>
            <Trans
              i18nKey="page.settings.assignment.individualAssignmentSetting.active.message"
              components={{
                tag: <Pill $variant="fulfilled" css={styles.tag} />,
              }}
            />
          </div>
        </div>
        <div css={styles.item}>
          <div css={styles.title}>
            {t(
              "page.settings.assignment.individualAssignmentSetting.success.title",
            )}
          </div>
          <div css={styles.preview}>
            <Trans
              i18nKey="page.settings.assignment.individualAssignmentSetting.success.message"
              components={{
                tag: <Pill $variant="fulfilled" css={styles.tag} />,
              }}
            />
          </div>
        </div>
        <div css={styles.item}>
          <div css={styles.title}>
            {t(
              "page.settings.assignment.individualAssignmentSetting.conflict.title",
            )}
          </div>
          <div css={styles.preview}>
            <Trans
              i18nKey="page.settings.assignment.individualAssignmentSetting.conflict.message"
              components={{
                tag: <Pill $variant="fulfilled" css={styles.tag} />,
              }}
            />
          </div>
        </div>
        <div css={styles.item}>
          <div css={styles.title}>
            {t(
              "page.settings.assignment.individualAssignmentSetting.failed.title",
            )}
          </div>
          <div css={styles.preview}>
            {t(
              "page.settings.assignment.individualAssignmentSetting.failed.message",
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { IndividualAssignmentSetting };
