import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@zeffiroso/utils/react/memo";
import { Skeleton } from "antd";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";

const PermanentAssignmentCountById = memo(
  function PermanentAssignmentCountById({ userId }: { userId: number }) {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const query = cantata.user.useGetById({
      params: {
        orgId,
        userId,
      },
    });

    if (query.isLoading) return <Skeleton.Input size="small" />;

    if (query.isError) {
      return (
        <Alert
          type="error"
          message={inspectMessage`query error: ${query.error}`}
        />
      );
    }

    return <>{query.data.permanentAssignmentMembersCount}</>;
  },
);

export { PermanentAssignmentCountById };
