import { memo } from "@zeffiroso/utils/react/memo";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import type { ModalProps } from "@/components/Modal";
import { Modal } from "@/components/Modal";

export type BlockerUiProps = ModalProps;

/**
 * Filter out the events type because the event type of Ant Design Button might
 * be anchor.
 */
function isButtonEvent<T>(
  e: T,
): e is Extract<T, { currentTarget: HTMLButtonElement }> {
  return (
    e instanceof Object &&
    "currentTarget" in e &&
    e.currentTarget instanceof HTMLButtonElement
  );
}

/**
 * The default UI from RouterPrompt. You can override this component with all
 * the props from Ant Design Modal.
 *
 * onOk: The handler when the user clicks the leave button.
 * onCancel: The handler when the user clicks the keep editing button.
 */
export const BlockerUi = memo(function BlockerUi(props: BlockerUiProps) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("common.unsaved.title")}
      footer={[
        <Button
          key="cancel"
          type="default"
          onClick={(e) => {
            if (!isButtonEvent(e)) return;
            props.onOk?.(e);
          }}
        >
          {t("common.unsaved.leave")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={(e) => {
            if (!isButtonEvent(e)) return;
            props.onCancel?.(e);
          }}
        >
          {t("common.unsaved.keepEditing")}
        </Button>,
      ]}
      {...props}
    >
      {props.children ?? t("common.unsaved.desc")}
    </Modal>
  );
});
