import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import { useDomRectObserver } from "@zeffiroso/utils/react/useDomRectObserver";
import classNames from "classnames";
import { type FC, type ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { useOpenZendesk } from "@/app/zendesk";
import { cantata } from "@/cantata";
import { Button } from "@/components/Button";
import { DisplayDate } from "@/components/DisplayDate";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { Input } from "@/components/Input";
import { BoldText, Text } from "@/components/Typography";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { UsersCount } from "@/resources/organization/UsersCount";
import { OrgLogo } from "@/routes/Settings/Org/OrgLogo";
import { OrgName } from "@/routes/Settings/Org/OrgName";
import { OrgTwoFactor } from "@/routes/Settings/Org/OrgTwoFactor";
import { OwnerInfo } from "@/routes/Settings/Org/OwnerInfo";
import { defineStyles } from "@/shared/emotion";
import { organizationPlanTypeTranslationKeyMap } from "@/shared/utils/translation/organizationPlanTypeTranslationKeyMap";

const seed = random();
const classNameRecord = {
  narrowLayout: `org-narrow-${seed}`,
} as const satisfies Record<PropertyKey, string>;

const styles = defineStyles({
  formContent: css({
    display: "grid",
    paddingTop: 16,
    gridColumnGap: "8%",
    gridTemplateColumns: "repeat(2, 40%)",
    gridTemplateRows: "repeat(5, auto)",
    "& > :nth-child(1)": { gridColumn: 1, gridRow: 1 },
    "& > :nth-child(2)": { gridColumn: 1, gridRow: 2 },
    "& > :nth-child(3)": { gridColumn: 2, gridRow: 2 },
    "& > :nth-child(4)": { gridColumn: 1, gridRow: 3 },
    "& > :nth-child(5)": { gridColumn: 2, gridRow: 3 },
    "& > :nth-child(6)": { marginBottom: 40, gridColumn: 1, gridRow: 4 },
    "& > :nth-child(7)": { gridColumn: 1, gridRow: 5 },
    [`&.${classNameRecord.narrowLayout}`]: {
      display: "block",
      maxWidth: 500,
    },
  }),
  formFieldWrapper: css({
    marginBottom: 24,
    display: "flex",
    gap: 8,
    "& > :nth-child(1)": { width: "30%" },
    "& > :nth-child(2)": { width: "calc(70% - 8px)" },
    [`.${classNameRecord.narrowLayout} &`]: {
      maxWidth: "none",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 16,
      "& > :nth-child(1)": { width: "100%" },
      "& > :nth-child(2)": { width: "100%" },
    },
  }),
});

const FormField = ({
  title,
  content,
}: {
  title: ReactNode;
  content: ReactNode;
}) => {
  return (
    <div css={styles.formFieldWrapper}>
      <div>{title}</div>
      <div>{content}</div>
    </div>
  );
};

const Org: FC = () => {
  const { t } = useTranslation();
  const openZendesk = useOpenZendesk();
  const domRectObserver = useDomRectObserver();

  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.org.useGetById(
    {
      params: {
        orgId,
      },
    },
    {
      useErrorBoundary: true,
      suspense: true,
    },
  );

  const planQuery = cantata.orgPlan.useGetById(
    {
      params: {
        orgId,
      },
    },
    {
      useErrorBoundary: true,
      suspense: true,
    },
  );

  const className = useMemo(
    () =>
      classNames(
        (domRectObserver.rect?.width ?? 0) < 1200
          ? classNameRecord.narrowLayout
          : undefined,
      ),
    [domRectObserver.rect?.width],
  );

  if (!query.isSuccess || !planQuery.isSuccess) return null;

  return (
    <div ref={domRectObserver.ref}>
      <div css={styles.formContent} className={className}>
        <FormField
          title={<BoldText>{t("glossary.orgAvatar")}</BoldText>}
          content={<OrgLogo />}
        />
        <FormField
          title={<BoldText>{t("glossary.company")}</BoldText>}
          content={<OrgName />}
        />
        <FormField
          title={<BoldText>{t("glossary.plan")}</BoldText>}
          content={
            <Input
              value={t(
                organizationPlanTypeTranslationKeyMap[planQuery.data.type],
              )}
              disabled
            />
          }
        />
        <FormField
          title={<BoldText>{t("glossary.owner")}</BoldText>}
          content={<OwnerInfo />}
        />
        <FormField
          title={<BoldText>{t("glossary.planExpirationDate")}</BoldText>}
          content={
            <Text>
              <DisplayDate value={planQuery.data.expiredAt} />
            </Text>
          }
        />
        <FormField
          title={<BoldText>{t("glossary.seatState")}</BoldText>}
          content={
            <Text>
              <UsersCount orgId={orgId} excludeInternal /> /{" "}
              {planQuery.data.seatNum}
            </Text>
          }
        />
        <div>
          <Button type="primary" onClick={openZendesk}>
            {t("organization.addSeats")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const WrappedOrg: FC = () => {
  const { t } = useTranslation();
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header>{t("organization.basicSetting")}</MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          <ErrorBoundary>
            <MainLayout.Section>
              <Org />
            </MainLayout.Section>
            <MainLayout.Section>
              <OrgTwoFactor />
            </MainLayout.Section>
          </ErrorBoundary>
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
};

export { WrappedOrg as Org };
