import { css } from "@emotion/react";
import type { FC } from "react";

import { Trans } from "@/app/i18n/Trans";
import { PermissionChecker } from "@/components/PermissionChecker";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { UnifyKeyLabel } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/CdpProfile/UnifyKeyLabel";
import { Field } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/Field";
import { CustomIdField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/CustomIdField";
import { DisplayEmailField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/DisplayEmailField";
import { DisplayMobileField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/DisplayMobileField";
import {
  ExternalMemberIdField,
  ExternalMemberIdLabel,
} from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/ExternalMemberId";
import { FormProvider } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/FormProvider";
import { ReadOnly } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/ReadOnly";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  DefaultProfile: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
});

const DefaultProfile: FC = () => {
  const profile = memberQueriesContext.useMember();

  return (
    <div css={styles.DefaultProfile}>
      <Field
        label={<ExternalMemberIdLabel />}
        content={<ExternalMemberIdField />}
      />
      <Field
        label={
          <UnifyKeyLabel unifyKey="display_mobile">
            <Trans i18nKey="chat.mobile" />
          </UnifyKeyLabel>
        }
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={
              <ReadOnly>
                {profile.displayMobile || profile.originalMobile}
              </ReadOnly>
            }
          >
            <FormProvider fieldName="displayMobile">
              <DisplayMobileField />
            </FormProvider>
          </PermissionChecker>
        }
      />
      <Field
        label={
          <UnifyKeyLabel unifyKey="display_email">
            <Trans i18nKey="glossary.email" />
          </UnifyKeyLabel>
        }
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={
              <ReadOnly>
                {profile.displayEmail || profile.originalEmail}
              </ReadOnly>
            }
          >
            <FormProvider fieldName="displayEmail">
              <DisplayEmailField />
            </FormProvider>
          </PermissionChecker>
        }
      />
      <Field
        label={
          <UnifyKeyLabel unifyKey="custom_id">
            <Trans i18nKey="chat.customerId" />
          </UnifyKeyLabel>
        }
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={<ReadOnly>{profile.customId}</ReadOnly>}
          >
            <FormProvider fieldName="customId">
              <CustomIdField />
            </FormProvider>
          </PermissionChecker>
        }
      />
    </div>
  );
};

export { DefaultProfile };
