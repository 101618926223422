import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import type { FormProps } from "@/components/Form";
import { Form } from "@/components/Form";
import { Input } from "@/components/Input";
import { useMessage } from "@/components/message";
import { MotifIcon } from "@/components/MotifIcon";
import { PermissionChecker } from "@/components/PermissionChecker";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { getMemberDisplayName } from "@/resources/member/displayName";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { defineStyles } from "@/shared/emotion";
import { useBlurInputOnEnter } from "@/shared/hooks/useBlurInputOnEnter";

const styles = defineStyles({
  name: css({
    color: theme.colors.neutral010,
    fontSize: "1rem",
    fontWeight: "bold",
  }),
  nameWrapper: css({
    display: "flex",
    flex: "1 1 auto",
    alignItems: "center",
    gap: "inherit",
  }),
  editButton: css({
    color: theme.colors.neutral005,
    "&:hover": {
      color: theme.colors.blue005,
    },
  }),
});

type FormValues = {
  name: string | undefined;
};

const MemberName = memo(function MemberName() {
  const [isEditing, toggleEditing] = useSwitch(false);
  const [form] = Form.useForm<FormValues>();
  const enterBlur = useBlurInputOnEnter();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();
  const message = useMessage();
  const profile = memberQueriesContext.useMember();
  const hasUnifyKeyQuery = cantata.org.useGetUnifyScopeSetting(
    {
      params: { orgId },
    },
    {
      select(data) {
        return { hasUnifyKey: data.unifyKeys.length > 0 };
      },
    },
  );
  const mutation = cantata.member.usePartialUpdate(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      onSuccess: () => {
        toggleEditing.off();
        message.success(
          hasUnifyKeyQuery.data?.hasUnifyKey ? (
            <message.Container>
              <message.Title>
                <Trans i18nKey="common.updatedSuccessfully" />
              </message.Title>
              <message.Content>
                <Trans i18nKey="chat.profile.cdp.syncing.label" />
              </message.Content>
            </message.Container>
          ) : (
            <Trans i18nKey="common.updatedSuccessfully" />
          ),
        );
      },
    },
  );

  const onBlur = useHandler(() => {
    form.submit();
  });

  const onFinish = useHandler<FormProps<FormValues>["onFinish"]>(
    function onSubmit(values) {
      const { name } = values;
      toggleEditing.off();

      // if name is not changed, do nothing
      if (name === undefined || name === profile.displayName) return;

      mutation.mutate({
        displayName: name,
      });
    },
  );

  const memberName = getMemberDisplayName(profile);

  return (
    <PermissionChecker
      to="editMemberProfile"
      fallback={<div css={styles.name}>{memberName}</div>}
    >
      {isEditing ? (
        <Form<FormValues>
          form={form}
          disabled={mutation.isLoading}
          onFinish={onFinish}
          routerPromptOptions={{
            disabled: true,
          }}
        >
          <Form.Item
            name="name"
            noStyle
            getValueProps={(v) => ({ value: v ?? memberName })}
          >
            <Input
              {...enterBlur.bind}
              autoFocus
              placeholder={profile.originalName}
              onBlur={onBlur}
              maxLength={255}
            />
          </Form.Item>
        </Form>
      ) : (
        <div css={styles.nameWrapper}>
          <div css={styles.name}>{memberName}</div>
          <NarrowIconButton
            css={styles.editButton}
            size="middle"
            iconSize="small"
            onClick={toggleEditing.on}
            icon={<MotifIcon un-i-motif="edit" />}
          />
        </div>
      )}
    </PermissionChecker>
  );
});

export { MemberName };
