import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import type { ElementRef, ElementType, ForwardedRef } from "react";
import { useMemo } from "react";
import { z } from "zod";

import { useNumberFormat } from "@/components/NumberFormat/hooks/useNumberFormat";
import { defineStyles } from "@/shared/emotion";
import type { AvailableLanguage } from "@/shared/hooks/formatLangCode";

const defaultComponent = "span";

type DefaultComponent = typeof defaultComponent;

const NumberFormatPresetSchema = z.enum([
  "count",
  "percent",
  "revenue",
  "currency",
]);

const CurrencyDisplaySchema = z.enum([
  "code",
  "name",
  "symbol",
  "narrowSymbol",
]);

interface NumberFormatOwnProps {
  /**
   * Optionally customize the default fallback value.
   */
  fallback?: string;
  /**
   * Select a number formatting preset: `count` (default), `percent`, `revenue`, or `currency`.
   */
  numberFormatPreset?: z.infer<typeof NumberFormatPresetSchema>;
  /**
   * Standard `Intl.NumberFormat` options object. Will override preset options.
   */
  numberFormatOptions?: Intl.NumberFormatOptions;
  /**
   * Locale to use for number formatting. Defaults to the current i18n language.
   */
  numberFormatLocale?: AvailableLanguage;
  /**
   * Any value in need of formatting. Should be a `number` or `bigint`.
   */
  value: number | bigint;
  /**
   * The component used for the root node.
   */
  component?: ElementType;
}

interface NumberFormatTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & NumberFormatOwnProps;
  defaultComponent: RootComponent;
}

type NumberFormatProps<
  RootComponent extends ElementType = NumberFormatTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = NumberFormatOwnProps &
  OverrideProps<
    NumberFormatTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: ElementType;
  };

const styles = defineStyles({
  root: css({
    fontVariantNumeric: "tabular-nums",
  }),
});

/**
 * A simple wrapper component for displaying numbers with consistent formatting.
 */
const NumberFormatInternal: OverridableComponent<NumberFormatTypeMap> =
  forwardRef(function NumberFormatInternal(
    {
      fallback,
      numberFormatPreset = "count",
      numberFormatOptions,
      numberFormatLocale,
      value,
      component: Component = "span" satisfies DefaultComponent,
      ...props
    }: NumberFormatProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    const { numberFormat } = useNumberFormat({
      fallback,
      numberFormatLocale,
      numberFormatPreset,
      numberFormatOptions,
    });

    const valueFormatted = useMemo(
      () => numberFormat(value),
      [numberFormat, value],
    );

    return (
      <Component css={styles.root} ref={ref} {...props}>
        {valueFormatted}
      </Component>
    );
  }) as OverridableComponent<NumberFormatTypeMap>;

const NumberFormat = Object.assign(NumberFormatInternal, {
  NumberFormatPresetSchema,
  CurrencyDisplaySchema,
});

assignDisplayName(NumberFormat, "NumberFormat");

export { defaultComponent, NumberFormat };

export type { NumberFormatOwnProps, NumberFormatProps, NumberFormatTypeMap };
