import {
  AlignLeftOutlined,
  PaperClipOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import type { FormListFieldData, FormListOperation } from "antd";
import { type FC, type ReactNode, useMemo } from "react";

import type { CantataTypes } from "@/cantata/types";
import { SortableDndContext } from "@/components/dnd/SortableDndContext";
import { FormItem } from "@/components/Form";
import { MotifIcon } from "@/components/MotifIcon";
import { RoundPictureOutlined } from "@/shared/icons/common/RoundPictureOutlined";

const Wrapper = styled.div`
  display: flex;
  width: 64px;
  height: 32px;
  flex-direction: row;
  align-items: center;
  border-radius: ${theme.shape.borderRadius};
  cursor: move;
  gap: 4px;
`;

const IconWrapper = styled.div<{ $size?: string }>`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.shape.borderRadius};
  background: ${theme.colors.white};
  color: ${theme.colors.neutral005};
  font-size: ${({ $size = "21px" }) => $size};
`;

const IndexSpan = styled.span`
  display: inline-block;
  width: 8px;
  color: ${theme.colors.neutral005};
  font-size: 12px;
  line-height: 16px;
`;

const ResizeIconWrapper = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.neutral005};
`;

const MessageSorter = styled.div`
  --border-radius: ${theme.shape.borderRadius};

  position: absolute;
  top: 90px;
  right: -72px;
  display: flex;
  flex-direction: column;
  padding: 8px 4px;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  background: ${theme.colors.neutral001};
  gap: 8px;
`;

const MessageIcon = ({
  value,
}: {
  value?: CantataTypes["QuickTemplateDetail"]["messages"][number];
}) => {
  switch (value?.type) {
    case "text":
      return (
        <IconWrapper>
          <AlignLeftOutlined />
        </IconWrapper>
      );
    case "image":
      return (
        <IconWrapper $size="24px">
          <RoundPictureOutlined />
        </IconWrapper>
      );
    case "video":
      return (
        <IconWrapper>
          <PlayCircleOutlined />
        </IconWrapper>
      );
    case "file":
      return (
        <IconWrapper>
          <PaperClipOutlined />
        </IconWrapper>
      );
    default:
      return null;
  }
};

const Item: FC<{
  field: FormListFieldData;
  index: number;
}> = ({ field: { key, ...field }, index }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: key });

  const style: ComponentProps<"div">["style"] = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
    }),
    [transform, transition],
  );
  return (
    <Wrapper ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <IndexSpan>{index + 1}</IndexSpan>
      <FormItem {...field} noStyle>
        <MessageIcon />
      </FormItem>
      <ResizeIconWrapper>
        <MotifIcon un-i-motif="arrow_vertical" />
      </ResizeIconWrapper>
    </Wrapper>
  );
};

export const messageSortFactory = (
  fields: FormListFieldData[],
  { move }: FormListOperation,
): ReactNode => {
  return (
    <SortableDndContext
      direction="vertical"
      onDragEnd={(e) => {
        if (!e.over) return;
        const over = e.over;
        const activeIndex = fields.findIndex(({ key }) => key === e.active.id);
        if (activeIndex === -1) return;
        const overIndex = fields.findIndex(({ key }) => key === over.id);
        if (overIndex === -1) return;
        if (activeIndex === overIndex) return;
        move(activeIndex, overIndex);
      }}
      items={fields.map(({ key }) => key)}
    >
      <MessageSorter>
        {fields.map((field, index) => (
          <Item key={field.key} field={field} index={index} />
        ))}
      </MessageSorter>
    </SortableDndContext>
  );
};
