import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { useUseAbortControllerStore } from "@zeffiroso/utils/react/abortControllerStore";
import { memo } from "@zeffiroso/utils/react/memo";
import { Radio } from "antd";
import type { ComponentProps, ReactNode } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { fbSdk } from "@/app/fbSdk";
import { useGetFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { Button } from "@/components/Button";
import { DisabledContextProvider } from "@/components/Form/DisabledContext";
import { useMessage } from "@/components/message";
import { Modal } from "@/components/Modal";
import type { featureFlags } from "@/config/featureFlags";
import { ChannelTypeIcon } from "@/resources/channel/ChannelTypeIcon";
import { channelTypeScopeMap } from "@/resources/channel/facebook/scope";
import { useBulkCreateChannelMutation } from "@/resources/channel/mutations";
import { useGetErrorMessage } from "@/shared/application/error/handleError";
import { handleNonGlobalApiError } from "@/shared/domains/error";
import { fakeT } from "@/shared/g11n/fakeT";

const cssAddChannelRadio = {
  self: css`
    width: 100%;
    padding: 12px;
    border-radius: ${theme.shape.borderRadius};

    &.ant-radio-wrapper-checked {
      background-color: ${theme.colors.blue001};
    }
  `,
  content: css`
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    gap: 10px;
    line-height: 1.42;
  `,
  icon: css`
    font-size: 2.5rem;
  `,
  name: css`
    margin-bottom: 4px;
    font-weight: 700;
  `,
  description: css`
    color: ${theme.colors.neutral007};
  `,
};

const channelTypeToOptionI18nKeyMap: Record<
  CantataTypes["ChannelType"],
  { name: string; description: string }
> = (() => {
  const t = fakeT;
  return {
    line: {
      name: "Line",
      description: "Not available yet",
    },
    fb: {
      name: t("organization.channels.add.modal.fb.name"),
      description: t("organization.channels.add.modal.fb.description"),
    },
    ig: {
      name: t("organization.channels.add.modal.ig.name"),
      description: t("organization.channels.add.modal.ig.description"),
    },
    wccs: {
      name: "Web Chat",
      description: "Not available yet",
    },
  };
})();

const translationI18nKeyMap = (() => {
  const t = fakeT;
  return define<
    Record<
      Extract<CantataTypes["ChannelType"], "fb" | "ig">,
      {
        addChannelFailedTitle: string;
        addChannelFailedDescription: {
          channelBelongToAnotherOrg: string;
          common: string;
        };
        createChannelSuccess: string;
        loginFailedMessage: string;
      }
    >
  >({
    fb: {
      addChannelFailedTitle: t("organization.channels.addFbFailed.modal.title"),
      addChannelFailedDescription: {
        channelBelongToAnotherOrg: t(
          "organization.channels.addFbFailed.modal.conflict.description",
        ),
        common: t("organization.channels.addFbFailed.modal.description"),
      },
      createChannelSuccess: t("organization.channels.addFbSuccess.message"),
      loginFailedMessage: t("organization.channels.loginFbFailed.message.text"),
    },
    ig: {
      addChannelFailedTitle: t("organization.channels.addIgFailed.modal.title"),
      addChannelFailedDescription: {
        channelBelongToAnotherOrg: t(
          "organization.channels.addIgFailed.modal.conflict.description",
        ),
        common: t("organization.channels.addIgFailed.modal.description"),
      },
      createChannelSuccess: t("organization.channels.addIgSuccess.message"),
      loginFailedMessage: t("organization.channels.loginIgFailed.message.text"),
    },
  });
})();

const availableChannels = define<
  Array<{
    channelType: CantataTypes["Channel"]["type"];
    requireFeature?: keyof typeof featureFlags;
  }>
>([
  {
    channelType: "fb",
  },
  {
    channelType: "ig",
  },
]);

const AddChannel = memo(function AddChannel() {
  const [addModalOpen, toggleAddModalOpen] = useSwitch(false);
  const [failReason, setFailReason] = useState<ReactNode>(null);
  const [channelType, setChannelType] =
    useState<Extract<CantataTypes["ChannelType"], "fb" | "ig">>("fb");
  const checkFeature = useGetFeatureFlag();
  const { t } = useTranslation();
  const message = useMessage();
  const getErrorMessage = useGetErrorMessage();
  const useAbortControllerStore = useUseAbortControllerStore();
  const createChannelsMutation = useBulkCreateChannelMutation({
    onSuccess() {
      toggleAddModalOpen.off();
      message.success(
        t(translationI18nKeyMap[channelType].createChannelSuccess),
      );
    },
    async onError(error) {
      toggleAddModalOpen.off();
      const handled = await handleNonGlobalApiError(error, {
        CHANNEL_BELONG_TO_ANOTHER_ORG() {
          setFailReason(
            t(
              translationI18nKeyMap[channelType].addChannelFailedDescription
                .channelBelongToAnotherOrg,
            ),
          );
        },
      });
      /**
       * If the error is handled, return early.
       */
      if (handled) return;
      /**
       * Fallback to the default error message.
       */
      setFailReason(
        getErrorMessage(error, {
          unknownErrorMessage: t(
            translationI18nKeyMap[channelType].addChannelFailedDescription
              .common,
          ),
        }),
      );
    },
  });

  const handleClose = useHandler(function handleClose() {
    setFailReason(undefined);
  });

  const abortAndReset = useHandler(function abortAndReset() {
    createChannelsMutation.reset();
    useAbortControllerStore.getState().abort();
  });

  useEffect(() => {
    return function cleanUp() {
      abortAndReset();
    };
  }, [abortAndReset]);

  const handleCancel = useHandler(function handleCancel() {
    if (createChannelsMutation.isLoading) return;

    toggleAddModalOpen.off();
  });

  const handleChange = useHandler<
    ComponentProps<typeof Radio.Group>["onChange"]
  >(function handleChange(event) {
    setChannelType(event.target.value);
  });

  const handleNext = useHandler(function handleNext() {
    if (createChannelsMutation.isLoading) return;

    loginMutation.mutate({
      loginOptions: {
        scope: channelTypeScopeMap[channelType],
      },
    });
  });

  const loginMutation = fbSdk.hooks.mutations.useLoginMutation({
    onSuccess(data) {
      if (data.status !== "connected") {
        message.error(
          t(translationI18nKeyMap[channelType].loginFailedMessage) +
            inspectMessage`, status: ${data.status}`,
        );
        return;
      }

      useAbortControllerStore.getState().abort();
      createChannelsMutation.mutate({
        channelType,
        signal: useAbortControllerStore.getState().signal,
      });
    },
    onError(e) {
      message.error(
        getErrorMessage(e, {
          unknownErrorMessage: t(
            translationI18nKeyMap[channelType].loginFailedMessage,
          ),
        }),
      );
    },
  });

  return (
    <>
      <Button type="primary" onClick={toggleAddModalOpen.on}>
        <Trans i18nKey="organization.channels.add" />
      </Button>
      <DisabledContextProvider disabled={createChannelsMutation.isLoading}>
        <Modal
          open={addModalOpen}
          onCancel={handleCancel}
          title={<Trans i18nKey="organization.channels.add.modal.title" />}
          footer={[
            <Button key="cancel" type="default" onClick={handleCancel}>
              <Trans i18nKey="common.cancel" />
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleNext}
              loading={createChannelsMutation.isLoading}
            >
              <Trans i18nKey="organization.channels.add.modal.confirm" />
            </Button>,
          ]}
        >
          <Radio.Group
            onChange={handleChange}
            value={channelType}
            style={{ display: "block" }}
          >
            {availableChannels.map((channel) =>
              channel.requireFeature &&
              !checkFeature(channel.requireFeature) ? null : (
                <Radio
                  key={`channelType_${channel.channelType}`}
                  value={channel.channelType}
                  css={cssAddChannelRadio.self}
                >
                  <div css={cssAddChannelRadio.content}>
                    <ChannelTypeIcon
                      channelType={channel.channelType}
                      css={cssAddChannelRadio.icon}
                    />
                    <div>
                      <div css={cssAddChannelRadio.name}>
                        {t(
                          channelTypeToOptionI18nKeyMap[channel.channelType]
                            .name,
                        )}
                      </div>
                      <div css={cssAddChannelRadio.description}>
                        {t(
                          channelTypeToOptionI18nKeyMap[channel.channelType]
                            .description,
                        )}
                      </div>
                    </div>
                  </div>
                </Radio>
              ),
            )}
          </Radio.Group>
        </Modal>
      </DisabledContextProvider>
      <Modal
        open={Boolean(failReason)}
        onCancel={handleClose}
        title={
          <Trans
            i18nKey={translationI18nKeyMap[channelType].addChannelFailedTitle}
          />
        }
        footer={[
          <Button key="confirm" type="primary" onClick={handleClose}>
            <Trans i18nKey="organization.channels.addFbFailed.modal.confirm" />
          </Button>,
        ]}
      >
        {failReason}
      </Modal>
    </>
  );
});

export { AddChannel };
