import { compile } from "path-to-regexp";
import type { To as unsafeTo } from "react-router-dom";
import strictUriEncode from "strict-uri-encode";
import type { Tagged } from "type-fest";

import type { BasePath, To } from "@/router/types";

function compileTo<TPath extends BasePath>(to: To<TPath>): unsafeTo {
  if (!to || !(typeof to === "object") || !("params" in to)) return to;
  const { params, ...rest } = to;
  const compiledPathname = compile(to.pathname, {
    encode: strictUriEncode,
  })(to.params);
  return {
    ...rest,
    pathname: compiledPathname,
  } as any;
}

/**
 * Type of a compiled `to` object to a string.
 */
type CompiledToString = Tagged<string, "compiledToString">;

/**
 * Compile a valid `to` object to a string.
 */
function compileToString<TPath extends BasePath>(
  to: To<TPath>,
): CompiledToString {
  const compiledTo = compileTo(to);
  return (typeof compiledTo === "string"
    ? compiledTo
    : `${compiledTo.pathname ?? ""}${compiledTo.search ?? ""}${compiledTo.hash ?? ""}`) satisfies string as CompiledToString;
}

export { compileTo, compileToString };
export type { CompiledToString };
