import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import type { FC } from "react";
import { useEffect } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantataClient, cantataUtils } from "@/cantata";
import { memberIdUtils } from "@/resources/member/memberIdUtils";

/**
 * Background will real time sync the data from EC to CDH.
 */
const SyncMembership: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();
  const invalidateQueries = cantataUtils.commerceProfile.useInvalidateQueries();

  useEffect(
    function syncMembership() {
      const abortController = new AbortController();

      (async function sync() {
        await safePromise(() =>
          cantataClient.commerceProfile.syncCommerceData(undefined, {
            params: {
              orgId,
              memberId,
            },
            signal: abortController.signal,
          }),
        );

        if (abortController.signal.aborted) return;

        invalidateQueries();
      })();

      return function cleanup() {
        abortController.abort();
      };
    },
    [invalidateQueries, memberId, orgId],
  );

  return null;
};

export { SyncMembership };
