import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { OverrideWith } from "@zeffiroso/utils/type/object/OverrideWith";
import { type ElementRef, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Button } from "@/components/Button";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { MotifIcon } from "@/components/MotifIcon";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { AvatarAndName } from "@/resources/message/AvatarAndName";
import { MessageImagePreview } from "@/resources/message/MessageImagePreview";
import { MessageVideoPreview } from "@/resources/message/MessageVideoPreview";
import { isFileMetadata } from "@/resources/message/utils";
import { useJumpToMessageController } from "@/routes/Chat/ui/jumpToMessage";
import { cssLineClamp, cssWrap, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css([
    cssWrap,
    {
      textAlign: "left",
      height: "auto",
      backgroundColor: "transparent",
      padding: 0,
      boxShadow: "none",
      color: theme.colors.neutral009,
      width: "100%",
      border: 0,
    },
  ]),
  innerWrapper: css({
    display: "flex",
    gap: 4,
    flexDirection: "column",
    "&>*": {
      maxWidth: "100%",
    },
  }),
  content: css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
  }),
  messageText: css({
    width: "100%",
    overflow: "hidden",
    color: theme.colors.neutral007,
  }),
  messageWithPreview: css({
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4,
  }),
  avatarAndName: css({
    display: "flex",
    alignItems: "center",
    fontSize: "0.75rem",
    gap: 4,
    overflow: "hidden",
  }),
});

declare namespace ReplyMessage {
  export type Ref = ElementRef<typeof Button>;
  export type Props = OverrideWith<
    ComponentProps<typeof Button>,
    {
      messageId: CantataTypes["MessageDetail"]["id"];
      /**
       * The maximum number of lines of text to display before truncating with an ellipsis.
       * default: 1
       */
      textMaxVisibleLines?: number;
      overrideStyles?: Partial<
        Record<"avatarAndName" | "message", ReturnType<typeof css>>
      >;
    }
  >;
}

const ReplyMessageById = forwardRef<ReplyMessage.Ref, ReplyMessage.Props>(
  function ReplyMessage(props, ref) {
    const { messageId, textMaxVisibleLines, overrideStyles, ...buttionProps } =
      props;
    const orgId = useActiveOrgIdStore((state) => state.value);
    const member = memberQueriesContext.useMember();
    const messageQuery = cantata.message.useGetById(
      {
        params: {
          orgId,
          memberId: member.id,
          messageId: props.messageId,
        },
      },
      {
        enabled: props.messageId !== 0,
        useErrorBoundary: true,
        suspense: true,
      },
    );

    const jumpToMessageController = useJumpToMessageController();
    const jumpTo = useHandler<ComponentProps<typeof Button>["onClick"]>(
      function jumpTo() {
        if (!messageQuery.isSuccess) return;
        jumpToMessageController.setup(messageQuery.data);
      },
    );

    const content = useMemo(() => {
      if (!messageQuery.isSuccess) return null;

      const message = messageQuery.data.message;

      switch (message.type) {
        case "text":
          return (
            <div css={styles.content}>
              <div
                css={css([
                  styles.messageText,
                  cssLineClamp(props.textMaxVisibleLines ?? 1),
                  props.overrideStyles?.message,
                ])}
              >
                {messageQuery.data.message.text}
              </div>
            </div>
          );
        case "image":
          return (
            <MessageImagePreview
              css={props.overrideStyles?.message}
              src={message.previewUrl}
            />
          );
        case "video":
          return (
            <MessageVideoPreview
              css={props.overrideStyles?.message}
              src={message.originUrl}
            />
          );
        case "file":
          return (
            <div
              css={css([
                styles.content,
                styles.messageWithPreview,
                props.overrideStyles?.message,
              ])}
            >
              {!isFileMetadata(message.metadata) ? null : (
                <Trans
                  i18nKey="chat.replyMessage.file.desc"
                  values={{
                    fileName: message.metadata.filename,
                  }}
                />
              )}
              <MotifIcon un-i-motif="attachment" />
            </div>
          );
        default:
          message.type satisfies "audio" | "line_flex" | "ig_story_mention";
          throw new Error(`Unsupported message type: ${message.type}`);
      }
    }, [
      messageQuery.data?.message,
      messageQuery.isSuccess,
      props.overrideStyles?.message,
      props.textMaxVisibleLines,
    ]);

    if (messageId === 0)
      return (
        <span css={css([styles.messageText, props.overrideStyles?.message])}>
          <Trans i18nKey="chat.replyMessage.getContentFailed.desc" />
        </span>
      );

    if (!messageQuery.isSuccess) return null;

    const message = messageQuery.data.message;

    return (
      <Button css={styles.root} ref={ref} onClick={jumpTo} {...buttionProps}>
        <div css={styles.innerWrapper} {...{ inert: "" }}>
          <AvatarAndName
            orgId={orgId}
            member={member}
            message={message}
            avatarProps={{
              size: 16,
            }}
            css={props.overrideStyles?.avatarAndName}
          />
          {content}
        </div>
      </Button>
    );
  },
);

assignDisplayName(ReplyMessageById, "ReplyMessage");

const Wrapper = forwardRef<ReplyMessage.Ref, ReplyMessage.Props>(
  function Wrapper(props, ref) {
    return (
      <ErrorBoundary.Alert>
        <ReplyMessageById {...props} ref={ref} />
      </ErrorBoundary.Alert>
    );
  },
);

assignDisplayName(Wrapper, "ReplyMessageById");

export { Wrapper as ReplyMessageById };
