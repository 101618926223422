import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { memo } from "@zeffiroso/utils/react/memo";
import type { ComponentProps, ElementRef } from "react";

const spinning = keyframes`
  0% {
    transform: translate(-50%, 100px);
  }
  100% {
    transform: translate(-50%, -50%) rotate(500deg);
  }
`;

const spinning2 = keyframes`
  0% {
    transform: translate(-50%, 150px);
  }
  100% {
    transform: translate(-50%, -50%) rotate(720deg);
  }
`;

const WaveContainer = styled.div`
  display: grid;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  place-items: center;
`;

const WaverInner = styled.div`
  position: relative;
  overflow: hidden;
  width: 150px;
  height: 150px;
  border: 0 solid transparent;
  border-radius: 75px;
  background: #fff;

  /* for safari wired border-radius overflow issue */
  will-change: transform;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    border-radius: 100px;
    animation: ${spinning2} 4.5s infinite ease-in-out;
    background: #1890ff;
    content: "";
    transform: translate(-50%, -50%);
  }

  &::after {
    position: absolute;
    top: 70%;
    left: 60%;
    width: 250px;
    height: 250px;
    border-radius: 80px;
    animation: ${spinning} 5s infinite ease-in-out;
    background: #1890ff;
    content: "";
    transform: translate(-50%, -50%);
  }
`;

type WaveLoadingProps = ComponentProps<typeof WaveContainer>;
type WaveLoadingRef = ElementRef<typeof WaveContainer>;

const WaveLoading = memo(
  forwardRef<WaveLoadingRef, WaveLoadingProps>(
    function WaveLoading(props, ref) {
      return (
        <WaveContainer ref={ref} {...props}>
          <WaverInner data-chromatic="ignore" />
        </WaveContainer>
      );
    },
  ),
);

export { WaveLoading };
export type { WaveLoadingProps, WaveLoadingRef };
