import { memo } from "@zeffiroso/utils/react/memo";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Modal } from "@/components/Modal";
import { useDeleteRule } from "@/routes/Settings/Assignment/pages/RoutingRules/application/routingRules";
import { DeleteOutlined } from "@/shared/icons/common/DeleteOutlined";

const RuleDeleteModal = ({
  open,
  onClose,
  ruleId,
  ruleName,
}: {
  ruleId: number;
  ruleName: string;
  open: boolean;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();
  const { isLoading, mutate } = useDeleteRule({
    autoAssignmentRuleId: ruleId,
  });

  return (
    <Modal
      title={t("assignment.deleteRuleModal.title", { routingName: ruleName })}
      open={open}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          type="default"
          onClick={(e) => {
            e.stopPropagation();
            onClose?.();
          }}
        >
          {t("common.cancel")}
        </Button>,
        <Button
          key="submit"
          data-test="confirm-delete-button"
          danger={true}
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            mutate(undefined);
          }}
          disabled={isLoading}
          loading={isLoading}
        >
          {t("common.delete")}
        </Button>,
      ]}
    >
      {t("assignment.deleteRuleModal.desc", { routingName: ruleName })}
    </Modal>
  );
};

export const RuleDeleteButton = memo<{
  ruleId: number;
  ruleName: string;
}>(function DeleteRule({ ruleId, ruleName }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <NarrowIconButton
        data-test={`rule-delete-button-${ruleId}`}
        onClick={() => setOpen(true)}
        size={20}
        icon={<DeleteOutlined />}
      />
      <RuleDeleteModal
        open={open}
        onClose={() => setOpen(false)}
        ruleId={ruleId}
        ruleName={ruleName}
      />
    </>
  );
});
