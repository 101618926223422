import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import type { UniqueIdentifier } from "@dnd-kit/core";
import type { SortableContext } from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import classNames from "classnames";
import { merge, omit, pick } from "lodash-es";
import type { ComponentProps, ElementRef, FC, Key, ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { ConditionalKeys } from "type-fest";

import type { NarrowIconButtonProps } from "@/components/Button/NarrowIconButton";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { SortableDndContext } from "@/components/dnd/SortableDndContext";
import { useDisabled } from "@/components/Form/DisabledContext";
import { MotifIcon } from "@/components/MotifIcon";
import { Tooltip } from "@/components/Tooltip";
import { defineStyles } from "@/shared/emotion";

import type { TableProps } from ".";
import { Table } from ".";

const seed = random();

const classNameRecord = {
  draggingRow: `draggingRow-${seed}`,
} satisfies Record<string, string>;

const styles = defineStyles({
  DndTable: css({
    "@layer emotion-component": {
      [`& .${classNameRecord.draggingRow}`]: {
        position: "relative",
        zIndex: 1,
      },
    },
  }),
  draggerIconButton: css({
    "@layer emotion-component": {
      "&": {
        width: 24,
        height: 24,
        color: theme.colors.neutral005,
        fontSize: 18,
      },
    },
  }),
  draggerColumnRender: css({
    "@layer emotion-component": {
      "&": {
        display: "flex",
        alignItems: "center",
        gap: 8,
      },
    },
  }),
});

const sortableDndContextPassthroughProps = [
  "onDragCancel",
  "onDragEnd",
  "onDragMove",
  "onDragOver",
  "onDragStart",
] as const satisfies Array<keyof ComponentProps<typeof SortableDndContext>>;

type SortableDndContextPassthroughProp =
  (typeof sortableDndContextPassthroughProps)[number];

namespace DndTable {
  // The row key must satisfies both the item key for dnd and the row key for the table.
  export type RawRowKey =
    // Item keys for dnd.
    UniqueIdentifier &
      // The row key for the table.
      Key;
  export interface Props<RecordType extends object = object>
    extends Overwrite<
      Overwrite<
        Omit<TableProps<RecordType>, "components">,
        Pick<
          ComponentProps<typeof SortableDndContext>,
          SortableDndContextPassthroughProp
        >
      >,
      {
        components?: Omit<TableProps<RecordType>["components"], "row">;
        sortableDndContextProps?: Omit<
          ComponentProps<typeof SortableDndContext>,
          "children" | "direction" | "items" | SortableDndContextPassthroughProp
        >;
        /**
         * Row key is required for dnd items.
         */
        rowKey:
          | ConditionalKeys<RecordType, RawRowKey>
          | ((record: RecordType) => RawRowKey);
      }
    > {}
  export type Ref = ElementRef<typeof Table>;
  export interface Type {
    <RecordType extends object = object>(
      props: Props<RecordType>,
      ref?: Ref,
    ): ReactNode;
  }

  export interface DraggerProps
    extends Overwrite<
      NarrowIconButtonProps,
      { tooltipProps?: Tooltip.Props }
    > {}

  export interface DraggerColumnOptions {
    draggerProps?: DraggerProps;
  }
}

const Dragger: FC<DndTable.DraggerProps> = ({ tooltipProps, ...props }) => {
  const { t } = useTranslation();
  const sortable = useContext(SortableRowContext);
  if (!sortable) {
    throw new Error("Dragger must be used inside a sortable row");
  }
  const { setActivatorNodeRef, listeners, isDragging } = sortable;
  return (
    <Tooltip
      title={t("dndTable.dragger.tooltip")}
      {...tooltipProps}
      {...(!isDragging ? null : { title: "" })}
    >
      <NarrowIconButton
        css={styles.draggerIconButton}
        {...props}
        icon={<MotifIcon un-i-motif="arrow_vertical" />}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    </Tooltip>
  );
};

/**
 * Default style for dragger columns.
 *
 * @example
 *
 * ```tsx
 * const draggerColumnRender = useMemo(
 *   () =>
 *     DndTable.generateDraggerColumnRender({
 *       draggerProps: {
 *         title: t(tooltipKey),
 *       },
 *     }),
 *   [t],
 * );
 * const columns = useMemo<Array<ColumnProps<RecordType>>>(
 *   () => [
 *     {
 *       title,
 *       render: draggerColumnRender,
 *     },
 *     // ...other columns.
 *   ],
 *   [draggerColumnRender],
 * );
 * ```
 */
const generateDraggerColumnRender: (
  options?: DndTable.DraggerColumnOptions,
) => NonNullable<DndTable.Props<any>["columns"]>[number]["render"] = (
  options,
) =>
  function render(_value, _record, index) {
    return (
      <div css={styles.draggerColumnRender}>
        <DndTable.Dragger {...options?.draggerProps} />
        <span>{index + 1}</span>
      </div>
    );
  };

type SortableRowContextProps = null | ReturnType<typeof useSortable>;

const SortableRowContext = createContext<SortableRowContextProps>(null);

type RowProps<RecordType extends object> = ReturnType<
  NonNullable<TableProps<RecordType>["onRow"]>
> & {
  index: number;
  data: RecordType;
};

const DraggableRow = <RecordType extends object>(
  props: ComponentProps<"tr"> &
    RowProps<RecordType> & {
      // Ant Design Table uses `data-row-key` to identify rows
      "data-row-key": string;
    },
) => {
  const sortable = useSortable({ id: props["data-row-key"] });

  const { attributes, setNodeRef, transform, transition, isDragging } =
    sortable;

  const style: ComponentProps<"tr">["style"] = useMemo(
    () => ({
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
    }),
    [props.style, transform, transition],
  );

  const className = useMemo(
    () =>
      classNames(
        isDragging ? classNameRecord.draggingRow : null,
        props.className,
      ),
    [isDragging, props.className],
  );

  return (
    <SortableRowContext.Provider value={sortable}>
      <tr
        {...props}
        className={className}
        ref={setNodeRef}
        style={style}
        {...attributes}
      />
    </SortableRowContext.Provider>
  );
};

const dndTableComponents = {
  body: {
    row: DraggableRow,
  },
} satisfies TableProps<object>["components"];

const DndTableInternal: DndTable.Type = forwardRef(function DndTableInternal<
  RecordType extends object = object,
>(
  { sortableDndContextProps, ...props }: DndTable.Props<RecordType>,
  ref?: DndTable.Props<RecordType>["ref"],
) {
  const tableProps = omit(props, sortableDndContextPassthroughProps);
  const rowKey = props.rowKey;
  const tableComponents = useMemo(
    () => merge({}, props.components, dndTableComponents),
    [props.components],
  );

  const formDisabled = useDisabled();
  const sortableContextItems: ComponentProps<typeof SortableContext>["items"] =
    useMemo(
      () =>
        !props.dataSource
          ? []
          : typeof rowKey === "function"
            ? props.dataSource.map(rowKey)
            : props.dataSource.map((i) => i[rowKey] as DndTable.RawRowKey),
      [props.dataSource, rowKey],
    );
  const sortableDndContextDisabled: ComponentProps<
    typeof SortableContext
  >["disabled"] = useMemo(
    () => sortableDndContextProps?.disabled ?? formDisabled,
    [formDisabled, sortableDndContextProps?.disabled],
  );

  return (
    <SortableDndContext
      {...sortableDndContextProps}
      {...(sortableDndContextDisabled === undefined
        ? null
        : { disabled: sortableDndContextDisabled })}
      {...pick(props, sortableDndContextPassthroughProps)}
      direction="vertical"
      items={sortableContextItems}
    >
      <Table
        {...tableProps}
        rowKey={
          // TypeScript don't understand the type of `rowKey` is narrowed by the
          // condition.
          rowKey as ComponentProps<typeof Table<RecordType>>["rowKey"]
        }
        css={styles.DndTable}
        components={tableComponents}
        ref={ref}
      />
    </SortableDndContext>
  );
}) as DndTable.Type;

/**
 * Antd Table with `dnd-kit`.
 *
 * @example
 * ```tsx
 * const columns = [
 *   {
 *     title: 'Order',
 *     render: DndTable.generateDraggerColumnRender(),
 *   },
 * ];
 *
 * return (
 *   <DndTable
 *     rowKey="id"
 *     columns={columns}
 *     onDragEnd={onDragEnd}
 *   />
 * );
 * ```
 *
 * Note: It is recommended to memoize the variables to avoid unnecessary computations.
 */
const DndTable = Object.assign(DndTableInternal, {
  Dragger,
  generateDraggerColumnRender,
  classNameRecord,
});

export { DndTable };
