import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import type { ComponentPropsWithRef } from "react";

import { cssWrap } from "@/shared/emotion";
import { getCssStr } from "@/shared/utils/style/getCssStr";
import { shouldNotForwardProps } from "@/shared/utils/style/shouldNotForwardProps";

const Headline2 = styled.h2`
  color: ${theme.colors.neutral010};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

type ParagraphProps = ComponentPropsWithRef<"p">;

const Paragraph = memo(
  forwardRef<HTMLParagraphElement, ParagraphProps>(function Paragraph(
    { ...props },
    ref,
  ) {
    return <p {...props} ref={ref} />;
  }),
);

assignDisplayName(Paragraph, "Paragraph");

const Text = styled.span`
  color: ${theme.colors.neutral010};
  font-size: 14px;
  line-height: 20px;
`;

const BoldText = styled(Text)`
  font-weight: 500;
`;

const LabelText = styled.div`
  ${cssWrap}
  color: ${theme.colors.neutral009};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const Description = styled.div`
  color: ${theme.colors.neutral007};
  font-size: 12px;
  line-height: 16px;
`;

const SubText = styled(Text)`
  color: ${theme.colors.neutral007};
`;

const Caption = styled(Text)`
  font-size: 12px;
  line-height: 16px;
`;

const Title = styled(Text)`
  color: ${theme.colors.neutral010};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
`;

const LinkText = styled(Text, {
  shouldForwardProp: shouldNotForwardProps(["$underline", "$disabled"]),
})<{
  $underline?: boolean;
  /**
   * Bad practice. Disable the outer component instead.
   */
  $disabled?: boolean;
}>`
  color: ${theme.colors.blue006};
  cursor: pointer;
  text-decoration: ${({ $underline }) => ($underline ? "underline" : "none")};

  :disabled &,
  .ant-btn-disabled & {
    color: ${theme.colors.neutral005};
    pointer-events: none;
  }

  ${({ $disabled }) =>
    $disabled
      ? getCssStr(
          (css) => css`
            color: ${theme.colors.neutral005};
            pointer-events: none;
          `,
        )
      : ""}
`;

export {
  BoldText,
  Caption,
  Description,
  Headline2,
  LabelText,
  LinkText,
  Paragraph,
  SubText,
  Text,
  Title,
};
