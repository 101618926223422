import { memo } from "@zeffiroso/utils/react/memo";
import { Fragment, useEffect } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { badge } from "@/app/sw/badge";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { BannerAlert } from "@/routes/Chat/ui/BannerAlert/BannerAlert";
import { ChatPanel } from "@/routes/Chat/ui/ChatPanel";
import { SendingMessagesProvider } from "@/routes/Chat/ui/ChatPanel/sendingMessages/Provider";
import { conversationHistoryMessages } from "@/routes/Chat/ui/conversationHistoryMessages";
import { DirectChatProvider } from "@/routes/Chat/ui/directChat";
import { JumpToMessageProvider } from "@/routes/Chat/ui/jumpToMessage";
import { Layout } from "@/routes/Chat/ui/Layout";
import { MemberProfilePanel } from "@/routes/Chat/ui/MemberProfilePanel";
import { MembersPanel } from "@/routes/Chat/ui/MembersPanel";
import { OutOfScope } from "@/routes/Chat/ui/OutOfScope";
import { SearchMessageFromAMemberProvider } from "@/routes/Chat/ui/searchMessageFromMember";
import { SideMenu } from "@/routes/Chat/ui/SideMenu";
import { UpdateMemberFromLegato } from "@/routes/Chat/ui/UpdateMemberFromLegato";

const Chat = memo(function Chat() {
  const activeMemberId = memberIdUtils.useGet();
  const showCloseDrawerButton = Layout.breakpointHooks.useLteMd();
  return (
    <>
      <DirectChatProvider>
        <BannerAlert />
        <JumpToMessageProvider>
          <SearchMessageFromAMemberProvider>
            <OutOfScope />
            <Layout
              sideMenu={<SideMenu />}
              sideMenuFooter={<SideMenu.Profile />}
              membersPanel={
                <ErrorBoundary.Alert fullSize>
                  <MembersPanel />
                </ErrorBoundary.Alert>
              }
              chatPanel={
                Number.isNaN(activeMemberId) ? (
                  <Fragment />
                ) : (
                  <ErrorBoundary.Alert fullSize>
                    <ChatPanel key={activeMemberId} />
                  </ErrorBoundary.Alert>
                )
              }
              memberProfilePanel={
                Number.isNaN(activeMemberId) ? (
                  <Fragment />
                ) : (
                  <ErrorBoundary.Alert fullSize>
                    <MemberProfilePanel
                      key={activeMemberId}
                      showCloseDrawerButton={showCloseDrawerButton}
                      onClose={Layout.profileDrawer.close}
                    />
                  </ErrorBoundary.Alert>
                )
              }
            />
          </SearchMessageFromAMemberProvider>
        </JumpToMessageProvider>
      </DirectChatProvider>
      {!activeMemberId ? null : <UpdateMemberFromLegato />}
    </>
  );
});

/**
 * Clear the badge when the chat is opened.
 */
const ClearBadge = memo(function ClearBadge() {
  useEffect(function clearBadgeWhenOpen() {
    badge.clear();
  }, []);
  useEffect(function clearBadgeWhenFocus() {
    const clearBadge = () => badge.clear();
    window.addEventListener("focus", clearBadge);
    return () => window.removeEventListener("focus", clearBadge);
  }, []);
  return null;
});

const WrappedChat = memo(function WrappedChat() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  return (
    <>
      <ClearBadge />
      <memberIdUtils.Provider key={orgId}>
        <conversationHistoryMessages.Provider>
          <SendingMessagesProvider>
            <Chat />
          </SendingMessagesProvider>
        </conversationHistoryMessages.Provider>
      </memberIdUtils.Provider>
    </>
  );
});

export { WrappedChat as Chat };
