import { makeApi } from "@zodios/core";
import { z } from "zod";

import { MemberProfileSchema, OrderSchema, PrizeSchema } from "../models";

const api = makeApi([
  {
    /**
     * Doc: [Notion](https://www.notion.so/cresclab/202411-Commerce-profile-6e7e78600cd64e6ba51103338002152f?pvs=4#bd6de5388042455cb922c65bd1518d3b)
     */
    alias: "profile",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/profile",
    response: MemberProfileSchema,
  },
  {
    /**
     * Doc: [Notion](https://www.notion.so/cresclab/202411-Commerce-profile-6e7e78600cd64e6ba51103338002152f?pvs=4#6370ce36e2f24691af22150c911512d5)
     */
    alias: "orders",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/orders",
    response: OrderSchema.array(),
  },
  {
    /**
     * Doc: [Notion](https://www.notion.so/cresclab/202411-Commerce-profile-6e7e78600cd64e6ba51103338002152f?pvs=4#68d3b14852b448fcb913d94fefaac904)
     */
    alias: "prizes",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/prizes",
    response: PrizeSchema.array(),
  },
  {
    /**
     * Doc: [Notion](https://www.notion.so/cresclab/202411-Commerce-profile-6e7e78600cd64e6ba51103338002152f?pvs=4#4fbefcbaa39e495eb434228e48b2fb23)
     */
    alias: "syncCommerceData",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/sync-commerce-data",
    response: z.unknown(),
  },
]);

export { api };
