import useSwitch from "@react-hook/switch";
import type { FC } from "react";

import { ShowMoreButton } from "@/components/Button/ShowMoreButton";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { CdpProfile } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/CdpProfile";
import { DefaultProfile } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/DefaultProfile";
import { GroupProfile } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/GroupProfile";

const ContactProfile: FC = () => {
  const [open, toggle] = useSwitch(false);
  return (
    <>
      {open ? <CdpProfile /> : <DefaultProfile />}
      <ShowMoreButton
        expanded={open}
        onChange={open ? toggle.off : toggle.on}
      />
    </>
  );
};

const ProfilePanel: FC = () => {
  const member = memberQueriesContext.useMember();
  return (
    <ErrorBoundary.Alert>
      {member.type === "line_group" ? <GroupProfile /> : <ContactProfile />}
    </ErrorBoundary.Alert>
  );
};

export { ProfilePanel };
