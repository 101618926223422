import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import useSwitch from "@react-hook/switch";
import { memo } from "@zeffiroso/utils/react/memo";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Form } from "@/components/Form";
import { Modal } from "@/components/Modal";
import { Select } from "@/components/Select";
import { useLegatoEvent } from "@/legato";
import { mutateSuccessMessage } from "@/resources/user/onDutyStatus/mutateSuccessMessage";
import type { UserOnDutyStatusSelectorProps } from "@/resources/user/onDutyStatus/UserOnDutyStatusSelector";
import { UserOnDutyStatusSelector } from "@/resources/user/onDutyStatus/UserOnDutyStatusSelector";
import { usePageInfo } from "@/routes/Settings/Teams/pages/TeamForm/usePageInfo";

function ConfirmModal({
  open,
  onOk,
  onCancel,
}: {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}) {
  return (
    <Modal
      title={<Trans i18nKey="team.editPage.updateDutyStatus.title" />}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText={<Trans i18nKey="common.confirm" />}
      cancelText={<Trans i18nKey="common.cancel" />}
    >
      <Trans i18nKey="team.editPage.updateDutyStatus.description" />
    </Modal>
  );
}

type FormValues = {
  onDutyStatus: CantataTypes["UserOnDutyStatus"];
};

const OnDutyStatus = memo(function OnDutyStatus({
  userId,
}: {
  userId: number;
}) {
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const pageInfo = usePageInfo();
  const [confirmModalOpen, toggleConfirmModal] = useSwitch(false);
  const confirmModalOk = useHandler(() => {
    toggleConfirmModal.off();
    form.submit();
  });
  const confirmModalCancel = useHandler(() => {
    toggleConfirmModal.off();
    form.resetFields();
  });
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { teamId } = usePageInfo();
  if (typeof teamId !== "number")
    throw new Error(inspectMessage`teamId is not a number. teamId: ${teamId}`);
  const [form] = Form.useForm<FormValues>();
  const onDutyStatus = Form.useWatch<FormValues["onDutyStatus"] | undefined>(
    "onDutyStatus",
    form,
  );
  const query = cantata.team.useListUsers({
    params: {
      orgId,
      teamId,
    },
  });
  useLegatoEvent("user-updated", (event) => {
    if (event.content.userId !== userId) return;
    safeInvalidateQuery(query.key);
  });
  const mutation = cantata.user.useUpdateOnDutyStatus(
    {
      params: {
        orgId,
        userId,
      },
    },
    {
      onSettled: () => {
        safeInvalidateQuery(query.key);
      },
    },
  );
  const onFinish = useHandler(async (values: FormValues) => {
    const result = await safePromise(() => mutation.mutateAsync(values));
    if (result.isSuccess) mutateSuccessMessage(values.onDutyStatus);

    form.resetFields();
  });
  const onDutyStatusOnChange = useHandler<
    UserOnDutyStatusSelectorProps["onChange"]
  >((value) => {
    form.setFieldValue("onDutyStatus", value);
    toggleConfirmModal.on();
  });
  if (query.isLoading) return <Select loading disabled />;
  if (query.isError) {
    return (
      <Form.Item
        hasFeedback
        validateStatus="error"
        help={inspectMessage`${query.error}`}
      >
        <Select disabled />
      </Form.Item>
    );
  }
  const user = query.data.users.find((user) => user.id === userId);
  if (!user) {
    return (
      <Form.Item hasFeedback validateStatus="error" help="User Not Found">
        <Select disabled />
      </Form.Item>
    );
  }
  return (
    <>
      <Form<FormValues>
        form={form}
        routerPromptOptions={{
          disabled: true,
        }}
        onFinish={onFinish}
        disabled={
          pageInfo.isView ||
          mutation.isLoading ||
          confirmModalOpen ||
          user.status !== "active"
        }
      >
        <Form.HiddenInput name="onDutyStatus" />
        <UserOnDutyStatusSelector
          value={onDutyStatus ?? user.onDutyStatus}
          onChange={onDutyStatusOnChange}
        />
      </Form>
      <ConfirmModal
        open={confirmModalOpen}
        onOk={confirmModalOk}
        onCancel={confirmModalCancel}
      />
    </>
  );
});

export { OnDutyStatus };
