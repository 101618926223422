import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { throttle } from "lodash-es";
import { useEffect, useMemo, useState } from "react";

namespace useDomRectObserver {
  export interface Options {
    throttleMs?: number;
  }
}

/**
 * A simple throttled hook to retrieve the DOM rect of an element.
 *
 * Provides an alternative workaround for container queries.
 */
function useDomRectObserver({
  throttleMs = 300,
}: useDomRectObserver.Options = {}) {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [rect, setRect] = useState<DOMRect | null>(null);
  const updateRect = useHandler(function updateRect() {
    if (!element) return setRect(null);
    setRect(element.getBoundingClientRect());
  });
  const clearRect = useHandler(function clearRect() {
    setRect(null);
  });
  useEffect(() => {
    if (!element) return;
    let cancel = false;

    function updateRectInternal() {
      if (cancel) return;
      updateRect();
    }

    updateRectInternal();

    const updateRectThrottled = throttle(updateRectInternal, throttleMs);

    const observer = new ResizeObserver(updateRectThrottled);
    observer.observe(element);
    return function cleanup() {
      cancel = true;
      clearRect();
      observer.disconnect();
    };
  }, [clearRect, element, throttleMs, updateRect]);
  const domRect = useMemo(() => ({ ref: setElement, rect }), [rect]);
  return domRect;
}

export { useDomRectObserver };
