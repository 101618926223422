import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { TableProps as AntTableProps } from "antd";
import { Table as AntTable } from "antd";
// eslint-disable-next-line no-restricted-imports -- Common usage not exposed by `antd`. Re-exporting improves DX.
import defaultLocale from "antd/es/locale/en_US";
// eslint-disable-next-line no-restricted-imports -- Common usage not exposed by `antd`. Re-exporting improves DX.
import type { ColumnsType as InternalColumnsType } from "antd/es/table";
// eslint-disable-next-line no-restricted-imports -- Common usage not exposed by `antd`. Re-exporting improves DX.
import type {
  GetRowKey,
  TableLocale as InternalTableLocale,
} from "antd/es/table/interface";
import type { IFuseOptions } from "fuse.js";
import { merge } from "lodash-es";
import type { ElementRef, ForwardedRef, Key, ReactNode, Ref } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { ConditionalKeys } from "type-fest";

import { Empty } from "@/components/Empty";
import type { Input } from "@/components/Input";
import { BarLoading } from "@/components/Loading/BarLoading";
import { ExpandError } from "@/components/Table/ExpandError";
import { ExpandIcon } from "@/components/Table/ExpandIcon";
import { ExpandLoading } from "@/components/Table/ExpandLoading";
import { useTableSearch } from "@/components/Table/utils/useTableSearch";
import { cssWrap, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  table: css({
    "@layer emotion-component": {
      "& .ant-table-tbody td, .ant-table-thead th": {
        verticalAlign: "middle",
      },
      "& .ant-table-thead > tr > th, .ant-table-thead > tr > td": {
        "&::before": {
          display: "none",
        },
        fontWeight: "normal",
      },
      "& .ant-table-cell": cssWrap,
      "& tr:hover": {
        "td.ant-table-column-sort": {
          // TODO: check if this is the correct color
          background: "#fafafa",
        },
      },
      ".ant-table-row:not(.ant-table-row-level-0) > td": {
        backgroundColor: theme.colors.neutral001,
      },
    },
  }),
  empty: css({
    minHeight: 366,
  }),
});

type TableRef = ElementRef<typeof AntTable>;

interface TableProps<RecordType extends object = object>
  extends Overwrite<
    AntTableProps<RecordType>,
    {
      /**
       * Stricter typing for the `rowKey` prop.
       */
      rowKey?: GetRowKey<RecordType> | ConditionalKeys<RecordType, Key>;
    }
  > {
  ref?: Ref<ElementRef<typeof AntTable>>;
  locale?: AntTableProps<RecordType>["locale"] & {
    emptyTextStyledCss?: SerializedStyles;
  };
}

type TableType = <RecordType extends object = object>(
  props: TableProps<RecordType>,
  ref?: TableRef,
) => ReactNode;

const defaultTableProps: Partial<TableProps> = {
  scroll: { x: 1000 },
};

/**
 * A styled `Table` component. Based on Ant Design's `Table`.
 */
const Table: TableType = forwardRef<TableRef, TableProps>(function Table<
  RecordType extends object = object,
>(props: TableProps<RecordType>, ref: ForwardedRef<TableRef>) {
  const { t } = useTranslation();
  const mergedProps = useMemo(
    () => merge({}, defaultTableProps, props),
    [props],
  );
  const { locale, loading, ...restProps } = mergedProps;
  const mergedLoading: TableProps<RecordType>["loading"] = useMemo(() => {
    if (!loading) return loading;
    return typeof loading === "boolean"
      ? {
          indicator: <BarLoading />,
          spinning: loading,
        }
      : {
          indicator: <BarLoading />,
          ...loading,
        };
  }, [loading]);

  const mergedLocale: TableProps<RecordType>["locale"] = useMemo(() => {
    const { emptyTextStyledCss, emptyText, ...restLocaleProps } = locale ?? {};
    return {
      emptyText: (
        <Empty css={styles.empty}>
          {typeof emptyText === "function"
            ? emptyText()
            : emptyText ?? t("common.noData")}
        </Empty>
      ),
      ...restLocaleProps,
    };
  }, [locale, t]);

  return (
    <AntTable
      css={styles.table}
      {...restProps}
      loading={mergedLoading}
      locale={mergedLocale}
      ref={ref}
    />
  );
}) as TableType;

/**
 * This is a shorthand for the `Table.sortDirections` prop.
 *
 * In most cases, cancelling sorting is pointless, so we use these values to
 * prevent the sorter from reverting to its default status.
 *
 * For more information, see
 * {@link https://ant.design/components/table#components-table-demo-head}.
 */
const sortDirections = {
  ascendFirst: ["ascend", "descend", "ascend"],
  descendFirst: ["descend", "ascend", "descend"],
} satisfies Record<string, NonNullable<AntTableProps<any>["sortDirections"]>>;

namespace exports {
  export type UseTableSearchOptions<
    TRecordType extends object = object,
    TColumns extends ColumnsType<TRecordType> = ColumnsType<TRecordType>,
  > = {
    /**
     * The data source for the table.
     */
    dataSource: Array<TRecordType>;
    /**
     * The columns of the table.
     */
    columns: TColumns;
    /**
     * The search query.
     *
     * If this is empty, the search functionality will be disabled.
     *
     * If this is not provided, it will use a local state to manage the search
     * query.
     */
    search?: string;
    /**
     * The callback function that is called when the search query changes.
     */
    onSearchChange?: (search: string) => void;
    /**
     * The options for the Fuse.js instance.
     *
     * You might want to set the `keys` option to the keys of the `dataSource`.
     */
    fuseOptions: IFuseOptions<TRecordType>;
    /**
     * The props for the search input.
     */
    searchInputProps?: Omit<ComponentProps<typeof Input>, "value" | "onChange">;
    /**
     * The `onFilter` function can be overridden. If the function returns a
     * boolean value, filtering will be based on that result. If it returns
     * `undefined`, filtering will use the search result. This allows for
     * customizing whether a record should be filtered based on the search. For
     * example, to filter only the root records, you can set `onFilter` to:
     * `(record) => isParent(record) ? undefined : true`.
     */
    onFilter?: (record: TRecordType) => boolean | undefined;
  };
  export type ColumnsType<TRecordType extends object = object> =
    InternalColumnsType<TRecordType>;
  export type TableLocale = InternalTableLocale;
}

const exports = Object.assign(Table, {
  defaultLocale,
  sortDirections,
  useTableSearch,
  ExpandIcon,
  ExpandLoading,
  ExpandError,
});

export { exports as Table };
export type { TableProps };
