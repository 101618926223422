import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { CollapseProps } from "antd";
import { Collapse as AntdCollapse } from "antd";
import type { ElementRef } from "react";

import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  collapse: css({
    background: "none",
    "& > .ant-collapse-item > .ant-collapse-header": {
      color: theme.colors.neutral009,
      fontWeight: 500,
    },
    ".ant-collapse-header": {
      padding: "8px 16px",
    },
    "& > .ant-collapse-item": {
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.neutral003,
    },
    "& .anticon.anticon-right.ant-collapse-arrow": {
      marginTop: -6,
      color: theme.colors.neutral005,
      transform: "rotate(-90deg)",
    },
    "& .ant-collapse-item-active .anticon.anticon-right.ant-collapse-arrow": {
      transform: "rotate(0deg)",
    },
  }),
});

const defaultProps = {
  bordered: false,
  expandIconPosition: "end",
} satisfies Partial<Api.Props>;

declare namespace Api {
  export type Ref = ElementRef<typeof AntdCollapse>;
  export type Props = CollapseProps;
}

const Collapse = forwardRef<Api.Ref, Api.Props>(function Collapse(props, ref) {
  return (
    <AntdCollapse
      {...defaultProps}
      {...props}
      ref={ref}
      css={styles.collapse}
    />
  );
});

assignDisplayName(Collapse, "Collapse");

const Api = Object.assign(Collapse, {
  Panel: AntdCollapse.Panel,
});

export { Api as Collapse };
