import { css } from "@emotion/react";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ResultList } from "@/components/ResultList";
import type {
  Task,
  TaskState,
} from "@/routes/Settings/Users/People/List/BatchInviteUsersModal/Loader";
import { defineStyles } from "@/shared/emotion";
import { fakeT } from "@/shared/g11n/fakeT";

const styles = defineStyles({
  resultList: css({
    margin: "12px 16px",
  }),
});

type TaskByState = {
  key: TaskState;
  i18nKey: string;
  color: keyof typeof ResultList.Item.colorStyles;
  count: number;
  emails: string[];
};

const t = fakeT;
const INIT_TASKS_BY_STATE: Record<TaskState, TaskByState> = {
  success: {
    key: "success",
    i18nKey: t("page.settings.users.invite.batch.result.success.title"),
    color: "success",
    count: 0,
    emails: [],
  },
  exist: {
    key: "exist",
    i18nKey: t("page.settings.users.invite.batch.result.existing.title"),
    color: "warning",
    count: 0,
    emails: [],
  },
  error: {
    key: "error",
    i18nKey: t("page.settings.users.invite.batch.result.failed.title"),
    color: "error",
    count: 0,
    emails: [],
  },
  pending: {
    key: "pending",
    i18nKey: "",
    color: "info",
    count: 0,
    emails: [],
  },
};

interface ResultProps {
  tasks: Task[];
}

const Result: FC<ResultProps> = ({ tasks }) => {
  const { t } = useTranslation();

  const tasksByState = useMemo(() => {
    const result = tasks.reduce(
      (result, task) => {
        const currentStateResult = result[task.state];
        result[task.state] = {
          ...currentStateResult,
          count: currentStateResult.count + 1,
          emails: [...currentStateResult.emails, task.email],
        };
        return result;
      },
      { ...INIT_TASKS_BY_STATE },
    );
    return result;
  }, [tasks]);

  return (
    <div>
      <div>{t("page.settings.users.invite.batch.result.content")}</div>
      <ResultList css={styles.resultList}>
        {Object.values(tasksByState).map((task) => {
          const isHide = task.count === 0 || task.key === "pending";
          if (isHide) {
            return null;
          }
          const isSuccessful = task.color === "success";
          return (
            <ResultList.Item key={task.color} color={task.color}>
              <ResultList.Item.Header>
                {t(task.i18nKey, {
                  count: task.count,
                })}
              </ResultList.Item.Header>
              {isSuccessful ? null : (
                <ResultList.Item.Description>
                  {task.emails.join(", ")}
                </ResultList.Item.Description>
              )}
            </ResultList.Item>
          );
        })}
      </ResultList>
    </div>
  );
};

export { Result };
