import { dataUrlToBlob } from "@zeffiroso/utils/file/dataUrlToBlob";
import { downloadFile } from "@zeffiroso/utils/file/downloadFile";
import { zipFiles } from "@zeffiroso/utils/file/zipFiles";
import {
  type QRCodeSegment,
  type QRCodeToDataURLOptions,
  toDataURL,
} from "qrcode";

namespace downloadzip {
  export interface InputSingleOptions {
    path: string;
    text: string | QRCodeSegment[];
    options?: QRCodeToDataURLOptions;
  }
  export type InputOptions = Array<InputSingleOptions>;
  export type OutputOptions = Omit<
    zipFiles.Options<"blob">["output"],
    // type should always be "blob"
    "type"
  > & {
    fileName: string;
  };
  export interface Options {
    inputs: InputOptions;
    output: OutputOptions;
    signal?: AbortSignal;
  }
}

/**
 * Downloads a zip file containing the QR codes generated from the input data.
 */
async function downloadZip({
  inputs,
  output: { fileName, ...output },
  signal,
}: downloadzip.Options) {
  signal?.throwIfAborted();
  const blobResults = await Promise.allSettled(
    inputs.map((input) =>
      toDataURL(input.text, input.options).then((...args) =>
        dataUrlToBlob(...args, {
          signal,
        }),
      ),
    ),
  );
  signal?.throwIfAborted();
  const errors: Array<unknown> = blobResults
    .filter((result) => result.status === "rejected")
    .map((result) => result.reason as unknown);
  const successBlobs: Array<Blob> = blobResults
    .filter((result) => result.status === "fulfilled")
    .map((result) => result.value);
  if (successBlobs.length === 0 && errors.length > 0) {
    throw new Error(`No data urls were generated`, {
      ...(errors.length === 0
        ? null
        : {
            cause: errors,
          }),
    });
  }
  const zipOptions = {
    input: blobResults.flatMap((result, index) => {
      if (result.status === "rejected") return [];
      const path = inputs[index].path;
      const data = result.value;
      return [
        {
          path,
          data,
        },
      ];
    }),
    output,
  };
  signal?.throwIfAborted();
  const zip = await zipFiles(zipOptions);
  signal?.throwIfAborted();
  downloadFile({
    fileName,
    obj: zip,
  });
  if (errors.length > 0) {
    throw new Error(`Some data urls were not generated`, {
      cause: errors,
    });
  }
}

export { downloadZip };
