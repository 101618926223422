import type { CantataTypes } from "@/cantata/types";

type FileType = CantataTypes["QuickTemplateDetail"]["messages"][number]["type"];

const supportMessageTypesMap: Record<
  CantataTypes["ChannelType"],
  Array<FileType>
> = {
  fb: ["text"],
  ig: ["text"],
  line: ["text", "image", "video", "file"],
  wccs: ["text", "image", "video", "file"],
};

function checkSupportMessageType(
  channelType: CantataTypes["ChannelType"],
  messageType: FileType,
) {
  return supportMessageTypesMap[channelType].includes(messageType);
}

export { checkSupportMessageType };
