import { number } from "@chatbotgang/etude/pitch-shifter/number";
import { memo } from "@zeffiroso/utils/react/memo";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { useMatchCurrentPath } from "@/router/utils/matchPath";

type PageInfoContextValue =
  | {
      autoAssignmentRuleId: number;
      isCreating: false;
      isView: false;
      isUpdating: true;
    }
  | {
      autoAssignmentRuleId: number;
      isCreating: false;
      isView: true;
      isUpdating: false;
    }
  | {
      autoAssignmentRuleId: undefined;
      isCreating: true;
      isView: false;
      isUpdating: false;
    };

const PageInfoContext = createContext<PageInfoContextValue | undefined>(
  undefined,
);

const PageInfoProvider = memo(function PageInfoProvider({
  children,
}: {
  children: ReactNode;
}) {
  const params = useParams();
  const autoAssignmentRuleId: number | undefined = (() => {
    const param = params["ruleId"];
    return !param ? undefined : number()(param);
  })();
  const matchCurrentPath = useMatchCurrentPath();
  const isView = useMemo(
    () =>
      Boolean(
        matchCurrentPath("/settings/assignment/routing-rules/view/:ruleId"),
      ),
    [matchCurrentPath],
  );

  const pageInfoContextValue = useMemo<PageInfoContextValue>(() => {
    return autoAssignmentRuleId === undefined
      ? {
          autoAssignmentRuleId,
          isCreating: true,
          isView: false,
          isUpdating: false,
        }
      : isView
        ? {
            autoAssignmentRuleId,
            isCreating: false,
            isView: true,
            isUpdating: false,
          }
        : {
            autoAssignmentRuleId,
            isCreating: false,
            isView: false,
            isUpdating: true,
          };
  }, [autoAssignmentRuleId, isView]);
  return (
    <PageInfoContext.Provider
      key={autoAssignmentRuleId}
      value={pageInfoContextValue}
    >
      {children}
    </PageInfoContext.Provider>
  );
});

function usePageInfo() {
  const pageInfoContextValue = useContext(PageInfoContext);
  if (pageInfoContextValue === undefined)
    throw new Error("usePageInfo must be used within PageInfoProvider");

  return pageInfoContextValue;
}

export { PageInfoProvider, usePageInfo };
