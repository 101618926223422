import { defineAffectedApis } from "@/cantata";

defineAffectedApis({
  channel: {
    assignmentQrcode: true,
  },
  team: {
    assignmentQrcode: true,
  },
  user: {
    assignmentQrcode: true,
    /**
     * Assignment rules will be affected when a user is changed:
     *
     * - User deleted: The rule will be disabled, and the assignee will be cleared.
     * - User suspended: The rule will be disabled.
     *
     * Asana: [[FE] User list / create / delete](https://app.asana.com/0/0/1208551211161867/1208773246734056/f)
     */
    autoAssignment: true,
  },
});
