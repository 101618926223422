import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";
import { useMemo } from "react";

import { MotifIcon } from "@/components/MotifIcon";
import { messageUtil } from "@/routes/Chat/ui/ChatPanel/History/Messages/messageUtil";
import { defineStyles } from "@/shared/emotion";

type Props = ComponentProps<"div"> & {
  messageType?: "caution";
};

const cssBase = css({
  width: "fit-content",
  minWidth: "1.5em",
  maxWidth: "280px",
  minHeight: "32px",
  boxSizing: "border-box",
  padding: "8px 12px",
  borderRadius: theme.shape.borderRadiusLarge,
  fontSize: "0.875rem",
  lineHeight: 1.42,
  whiteSpace: "pre-wrap",
  a: {
    color: "inherit",
    textDecoration: "underline",
    "&:hover": {
      color: "inherit",
    },
  },
});

const styles = defineStyles({
  user: css([
    cssBase,
    {
      backgroundColor: theme.colors.blue005,
      color: theme.colors.neutral001,
    },
  ]),
  member: css([
    cssBase,
    {
      backgroundColor: theme.colors.neutral001,
      color: theme.colors.neutral010,
    },
  ]),
});

const cautionMessageStyles = defineStyles({
  user: css([
    styles.user,
    {
      color: theme.colors.blue003,
    },
  ]),
  member: css([
    styles.member,
    {
      color: theme.colors.neutral007,
    },
  ]),
  container: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "4px",
  }),
});

const TextBubble = forwardRef<ElementRef<"div">, Props>(function TextBubble(
  { messageType, ...props },
  ref,
) {
  const message = messageUtil.useMessage();
  const localIsUserMessage = messageUtil.isUserMessage(message);
  const style =
    messageType === "caution"
      ? localIsUserMessage
        ? cautionMessageStyles.user
        : cautionMessageStyles.member
      : localIsUserMessage
        ? styles.user
        : styles.member;
  const defaultProps = useMemo(
    () => ({
      children: message.text,
    }),
    [message.text],
  );
  const mergedProps = useMemo<ComponentProps<"div">>(() => {
    const mergedChildren = "children" in props ? props.children : message.text;
    const children =
      messageType === "caution" ? (
        <div css={cautionMessageStyles.container}>
          <div>
            <MotifIcon un-i-motif="circle_caution" />
          </div>
          <div>{mergedChildren}</div>
        </div>
      ) : (
        mergedChildren
      );
    return {
      ...defaultProps,
      ...props,
      children,
    };
  }, [props, message.text, messageType, defaultProps]);
  return <div css={style} {...mergedProps} ref={ref} />;
});

export { TextBubble };
