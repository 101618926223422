import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { QueryOptions } from "@zeffiroso/utils/zodios/types";
import { Skeleton } from "antd";
import { type FC, useCallback } from "react";

import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata, type cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { RoleName } from "@/resources/role/RoleName";

namespace RoleNameById {
  export interface Options {
    orgId: CantataTypes["Org"]["id"];
    roleId: CantataTypes["Role"]["id"];
  }
  export interface Props extends Options {}
}

type QueryFnData = Awaited<
  ReturnType<(typeof cantataClient)["role"]["listRoles"]>
>;

/**
 * Get the role name by the role ID.
 */
function useRoleNameByIdQuery(
  options: RoleNameById.Options,
  queryOptions?: Omit<QueryOptions<QueryFnData, string>, "select">,
) {
  const getRoleName = RoleName.useGetRoleName();
  const querySelect: (data: QueryFnData) => string = useCallback(
    (data) => {
      const role = data.roles.find((role) => role.id === options.roleId);
      return !role ? EMPTY_STRING_PLACEHOLDER : getRoleName(role);
    },
    [getRoleName, options.roleId],
  );
  const query = cantata.role.useListRoles(
    {
      params: {
        orgId: options.orgId,
      },
    },
    {
      ...queryOptions,
      select: querySelect,
    },
  );
  return query;
}

const RoleNameByIdInternal: FC<RoleNameById.Props> = ({ orgId, roleId }) => {
  const query = useRoleNameByIdQuery({
    orgId,
    roleId,
  });

  if (query.isLoading) return <Skeleton.Input size="small" />;

  if (query.isError) {
    return (
      <Alert
        type="error"
        message={inspectMessage`query error: ${query.error}`}
      />
    );
  }

  return query.data;
};

/**
 * Display the role name by the role ID.
 */
const RoleNameById = Object.assign(RoleNameByIdInternal, {
  useQuery: useRoleNameByIdQuery,
});

export { RoleNameById };
