import { css } from "@emotion/react";
import type { FC } from "react";

import { Flex } from "@/components/Box";
import { FormItem } from "@/components/Form";
import { ConditionField } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/fields/ConditionsField/ConditionField";
import type { FormValues } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/type";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  ConditionsField: css({
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "inherit",
  }),
  Conditions: css({
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "inherit",
    "& .ant-form-item": {
      marginBottom: 0,
    },
  }),
});

const Conditions: FC<{
  value?: FormValues["conditions"];
  onChange?: (value: FormValues["conditions"]) => void;
}> = ({ value, onChange }) => {
  if (!value || !onChange) return null;

  return (
    <Flex css={styles.Conditions}>
      {value.map((condition, index) => (
        <ConditionField
          key={condition.key}
          conditions={value}
          setConditions={onChange}
          index={index}
        />
      ))}
    </Flex>
  );
};

const ConditionsField = () => {
  return (
    <Flex css={styles.ConditionsField}>
      <FormItem name="conditions" noStyle>
        <Conditions />
      </FormItem>
    </Flex>
  );
};

export { ConditionsField };
