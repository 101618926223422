import { memo } from "@zeffiroso/utils/react/memo";
import type { ComponentType, ReactNode } from "react";
import { useMemo } from "react";

type ComposeProviderProps = {
  providers: ComponentType<{ children: ReactNode }>[];
  children: ReactNode;
};

/**
 * Compose multiple providers into one. Props are not supported.
 *
 * @example
 * ```tsx
 * function MyApp() {
 *   return (
 *     <ComposeProvider providers={[Provider1, Provider2]}>
 *       <App />
 *     </ComposeProvider>
 *   );
 * }
 * // Is equivalent to:
 * function MyApp() {
 *   return (
 *     <Provider1>
 *       <Provider2>
 *         <App />
 *       </Provider2>
 *     </Provider1>
 *   );
 * }
 * ```
 */
const ComposeProvider = memo(function ComposeProvider({
  providers,
  children,
}: ComposeProviderProps) {
  const reversedProviders = useMemo(
    () => [...providers].reverse(),
    [providers],
  );
  const mergedChildren = useMemo(() => {
    let mergedChildren = children;
    reversedProviders.forEach((Provider) => {
      mergedChildren = <Provider>{mergedChildren}</Provider>;
    });
    return mergedChildren;
  }, [children, reversedProviders]);
  return mergedChildren;
});

export { ComposeProvider };
export type { ComposeProviderProps };
