import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { Trans } from "@/app/i18n/Trans";
import { PermissionChecker } from "@/components/PermissionChecker";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { AccountManagerField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/AccountManagerField";
import { MemberLevelField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/MemberLevelField";
import { FormProvider } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/FormProvider";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  Default: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  label: css({
    fontSize: "0.75rem",
    color: theme.colors.neutral007,
  }),
  field: css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
});

const Default: FC = () => {
  const profile = memberQueriesContext.useMember();
  return (
    <div css={styles.Default}>
      <div css={styles.field}>
        <label css={styles.label}>
          <Trans i18nKey="chat.memberProfilePanel.membership.member.memberLevel" />
        </label>
        <PermissionChecker
          to="editMemberProfile"
          fallback={<span>{profile.memberLevel}</span>}
        >
          <FormProvider fieldName="memberLevel">
            <MemberLevelField />
          </FormProvider>
        </PermissionChecker>
      </div>
      <div css={styles.field}>
        <label css={styles.label}>
          <Trans i18nKey="chat.customer.accountManager" />
        </label>
        <PermissionChecker
          to="editMemberProfile"
          fallback={<span>{profile.accountManager}</span>}
        >
          <FormProvider fieldName="accountManager">
            <AccountManagerField />
          </FormProvider>
        </PermissionChecker>
      </div>
    </div>
  );
};

export { Default };
