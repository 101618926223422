import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { RouteTabs } from "@/components/Tabs/RouteTabs";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { defineChildRouteObject } from "@/router/utils/defineChildRouteObject";
import { EditUser } from "@/routes/Settings/Users/People/EditUser";
import { Users } from "@/routes/Settings/Users/People/List/UsersList";
import { Roles } from "@/routes/Settings/Users/Roles";

const List: FC = () => {
  const { t } = useTranslation();
  const items = useMemo<ComponentProps<typeof RouteTabs>["items"]>(
    () => [
      {
        key: "/settings/users",
        label: t("page.users.tab.users.title"),
        children: <Outlet />,
      },
      {
        key: "/settings/users/roles",
        label: t("organization.roleSetting"),
        children: <Roles />,
      },
    ],
    [t],
  );
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header>{t("organization.peopleSetting")}</MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          <MainLayout.Section>
            <RouteTabs items={items} />
          </MainLayout.Section>
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
};

const usersRouteObject = defineChildRouteObject({
  children: [
    {
      path: "edit/:userId",
      element: <EditUser />,
    },
    {
      path: "",
      element: <List />,
      children: [
        {
          index: true,
          element: <Users />,
        },
        {
          path: "roles",
          element: <Roles />,
        },
      ],
    },
  ],
});

export { usersRouteObject };
