import { memo } from "@zeffiroso/utils/react/memo";
import { useEffect } from "react";

import { createStack } from "./createStack";

/**
 * Create a hook or component that can only be used once.
 *
 * @example
 *
 * ```ts
 * const { useOnlyOnce, OnlyOnce } = createUseOnlyOnce({
 *  onError: console.error,
 * });
 *
 * function MyComponent() { // This will always call the error handler.
 *   useOnlyOnce();
 *   return <OnlyOnce />;
 * }
 * ```
 */
function createUseOnlyOnce({ onError }: { onError: (error: Error) => void }) {
  const { useStore, useItem } = createStack();
  function useOnlyOnce() {
    useItem();
    useEffect(() => {
      const unsubscribe = useStore.subscribe((state) => {
        if (state.stack.length <= 1) return;
        const error = new Error(
          "This hook can only have one instance at a time.",
        );
        if (import.meta.env.DEV) console.error(error);
        onError(error);
      });

      return unsubscribe;
    }, []);
  }
  const OnlyOnce = memo(function OnlyOnce() {
    useOnlyOnce();
    return null;
  });
  return {
    useOnlyOnce,
    OnlyOnce,
  };
}

export { createUseOnlyOnce };
