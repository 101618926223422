import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { useMemo } from "react";

import { useToLocaleProcessingState } from "@/resources/member/useToLocaleProcessingState";
import { useConversation } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/Context";

const cssState = css`
  display: flex;
  align-items: center;
  color: ${theme.colors.neutral009};
  font-weight: 500;
`;

const State = memo(function State() {
  const conversation = useConversation();
  const toLocaleProcessingState = useToLocaleProcessingState();
  const title = useMemo(
    function computeTitle() {
      return toLocaleProcessingState(conversation.processingState);
    },
    [conversation.processingState, toLocaleProcessingState],
  );
  return <div css={cssState}>{title}</div>;
});

export { State };
