import { CloseOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";

import type { ButtonProps } from "@/components/Button";
import { Button } from "@/components/Button";

export const DelButton = memo(function DelButton({ ...props }: ButtonProps) {
  return (
    <Button
      css={css`
        border: none;
        color: ${theme.colors.neutral006};
        font-size: 12px;
      `}
      ghost
      shape="circle"
      icon={<CloseOutlined />}
      {...props}
    />
  );
});
