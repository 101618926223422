import type { FormInstance } from "antd";
import { HOOK_MARK } from "rc-field-form/es/FieldContext";
import type { InternalFormInstance } from "rc-field-form/es/interface";

function getInternalFormInstance(antForm: FormInstance) {
  const internalForm: InternalFormInstance =
    antForm as unknown as InternalFormInstance;
  return internalForm;
}

function getInternalHooks(antForm: FormInstance) {
  const internalForm = getInternalFormInstance(antForm);
  const internalHooks =
    internalForm.getInternalHooks.bind(internalForm)(HOOK_MARK);
  return internalHooks;
}

export { getInternalHooks };
