import { css } from "@emotion/react";
import type { FC } from "react";

import { Trans } from "@/app/i18n/Trans";
import { PermissionChecker } from "@/components/PermissionChecker";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { UnifyKeyLabel } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/CdpProfile/UnifyKeyLabel";
import { Field } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/Field";
import { AccountManagerField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/AccountManagerField";
import { CompanyField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/CompnayField";
import { CustomIdField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/CustomIdField";
import { MemberLevelField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/MemberLevelField";
import { FormProvider } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/FormProvider";
import { ReadOnly } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/ReadOnly";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  GroupProfile: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
});

const GroupProfile: FC = () => {
  const profile = memberQueriesContext.useMember();

  return (
    <div css={styles.GroupProfile}>
      <Field
        label={
          <UnifyKeyLabel unifyKey="custom_id">
            <Trans i18nKey="chat.customerId" />
          </UnifyKeyLabel>
        }
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={<ReadOnly>{profile.customId}</ReadOnly>}
          >
            <FormProvider fieldName="customId">
              <CustomIdField />
            </FormProvider>
          </PermissionChecker>
        }
      />
      <Field
        label={<Trans i18nKey="chat.customer.company" />}
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={<ReadOnly>{profile.company}</ReadOnly>}
          >
            <FormProvider fieldName="company">
              <CompanyField />
            </FormProvider>
          </PermissionChecker>
        }
      />
      <Field
        label={<Trans i18nKey="chat.customer.memberLevel" />}
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={<ReadOnly>{profile.memberLevel}</ReadOnly>}
          >
            <FormProvider fieldName="memberLevel">
              <MemberLevelField />
            </FormProvider>
          </PermissionChecker>
        }
      />
      <Field
        label={<Trans i18nKey="chat.customer.accountManager" />}
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={<ReadOnly>{profile.accountManager}</ReadOnly>}
          >
            <FormProvider fieldName="accountManager">
              <AccountManagerField />
            </FormProvider>
          </PermissionChecker>
        }
      />
    </div>
  );
};

export { GroupProfile };
