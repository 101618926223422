import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { memo } from "@zeffiroso/utils/react/memo";
import type { ReactNode } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import type { FormProps } from "@/components/Form";
import { Form } from "@/components/Form";
import { useMessage } from "@/internal/message";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";

export type FormValues = {
  [key in keyof Pick<
    CantataTypes["MemberDetail"],
    | "name"
    | "displayEmail"
    | "displayMobile"
    | "gender"
    | "location"
    | "country"
    | "city"
    | "company"
    | "accountManager"
    | "engagementLevel"
    | "customId"
    | "memberLevel"
    | "birthday"
    | "connectId"
  >]?: CantataTypes["MemberDetail"][key] | undefined;
};
export const FormProvider = memo<{
  fieldName: keyof FormValues;
  children: ReactNode;
}>(function FormWrapper({ fieldName, children }) {
  const { id: memberId } = memberQueriesContext.useMember();
  const message = useMessage();
  const [form] = Form.useForm<FormValues>();
  const profile = memberQueriesContext.useMember();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const unifyKeyQuery = cantata.org.useGetUnifyScopeSetting({
    params: { orgId },
  });
  function hasUnifyKey() {
    return unifyKeyQuery.isSuccess && unifyKeyQuery.data.unifyKeys.length > 0;
  }
  const mutation = cantata.member.usePartialUpdate(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      onSuccess: () => {
        message.success(
          hasUnifyKey() ? (
            <message.Container>
              <message.Title>
                <Trans i18nKey="common.updatedSuccessfully" />
              </message.Title>
              <message.Content>
                <Trans i18nKey="chat.profile.cdp.syncing.label" />
              </message.Content>
            </message.Container>
          ) : (
            <Trans i18nKey="common.updatedSuccessfully" />
          ),
        );
        form.resetFields();
      },
    },
  );

  const onFinish = useHandler<FormProps<FormValues>["onFinish"]>(
    function onFinish(values) {
      if (
        values[fieldName] === undefined ||
        values[fieldName] === profile[fieldName] ||
        mutation.isLoading
      )
        return;

      mutation.mutate(values);
    },
  );

  return (
    <Form
      form={form}
      disabled={mutation.isLoading}
      onFinish={onFinish}
      size="small"
    >
      {children}
    </Form>
  );
});
