import { define } from "@chatbotgang/etude/util/define";
import { colors } from "@zeffiroso/theme/colors";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import type { ThemeConfig } from "antd";
import { mapValues } from "lodash-es";
import { useMemo } from "react";
import { capitalize } from "tsafe/capitalize";
import { createWithEqualityFn } from "zustand/traditional";

const screenSizes = define<Array<string>>()([
  "xxs",
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl",
]);
type ScreenSize = (typeof screenSizes)[number];
const screenTypes = define<Array<string>>()([
  "mobile",
  "tabletLandscape",
  "desktop",
]);
type ScreenType = (typeof screenTypes)[number];
/**
 * # Breakpoints
 *
 * | min-width     | xxs     | xs                 | sm                  | md                   | lg                             | xl                    | xxl                |
 * | ------------- | ------- | ------------------ | ------------------- | -------------------- | ------------------------------ | --------------------- | ------------------ |
 * | bootstrap     |         | 0 ~ 767            | 768 ~ 991           | 992 ~ 1200           | 1200 ~ ∞                       |                       |                    |
 * | mui           |         | 0 ~ 599            | 600 ~ 899           | 900 ~ 1199           | 1200 ~ 1535                    | 1536 ~ ∞              |                    |
 * | material v2   |         | 0 ~ 599 (phone)    | 600 ~ 1239 (tablet) | 1240 ~ 1439 (laptop) | 1440 (desktop) ~ ∞             |                       |                    |
 * | antd          | 0 ~ 479 | 480 ~ 575          | 576 ~ 767           | 768 ~ 991            | 992 ~ 1199                     | 1200 ~ 1599           | 1600 ~ ∞           |
 * | crescendo lab | 0 ~ 479 | 480 ~ 575 (mobile) | 576 ~ 767 (mobile)  | 768 ~ 1023 (mobile)  | 1024 ~ 1279 (tablet landscape) | 1280 ~ 1599 (desktop) | 1600 ~ ∞ (desktop) |
 *
 * - Figma: <https://www.figma.com/file/8E4tpxtJ0zS5ZMsPM3X8gy/CAAC_Information-Architecture?type=design&node-id=1249%3A24141&mode=design&t=481cRK69RzTwaywK-1>
 * - Slack: [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1696230049222709?thread_ts=1696226408.033509&cid=C02R6ETJMEY)
 */
const breakpoints = define<Record<ScreenSize, number>>()({
  xxs: 0,
  xs: 480,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1600,
});

const screenTypeBreakpoints = define<Record<ScreenType, number>>()({
  mobile: breakpoints.xxs,
  tabletLandscape: breakpoints.lg,
  desktop: breakpoints.xl,
});

type Token = Required<NonNullable<ThemeConfig["token"]>>;
type TokenKey = keyof Token;
type ScreenTokenKey<T extends TokenKey> = T extends `screen${infer S}`
  ? `screen${S}`
  : never;

const antdScreens: {
  [K in ScreenTokenKey<TokenKey>]: number;
} = {
  screenXS: breakpoints.xs,
  screenXSMin: breakpoints.xs,
  screenXSMax: breakpoints.sm - 1,
  screenSM: breakpoints.sm,
  screenSMMin: breakpoints.sm,
  screenSMMax: breakpoints.md - 1,
  screenMD: breakpoints.md,
  screenMDMin: breakpoints.md,
  screenMDMax: breakpoints.lg - 1,
  screenLG: breakpoints.lg,
  screenLGMin: breakpoints.lg,
  screenLGMax: breakpoints.xl - 1,
  screenXL: breakpoints.xl,
  screenXLMin: breakpoints.xl,
  screenXLMax: breakpoints.xxl - 1,
  screenXXL: breakpoints.xxl,
  screenXXLMin: breakpoints.xxl,
};

const theme = {
  colors,
  shape: {
    slightBorderRadius: "2px",
    borderRadius: "4px",
    borderRadiusLarge: "8px",
    borderRadiusRound: "50%",
    mediaFieldSize: "320px",
    fileFieldHeight: "163px",
  },
  zIndices: {
    cover: 200,
    ultimate: 2147483647, // This is the highest possible z-index value for some browsers
  },
} as const;

const antdTheme: ThemeConfig = {
  token: {
    fontFamily: "var(--font-family)",
    red: theme.colors.red006,
    yellow: theme.colors.yellow006,
    green: theme.colors.green006,
    blue: theme.colors.blue006,
    purple: theme.colors.purple006,
    colorError: theme.colors.error,
    colorInfo: theme.colors.info,
    colorLink: theme.colors.primary,
    colorPrimary: theme.colors.primary,
    colorSuccess: theme.colors.success,
    colorText: theme.colors.colorText,
    colorWarning: theme.colors.warning,
    colorWhite: theme.colors.white,
    colorBgContainerDisabled: theme.colors.backgroundDisabled,
    colorTextPlaceholder: theme.colors.neutral006,
    colorTextDescription: theme.colors.neutral007,
    red1: theme.colors.red001,
    red2: theme.colors.red002,
    red3: theme.colors.red003,
    red4: theme.colors.red004,
    red5: theme.colors.red005,
    red6: theme.colors.red006,
    red7: theme.colors.red007,
    red8: theme.colors.red008,
    red9: theme.colors.red009,
    red10: theme.colors.red010,
    yellow1: theme.colors.yellow001,
    yellow2: theme.colors.yellow002,
    yellow3: theme.colors.yellow003,
    yellow4: theme.colors.yellow004,
    yellow5: theme.colors.yellow005,
    yellow6: theme.colors.yellow006,
    yellow7: theme.colors.yellow007,
    yellow8: theme.colors.yellow008,
    yellow9: theme.colors.yellow009,
    yellow10: theme.colors.yellow010,
    green1: theme.colors.green001,
    green2: theme.colors.green002,
    green3: theme.colors.green003,
    green4: theme.colors.green004,
    green5: theme.colors.green005,
    green6: theme.colors.green006,
    green7: theme.colors.green007,
    green8: theme.colors.green008,
    green9: theme.colors.green009,
    green10: theme.colors.green010,
    purple1: theme.colors.purple001,
    purple2: theme.colors.purple002,
    purple3: theme.colors.purple003,
    purple4: theme.colors.purple004,
    purple5: theme.colors.purple005,
    purple6: theme.colors.purple006,
    purple7: theme.colors.purple007,
    purple8: theme.colors.purple008,
    purple9: theme.colors.purple009,
    purple10: theme.colors.purple010,
    blue1: theme.colors.blue001,
    blue2: theme.colors.blue002,
    blue3: theme.colors.blue003,
    blue4: theme.colors.blue004,
    blue5: theme.colors.blue005,
    blue6: theme.colors.blue006,
    blue7: theme.colors.blue007,
    blue8: theme.colors.blue008,
    blue9: theme.colors.blue009,
    blue10: theme.colors.blue010,
    borderRadius: Number.parseInt(theme.shape.borderRadius, 10),
    ...antdScreens,
  },
  components: {
    Tabs: {
      itemColor: theme.colors.neutral007,
    },
    Table: {
      headerColor: theme.colors.neutral007,
      headerBg: theme.colors.white,
      bodySortBg: theme.colors.white,
      fixedHeaderSortActiveBg: theme.colors.white,
      headerSortActiveBg: theme.colors.white,
    },
    Breadcrumb: {
      linkColor: theme.colors.neutral010,
      lastItemColor: theme.colors.neutral008,
      separatorColor: theme.colors.neutral004,
      separatorMargin: 12,
    },
    Tooltip: {
      colorBgSpotlight: theme.colors.white,
      colorTextLightSolid: theme.colors.neutral009,
    },
  },
};

const breakpointApi = (function createUseBreakpointApi() {
  /**
   * @see {@link breakpoints}
   */
  type BreakpointStoreValue = {
    isXxs: boolean;
    isXs: boolean;
    isSm: boolean;
    isMd: boolean;
    isLg: boolean;
    isXl: boolean;
    isXxl: boolean;
    isMobile: boolean;
    isTabletLandscape: boolean;
    isDesktop: boolean;
  };

  const screenSizeMediaQueryStringMap = define<Record<ScreenSize, string>>()({
    xxs: `(width < ${breakpoints.xs}px)`,
    xs: `(${breakpoints.xs}px <= width < ${breakpoints.sm}px)`,
    sm: `(${breakpoints.sm}px <= width < ${breakpoints.md}px)`,
    md: `(${breakpoints.md}px <= width < ${breakpoints.lg}px)`,
    lg: `(${breakpoints.lg}px <= width < ${breakpoints.xl}px)`,
    xl: `(${breakpoints.xl}px <= width < ${breakpoints.xxl}px)`,
    xxl: `(width >= ${breakpoints.xxl}px)`,
  });
  const screenSizeMediaQueryListMap = mapValues(
    screenSizeMediaQueryStringMap,
    (mediaQueryString) => window.matchMedia(mediaQueryString),
  );
  const screenTypeMediaQueryStringMap = define<Record<ScreenType, string>>()({
    mobile: `(width < ${screenTypeBreakpoints.tabletLandscape}px)`,
    tabletLandscape: `(${screenTypeBreakpoints.tabletLandscape}px <= width < ${screenTypeBreakpoints.desktop}px)`,
    desktop: `(width >= ${screenTypeBreakpoints.desktop}px)`,
  });
  const screenTypeMediaQueryListMap = mapValues(
    screenTypeMediaQueryStringMap,
    (mediaQueryString) => window.matchMedia(mediaQueryString),
  );

  type ScreenSizeBreakpointStoreValue = Pick<
    BreakpointStoreValue,
    Extract<keyof BreakpointStoreValue, `is${Capitalize<ScreenSize>}`>
  >;
  type ScreenTypeBreakpointStoreValue = Pick<
    BreakpointStoreValue,
    Extract<keyof BreakpointStoreValue, `is${Capitalize<ScreenType>}`>
  >;

  function getScreenSizeMediaQueryResults(): ScreenSizeBreakpointStoreValue {
    return {
      isXxs: screenSizeMediaQueryListMap.xxs.matches,
      isXs: screenSizeMediaQueryListMap.xs.matches,
      isSm: screenSizeMediaQueryListMap.sm.matches,
      isMd: screenSizeMediaQueryListMap.md.matches,
      isLg: screenSizeMediaQueryListMap.lg.matches,
      isXl: screenSizeMediaQueryListMap.xl.matches,
      isXxl: screenSizeMediaQueryListMap.xxl.matches,
    };
  }
  function getScreenTypeMediaQueryResults(): ScreenTypeBreakpointStoreValue {
    return {
      isMobile: screenTypeMediaQueryListMap.mobile.matches,
      isTabletLandscape: screenTypeMediaQueryListMap.tabletLandscape.matches,
      isDesktop: screenTypeMediaQueryListMap.desktop.matches,
    };
  }

  const syncScreenSizeMediaQueryResults: Parameters<
    typeof screenSizeMediaQueryListMap.xxs.addEventListener<"change">
  >[1] = function syncMediaQueryResults(e) {
    /**
     * Since there will be only one media query list that matches the current
     * screen width, we can return early to avoid unnecessary re-renders.
     */
    if (!e.matches) return;
    useBreakpointStore.setState(getScreenSizeMediaQueryResults());
  };

  const syncScreenTypeMediaQueryResults: Parameters<
    typeof screenTypeMediaQueryListMap.mobile.addEventListener<"change">
  >[1] = function syncMediaQueryResults(e) {
    /**
     * Since there will be only one media query list that matches the current
     * screen width, we can return early to avoid unnecessary re-renders.
     */
    if (!e.matches) return;
    useBreakpointStore.setState(getScreenTypeMediaQueryResults());
  };

  Object.values(screenSizeMediaQueryListMap).forEach((mediaQueryList) =>
    mediaQueryList.addEventListener("change", syncScreenSizeMediaQueryResults),
  );

  Object.values(screenTypeMediaQueryListMap).forEach((mediaQueryList) =>
    mediaQueryList.addEventListener("change", syncScreenTypeMediaQueryResults),
  );

  const useBreakpointStore = createWithEqualityFn<BreakpointStoreValue>()(
    () => ({
      ...getScreenSizeMediaQueryResults(),
      ...getScreenTypeMediaQueryResults(),
    }),
    shallow,
  );
  function useBreakpoint() {
    return useBreakpointStore();
  }
  function useGt(screen: ScreenType | ScreenSize) {
    const breakpoint = useBreakpoint();
    const gt = useMemo(
      function gt() {
        const screenSizeIndex = screenSizes.findIndex((s) => s === screen);
        if (screenSizeIndex > -1) {
          const sizesToMatch = screenSizes.slice(screenSizeIndex + 1);
          return sizesToMatch.some(
            (size) => breakpoint[`is${capitalize(size)}`],
          );
        }
        const screenTypeIndex = screenTypes.findIndex((s) => s === screen);
        const devicesToMatch = screenTypes.slice(screenTypeIndex + 1);
        return devicesToMatch.some(
          (device) => breakpoint[`is${capitalize(device)}`],
        );
      },
      [breakpoint, screen],
    );
    return gt;
  }
  function useLt(screen: ScreenType | ScreenSize) {
    const breakpoint = useBreakpoint();
    const lt = useMemo(
      function lt() {
        const screenSizeIndex = screenSizes.findIndex((s) => s === screen);
        if (screenSizeIndex > -1) {
          const sizesToMatch = screenSizes.slice(0, screenSizeIndex);
          return sizesToMatch.some(
            (size) => breakpoint[`is${capitalize(size)}`],
          );
        }
        const screenTypeIndex = screenTypes.findIndex((s) => s === screen);
        const devicesToMatch = screenTypes.slice(0, screenTypeIndex);
        return devicesToMatch.some(
          (device) => breakpoint[`is${capitalize(device)}`],
        );
      },
      [breakpoint, screen],
    );
    return lt;
  }
  function useGte(screen: ScreenType | ScreenSize) {
    const breakpoint = useBreakpoint();
    const gte = useMemo(
      function gte() {
        const screenSizeIndex = screenSizes.findIndex((s) => s === screen);
        if (screenSizeIndex > -1) {
          const sizesToMatch = screenSizes.slice(screenSizeIndex);
          return sizesToMatch.some(
            (size) => breakpoint[`is${capitalize(size)}`],
          );
        }
        const screenTypeIndex = screenTypes.findIndex((s) => s === screen);
        const devicesToMatch = screenTypes.slice(screenTypeIndex);
        return devicesToMatch.some(
          (device) => breakpoint[`is${capitalize(device)}`],
        );
      },
      [breakpoint, screen],
    );
    return gte;
  }
  function useLte(screen: ScreenType | ScreenSize) {
    const breakpoint = useBreakpoint();
    const lte = useMemo(
      function lte() {
        const screenSizeIndex = screenSizes.findIndex((s) => s === screen);
        if (screenSizeIndex > -1) {
          const sizesToMatch = screenSizes.slice(0, screenSizeIndex + 1);
          return sizesToMatch.some(
            (size) => breakpoint[`is${capitalize(size)}`],
          );
        }
        const screenTypeIndex = screenTypes.findIndex((s) => s === screen);
        const devicesToMatch = screenTypes.slice(0, screenTypeIndex + 1);
        return devicesToMatch.some(
          (device) => breakpoint[`is${capitalize(device)}`],
        );
      },
      [breakpoint, screen],
    );
    return lte;
  }

  const breakpointApi = {
    useBreakpoint,
    useGt,
    useGte,
    useLt,
    useLte,
    screenSizeMediaQueryListMap,
    screenSizeMediaQueryStringMap,
  };

  return breakpointApi;
})();

type Theme = typeof theme;

export {
  antdTheme,
  breakpointApi,
  breakpoints,
  screenSizes,
  screenTypeBreakpoints,
  screenTypes,
  theme,
};
export type { ScreenSize, ScreenType, Theme };
