import { StyleProvider } from "@ant-design/cssinjs";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import createEmotionCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { antdTheme } from "@zeffiroso/theme";
import type { ComposeProviderProps } from "@zeffiroso/utils/react/ComposeProvider";
import { ComposeProvider } from "@zeffiroso/utils/react/ComposeProvider";
import { memo } from "@zeffiroso/utils/react/memo";
import type { AnyFunction } from "@zeffiroso/utils/type/function/AnyFunction";
import { ConfigProvider } from "antd";
import type { ComponentPropsWithRef, ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";

/**
 * Make sure the antd style is injected in the body instead of the head.
 */
const AntdStyleProvider = memo(function AntdStyleProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [injectElement, setInjectElement] = useState<HTMLDivElement | null>(
    null,
  );
  const ref = useHandler<
    Extract<ComponentPropsWithRef<"div">["ref"], AnyFunction>
  >((el) => {
    setInjectElement(el);
  });
  useEffect(function removeHeadRcStyle() {
    /**
     * If rc style is injected in the head, it might be caused by an extension
     * based on antd v5. We need to remove it to prevent style conflicts.
     */
    function removeHeadRcStyle() {
      const headRcStyle = document.querySelectorAll(
        "head > style[data-rc-order]",
      );
      headRcStyle.forEach((style) => {
        style.remove();
      });
    }
    removeHeadRcStyle();
    const observer = new MutationObserver(removeHeadRcStyle);
    observer.observe(document.head, {
      childList: true,
      subtree: false,
    });
    return function cleanup() {
      observer.disconnect();
    };
  }, []);
  return (
    <>
      <div
        data-antd-css
        className="zeffiroso-antd-style"
        style={{ display: "none" }}
        ref={ref}
      />
      {!injectElement ? null : (
        <StyleProvider container={injectElement}>{children}</StyleProvider>
      )}
    </>
  );
});

const EmotionCacheProvider = memo(function EmotionCacheProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [injectElement, setInjectElement] = useState<HTMLDivElement | null>(
    null,
  );
  const emotionCache = useMemo(() => {
    if (!injectElement) return null;
    const cache = createEmotionCache({
      key: "css",
      container: injectElement,
      prepend: true,
    });
    /**
     * To force enable the compat mode to prevent the `:first-child` and
     * `:nth-child` error message.
     *
     * @see https://github.com/emotion-js/emotion/issues/1105#issuecomment-1058225197
     */
    cache.compat = true;
    return cache;
  }, [injectElement]);
  return (
    <>
      <div
        data-emotion-css
        style={{ display: "none" }}
        ref={setInjectElement}
      />
      {!emotionCache ? null : (
        <CacheProvider value={emotionCache}>{children}</CacheProvider>
      )}
    </>
  );
});

const AntdThemeConfigProvider = memo(function AntdThemeConfigProvider({
  children,
}: {
  children: ReactNode;
}) {
  return <ConfigProvider theme={antdTheme}>{children}</ConfigProvider>;
});

const providers = [
  AntdStyleProvider,
  EmotionCacheProvider,
  AntdThemeConfigProvider,
] satisfies ComposeProviderProps["providers"];

const ThemeProvider = memo(function ThemeProvider({
  children,
}: {
  children: ReactNode;
}) {
  return <ComposeProvider providers={providers}>{children}</ComposeProvider>;
});

assignDisplayName(ThemeProvider, "ThemeProvider");

export { ThemeProvider };
