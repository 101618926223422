import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef, ReactNode } from "react";

import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  ResultListItemDescription: css({
    fontSize: "0.875rem",
    lineHeight: "normal",
    color: theme.colors.neutral007,
  }),
});

namespace ResultListItemDescription {
  export type Ref = ElementRef<"div">;
  interface OwnProps {
    children: ReactNode;
  }
  export type Props = Overwrite<ComponentProps<"div">, OwnProps>;
}

const ResultListItemDescription = forwardRef<
  ResultListItemDescription.Ref,
  ResultListItemDescription.Props
>(function ResultListItemDescription(props, ref) {
  return <div css={styles.ResultListItemDescription} ref={ref} {...props} />;
});

/**
 * A styled `ResultList.Item.Description` component.
 */
assignDisplayName(ResultListItemDescription, "ResultList.Item.Description");

export { ResultListItemDescription };
