import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import type { ComponentPropsWithRef } from "react";

type PillVariant =
  | "fulfilled"
  | "rejected"
  | "pending"
  | "disabled"
  | "warning"
  | "success";

type PillProps = {
  $variant?: PillVariant;
} & ComponentPropsWithRef<"span">;

const PillVariantStyles: Record<PillVariant, SerializedStyles> = {
  fulfilled: css`
    background-color: ${theme.colors.blue002};
    color: ${theme.colors.blue006};
  `,
  rejected: css`
    background-color: ${theme.colors.red002};
    color: ${theme.colors.red006};
  `,
  pending: css`
    background-color: ${theme.colors.purple001};
    color: ${theme.colors.purple006};
  `,
  success: css`
    background-color: ${theme.colors.green002};
    color: ${theme.colors.green006};
  `,
  warning: css`
    background-color: ${theme.colors.yellow002};
    color: ${theme.colors.neutral010};
  `,
  disabled: css`
    background-color: ${theme.colors.neutral001};
    color: ${theme.colors.neutral006};
  `,
};

const Pill = memo(
  forwardRef<HTMLSpanElement, PillProps>(function Pill(
    { $variant, ...props },
    ref,
  ) {
    return (
      <span
        {...props}
        css={css`
          --height: 1.25rem;

          display: inline-flex;
          min-height: var(--height);
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          padding: 0 8px;
          border-radius: calc(var(--height) / 2);
          ${$variant === undefined ? "" : PillVariantStyles[$variant]}
        `}
        ref={ref}
      />
    );
  }),
);

export { Pill };

export type { PillProps, PillVariant };
