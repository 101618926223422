import { useCallback, useMemo } from "react";

import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import {
  numberFormatPresetMap,
  shouldFormatNumber,
} from "@/components/NumberFormat/utils";
import { useLanguage } from "@/shared/hooks/useLanguage";

import type { NumberFormatOwnProps } from "..";

type UseNumberFormatOptions = Pick<
  NumberFormatOwnProps,
  | "fallback"
  | "numberFormatLocale"
  | "numberFormatPreset"
  | "numberFormatOptions"
>;

/**
 * A locales-aware hook used to format numbers for display
 * This handles common defaults without getting too much in the way of using browser APIs
 * Original instance is also returned in case you need conditional operation based on resolved options
 * Most of the time you should use the `NumberFormat` component since the component handles `font-variant-numeric`
 *
 * ```tsx
 * const { numberFormat: formatCount } = useNumberFormat({
 *   numberFormatPreset: 'count',
 * });
 * const { numberFormat: revenueNumberFormat } = useNumberFormat({
 *   numberFormatPreset: 'revenue',
 * });
 * const { numberFormat: formatPercentFixed } = useNumberFormat({
 *   numberFormatPreset: 'percent',
 *   numberFormatOptions: {
 *     // Optional, any Intl.NumberFormatOptions
 *   },
 * });
 * ```
 */
const useNumberFormat = ({
  numberFormatPreset = "count",
  fallback,
  numberFormatLocale,
  numberFormatOptions,
}: UseNumberFormatOptions): {
  numberFormat: (value: NumberFormatOwnProps["value"]) => string;
  numberFormatInstance: Intl.NumberFormat;
} => {
  const lang = useLanguage();

  const numberFormatInstance = useMemo(() => {
    const options: Intl.NumberFormatOptions = {
      ...numberFormatPresetMap[numberFormatPreset],
      ...numberFormatOptions,
    };

    return new Intl.NumberFormat(numberFormatLocale ?? lang, options);
  }, [lang, numberFormatLocale, numberFormatOptions, numberFormatPreset]);

  const numberFormat = useCallback(
    function numberFormat(value: NumberFormatOwnProps["value"]) {
      if (!shouldFormatNumber(value)) {
        return (
          fallback ??
          `${EMPTY_STRING_PLACEHOLDER}${numberFormatPreset === "percent" ? "%" : ""}`
        );
      }
      return numberFormatInstance.format(value);
    },
    [fallback, numberFormatInstance, numberFormatPreset],
  );

  return {
    numberFormat,
    numberFormatInstance,
  };
};

export { useNumberFormat };
export type { UseNumberFormatOptions };
