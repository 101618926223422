/**
 * This file is used to validate the attachment file type and size.
 *
 * Doc: [CAAC message limitation](https://docs.google.com/spreadsheets/d/1ivkh2k7rLIDm1x_lTsY60btBaig1zLf0euxyyqPrqqM/edit#gid=97920953)
 */

import { define } from "@chatbotgang/etude/util/define";
import { toFileSizeDisplayString } from "@zeffiroso/utils/file/toFileSizeDisplayString";
import { extname } from "pathe";
import type { ReactNode } from "react";

import { Trans } from "@/app/i18n/Trans";
import { MB } from "@/appConstant";
import type { CantataTypes } from "@/cantata/types";

const lineRules = {
  image: {
    maxFileSize: 10 * MB,
    extnames: ["png", "jpg", "jpeg", "gif", "bmp"],
  },
  video: {
    extnames: ["mp4", "mov"],
  },
  audio: {
    // Not ready yet
    // extnames: ["mp3", "m4a", "wav"],
    extnames: define<Array<string>>([]),
  },
  other: {
    maxFileSize: 100 * MB,
    extnames: ["pdf", "xlsx", "xls", "docx", "doc", "pptx", "ppt", "csv"],
  },
} as const;

const fbRules = {
  maxFileSize: 25 * MB,
  image: {
    extnames: ["png", "jpg", "jpeg", "gif", "bmp"],
  },
  video: {
    extnames: ["mp4"],
  },
  audio: {
    // Not ready yet
    // extnames: ["mp3", "m4a", "wav"],
    extnames: define<Array<string>>([]),
  },
  other: {
    extnames: ["pdf", "xlsx", "xls", "docx", "doc", "pptx", "ppt", "csv"],
  },
} as const;

const igRules = {
  maxFileSize: 25 * MB,
  image: {
    maxFileSize: 8 * MB,
    extnames: ["png", "jpg", "jpeg", "gif", "bmp"],
  },
  video: {
    extnames: ["mp4"],
  },
  audio: {
    // Not ready yet
    // extnames: ["acc", "m4a", "wav"],
    extnames: define<Array<string>>([]),
  },
  other: {
    extnames: [],
  },
} as const;

const wccsRules = {
  maxFileSize: 100 * MB,
  image: {
    maxFileSize: 10 * MB,
    extnames: ["png", "jpg", "jpeg"],
  },
  video: {
    extnames: ["mp4", "mov"],
  },
  audio: {
    // Not ready yet
    // extnames: ["acc", "m4a", "wav"],
    extnames: define<Array<string>>([]),
  },
  other: {
    extnames: ["pdf", "xlsx"],
  },
} as const;

type FileInvalidatedError =
  | {
      code: "exceedFileSize";
      maxFileSize: number;
      reactNode: ReactNode;
    }
  | {
      code: "unsupportedFileType";
      reactNode: ReactNode;
    };

const unSupportedFileTypeError: FileInvalidatedError = {
  code: "unsupportedFileType",
  reactNode: <Trans i18nKey="chat.failedToSendMessage.unsupportedFileType" />,
};

function getExceedFileSizeError(maxFileSize: number): FileInvalidatedError {
  return {
    code: "exceedFileSize",
    maxFileSize,
    reactNode: (
      <Trans
        i18nKey="common.fileSizeExceedWithSize"
        values={{ size: toFileSizeDisplayString(maxFileSize) }}
      />
    ),
  };
}

function isUnSupportedFileTypeError(
  error: FileInvalidatedError,
): error is Extract<FileInvalidatedError, { code: "unsupportedFileType" }> {
  return error.code === "unsupportedFileType";
}

function isExceedFileSizeError(
  error: FileInvalidatedError,
): error is Extract<FileInvalidatedError, { code: "exceedFileSize" }> {
  return error.code === "exceedFileSize";
}

function getFormattedExtname(filename: string): string {
  return extname(filename).slice(1).toLowerCase();
}

/**
 * https://docs.google.com/spreadsheets/d/1ivkh2k7rLIDm1x_lTsY60btBaig1zLf0euxyyqPrqqM/edit?usp=sharing
 */
const validator = (() => {
  /**
   * To validate the attachment file type and size.
   *
   * ```ts
   * line.validate(file)
   * line.image.validate(file)
   * line.video.validate(file)
   * line.other.validate(file)
   * ```
   */
  const line = (() => {
    const image = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!lineRules.image.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = lineRules.image.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);

        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return validateFileSize(file);
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();

    const video = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!lineRules.video.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = lineRules.other.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return validateFileSize(file);
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();

    const audio = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!lineRules.audio.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return null;
      }
      return {
        validate,
        validateFileType,
      };
    })();

    const other = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!lineRules.other.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = lineRules.other.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return validateFileSize(file);
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();

    function validate(file: File): FileInvalidatedError | null {
      const validateImageFileTypeError = image.validateFileType(file);
      if (!validateImageFileTypeError) return image.validateFileSize(file);
      const validateVideoFileTypeError = video.validateFileType(file);
      if (!validateVideoFileTypeError) return video.validateFileSize(file);
      const validateAudioFileTypeError = audio.validateFileType(file);
      if (!validateAudioFileTypeError) return audio.validate(file);
      const validateOtherFileTypeError = other.validateFileType(file);
      if (!validateOtherFileTypeError) return other.validateFileSize(file);
      return validateImageFileTypeError;
    }

    return {
      rules: lineRules,
      validate,
      image,
      video,
      audio,
      other,
    };
  })();

  /**
   * To validate the attachment file type and size.
   *
   * ```ts
   * fb.validate(file)
   * fb.image.validate(file)
   * ```
   */
  const fb = (() => {
    const image = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!fbRules.image.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = fbRules.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return validateFileSize(file);
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();

    const video = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!fbRules.video.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = fbRules.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return validateFileSize(file);
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();

    const audio = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!fbRules.audio.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return null;
      }
      return {
        validate,
        validateFileType,
      };
    })();

    const other = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!fbRules.other.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = fbRules.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return validateFileSize(file);
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();
    function validate(file: File): FileInvalidatedError | null {
      const validateImageFileTypeError = image.validateFileType(file);
      if (!validateImageFileTypeError) return image.validateFileSize(file);
      const validateVideoFileTypeError = video.validateFileType(file);
      if (!validateVideoFileTypeError) return video.validateFileSize(file);
      const validateAudioFileTypeError = audio.validateFileType(file);
      if (!validateAudioFileTypeError) return audio.validate(file);
      const validateOtherFileTypeError = other.validateFileType(file);
      if (!validateOtherFileTypeError) return other.validateFileSize(file);
      return validateImageFileTypeError;
    }
    return {
      rules: fbRules,
      validate,
      image,
      video,
      audio,
      other,
    };
  })();

  /**
   * To validate the attachment file type and size.
   *
   * ```ts
   * ig.validate(file)
   * ig.image.validate(file)
   * ```
   */
  const ig = (() => {
    const image = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!igRules.image.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = igRules.image.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return validateFileSize(file);
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();

    const video = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!igRules.video.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = igRules.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return validateFileSize(file);
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();

    const audio = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!igRules.audio.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = igRules.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return null;
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();

    const other = (() => {
      function validateFileType(_file: File): FileInvalidatedError | null {
        return unSupportedFileTypeError;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = igRules.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return validateFileSize(file);
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();
    function validate(file: File): FileInvalidatedError | null {
      const validateImageFileTypeError = image.validateFileType(file);
      if (!validateImageFileTypeError) return image.validateFileSize(file);
      const validateVideoFileTypeError = video.validateFileType(file);
      if (!validateVideoFileTypeError) return video.validateFileSize(file);
      const validateAudioFileTypeError = audio.validateFileType(file);
      if (!validateAudioFileTypeError) return audio.validate(file);
      const validateOtherFileTypeError = other.validateFileType(file);
      if (!validateOtherFileTypeError) return other.validateFileSize(file);
      return validateImageFileTypeError;
    }
    return {
      rules: igRules,
      validate,
      image,
      video,
      audio,
      other,
    };
  })();

  /**
   * To validate the attachment file type and size.
   *
   * ```ts
   * wccs.validate(file)
   * wccs.image.validate(file)
   * wccs.video.validate(file)
   * wccs.other.validate(file)
   * ```
   */
  const wccs = (() => {
    const image = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!wccsRules.image.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = wccsRules.image.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);

        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return validateFileSize(file);
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();

    const video = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!wccsRules.video.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = wccsRules.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return validateFileSize(file);
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();

    const audio = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!wccsRules.audio.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return null;
      }
      return {
        validate,
        validateFileType,
      };
    })();

    const other = (() => {
      function validateFileType(file: File): FileInvalidatedError | null {
        const ext = getFormattedExtname(file.name);
        if (!wccsRules.other.extnames.includes(ext))
          return unSupportedFileTypeError;
        return null;
      }
      function validateFileSize(file: File): FileInvalidatedError | null {
        const maxFileSize = wccsRules.maxFileSize;
        if (file.size > maxFileSize) return getExceedFileSizeError(maxFileSize);
        return null;
      }
      function validate(file: File): FileInvalidatedError | null {
        const validateFileTypeError = validateFileType(file);
        if (validateFileTypeError) return validateFileTypeError;
        return validateFileSize(file);
      }
      return {
        validate,
        validateFileType,
        validateFileSize,
      };
    })();

    function validate(file: File): FileInvalidatedError | null {
      const validateImageFileTypeError = image.validateFileType(file);
      if (!validateImageFileTypeError) return image.validateFileSize(file);
      const validateVideoFileTypeError = video.validateFileType(file);
      if (!validateVideoFileTypeError) return video.validateFileSize(file);
      const validateAudioFileTypeError = audio.validateFileType(file);
      if (!validateAudioFileTypeError) return audio.validate(file);
      const validateOtherFileTypeError = other.validateFileType(file);
      if (!validateOtherFileTypeError) return other.validateFileSize(file);
      return validateImageFileTypeError;
    }

    return {
      rules: wccsRules,
      validate,
      image,
      video,
      audio,
      other,
    };
  })();

  return {
    line,
    fb,
    ig,
    wccs,
    isExceedFileSizeError,
    isUnSupportedFileTypeError,
  };
})();

/**
 * Verify the attached file for a nonspecific file type.
 */
function validateFile(channelType: CantataTypes["ChannelType"], file: File) {
  return validator[channelType].validate(file);
}

function validateImage(channelType: CantataTypes["ChannelType"], file: File) {
  return validator[channelType].image.validate(file);
}

function validateVideo(channelType: CantataTypes["ChannelType"], file: File) {
  return validator[channelType].video.validate(file);
}

function validateAudio(channelType: CantataTypes["ChannelType"], file: File) {
  return validator[channelType].audio.validate(file);
}

function validateOther(channelType: CantataTypes["ChannelType"], file: File) {
  return validator[channelType].other.validate(file);
}

export {
  validateAudio,
  validateFile,
  validateImage,
  validateOther,
  validateVideo,
  validator,
};
export type { FileInvalidatedError };
