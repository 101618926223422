import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import type { ReactNode } from "react";

export const Field = (() => {
  const row = css`
    display: flex;
    align-items: baseline;
    color: ${theme.colors.neutral007};
    font-size: 0.75rem;
    gap: 8px;
  `;

  const fieldName = css`
    display: flex;
    width: 104px;
    box-sizing: border-box;
    flex: 0 0 auto;
    font-size: 0.75rem;
    gap: 4px;
    line-height: 1rem;

    > span {
      font-size: 1rem;
    }
  `;

  return memo<{ label: ReactNode; content: ReactNode }>(function Field({
    label,
    content,
  }) {
    return (
      <div css={row}>
        <div css={fieldName}>{label}</div>
        <div
          css={css`
            flex: 1 1 auto;
          `}
        >
          {content}
        </div>
      </div>
    );
  });
})();
