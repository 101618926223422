import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";

import { Trans } from "@/app/i18n/Trans";
import { Flex } from "@/components/Box";
import { getCssStr } from "@/shared/utils/style/getCssStr";

const Pill = styled.button<{ $active: boolean }>`
  padding: 0 8px;
  border: 1px solid ${theme.colors.neutral005};
  border-radius: 11px;
  background-color: white;
  color: ${theme.colors.neutral010};
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;

  ${(props) =>
    props.$active &&
    getCssStr(
      (css) => css`
        border-color: ${theme.colors.neutral007};
        background-color: ${theme.colors.neutral007};
        color: white;
      `,
    )};
`;

type FilterByOptions = "team" | "agent" | "all";

export const FilterBy = memo(function FilterBy({
  value,
  onChange,
}: {
  value: FilterByOptions;
  onChange: (value: FilterByOptions) => void;
}) {
  const toggle = (draft: FilterByOptions): FilterByOptions => {
    return draft === value ? "all" : draft;
  };
  return (
    <Flex
      css={css`
        flex-direction: column;
        padding: 8px 16px;
        gap: 8px;
      `}
    >
      <div>
        <Trans i18nKey="chat.manualAssignment.assigneeSelector.filterBy.label" />
      </div>
      <Flex
        css={css`
          flex-direction: row;
          gap: 8px;
        `}
      >
        <Pill
          $active={value === "team"}
          onClick={() => onChange(toggle("team"))}
        >
          <Trans i18nKey="chat.manualAssignment.assigneeSelector.option.group.team.label" />
        </Pill>
        <Pill
          $active={value === "agent"}
          onClick={() => onChange(toggle("agent"))}
        >
          <Trans i18nKey="chat.manualAssignment.assigneeSelector.option.group.agent.label" />
        </Pill>
      </Flex>
    </Flex>
  );
});
