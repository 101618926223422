import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { memo } from "@zeffiroso/utils/react/memo";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import { mergeWith } from "lodash-es";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { FormProps } from "@/components/Form";
import { Form } from "@/components/Form";
import { useMessage } from "@/components/message";
import { FormLayout } from "@/layout/FormLayout";
import type { FormValues } from "@/routes/Settings/Notification/NotificationSettings/form";
import {
  initialValues,
  useCurrentQuery,
} from "@/routes/Settings/Notification/NotificationSettings/form";
import { FormTable } from "@/routes/Settings/Notification/NotificationSettings/FormTable";
import { RequestPermission } from "@/routes/Settings/Notification/NotificationSettings/RequestPermission";

const NotificationSettings = memo(function NotificationSettings() {
  const { t } = useTranslation();
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const [form] = Form.useForm<Partial<FormValues>>();

  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = useCurrentQuery();
  const message = useMessage();
  const mutation = cantata.notificationSetting.useUpdate(
    {
      params: {
        orgId,
      },
    },
    {
      onSuccess: () => {
        message.success(t("common.updatedSuccessfully"));
        safeInvalidateQuery(query.key);
        form.resetFields();
      },
    },
  );

  const onFinish = useHandler<FormProps<Partial<FormValues>>["onFinish"]>(
    (values) => {
      const mergedValues = mergeWith(
        {},
        initialValues,
        query.data,
        values,
        (objValue, srcValue) => {
          if (srcValue === undefined) return objValue;
          return srcValue;
        },
      );
      mutation.mutate(mergedValues);
    },
  );

  return (
    <Form<Partial<FormValues>>
      form={form}
      disabled={mutation.isLoading}
      onFinish={onFinish}
    >
      <FormLayout.Container
        submit={
          <FormLayout.SubmitButton>
            {t("common.update")}
          </FormLayout.SubmitButton>
        }
      >
        <FormLayout.RowField
          title={t("notification.setting.field.label")}
          content={
            <>
              <FormLayout.Item width="wide">
                <RequestPermission />
              </FormLayout.Item>
              <FormLayout.Item width="full">
                <FormTable />
              </FormLayout.Item>
            </>
          }
        />
      </FormLayout.Container>
    </Form>
  );
});

export { NotificationSettings };
