import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { getDisplayName } from "@chatbotgang/etude/react/getDisplayName";
// eslint-disable-next-line no-restricted-imports -- The only file allowed to import memo from react is this one.
import { memo } from "react";

/**
 * @deprecated We recommend using memorization hooks over the memo component
 * due to [memo is too easy to break](https://tkdodo.eu/blog/the-uphill-battle-of-memoization)
 *
 * Memoize a component but with a display name.
 *
 * In most cases, this function is unnecessary due to the inherent difficulty in
 * memoizing components. The props often vary, particularly when there is a prop
 * of type `Function` or `ReactNode`, an inline object like style, the `css`
 * prop, and so on. The existence of the `memo` function highlights the
 * suboptimal design of React. For further insights, explore: [The Uphill Battle
 * of Memoization](https://tkdodo.eu/blog/the-uphill-battle-of-memoization).
 *
 * Issue:
 *
 * - [Memoized components should forward displayName
 *   #15207](https://github.com/facebook/react/issues/15207)
 *
 * @example
 *
 * ```ts
 * getDisplayName(memo(function Foo() { return null; })); // ''
 * getDisplayName(memoForwardedName(function Foo() { return null; })); // 'memo(Foo)'
 * ```
 *
 * @see https://github.com/facebook/react/issues/15207
 *
 * @see https://app.asana.com/0/1199607007611227/1205394699513896/f
 */
const memoForwardedName: typeof memo = function memoForwardedName(
  ...args: Parameters<typeof memo>
) {
  const [Component] = args;
  const displayName = getDisplayName(Component);
  const memorizedComponent = memo(...args);
  assignDisplayName(memorizedComponent, `memo(${displayName})`);
  return memorizedComponent;
};
export { memoForwardedName as memo };
