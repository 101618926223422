import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { merge, omit } from "lodash-es";
import { type FC, useMemo } from "react";
import type { O } from "ts-toolbelt";

import type { CantataTypes } from "@/cantata/types";
import { MotifIcon } from "@/components/MotifIcon";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  inactiveIcon: css({
    color: theme.colors.neutral006,
  }),
  activeWebChatIcon: css({
    color: theme.colors.blue006,
  }),
});

type Options = {
  /**
   * Some channel type like facebook has two different icons for chatting and
   * other features. This option is for switching between them.
   */
  chatting?: boolean;
};

const defaultOptions: Options = {
  chatting: false,
};

function getChannelLogo(
  channelType: CantataTypes["Channel"]["type"],
  isConnected: boolean,
  options?: O.Partial<Options, "deep">,
) {
  const mergedOptions: Options = merge({}, defaultOptions, options);
  const channelLogoMap = {
    line: {
      active: <MotifIcon un-i-motif="line_color" />,
      inactive: <MotifIcon un-i-motif="line_mono" css={styles.inactiveIcon} />,
    },
    fb: mergedOptions.chatting
      ? {
          active: <MotifIcon un-i-motif="messenger" />,
          inactive: (
            <MotifIcon un-i-motif="messenger_mono" css={styles.inactiveIcon} />
          ),
        }
      : {
          active: <MotifIcon un-i-motif="facebook" />,
          inactive: (
            <MotifIcon un-i-motif="facebook_mono" css={styles.inactiveIcon} />
          ),
        },
    ig: {
      active: <MotifIcon un-i-motif="instagram" />,
      inactive: (
        <MotifIcon un-i-motif="instagram_mono" css={styles.inactiveIcon} />
      ),
    },
    whatsApp: {
      active: <MotifIcon un-i-motif="whatsapp" />,
      inactive: (
        <MotifIcon un-i-motif="whatsapp_mono" css={styles.inactiveIcon} />
      ),
    },
    wccs: {
      active: (
        <MotifIcon
          un-i-motif="bubble_chat_fill"
          css={styles.activeWebChatIcon}
        />
      ),
      inactive: (
        <MotifIcon un-i-motif="bubble_chat_fill" css={styles.inactiveIcon} />
      ),
    },
  };
  const { active, inactive } = channelLogoMap[channelType];
  return isConnected ? active : inactive;
}

type ChannelTypeIconProps = (
  | { channelType: CantataTypes["Channel"]["type"]; on?: boolean }
  | { channel: Pick<CantataTypes["Channel"], "status" | "type"> }
) &
  ComponentProps<"div"> & {
    options?: O.Partial<Options, "deep">;
  };

/**
 * Use this channel type logo for features other than chatting
 */
const ChannelTypeIcon: FC<ChannelTypeIconProps> = ({
  options,
  style,
  ...props
}) => {
  const { channelType, isConnected } = useMemo(() => {
    const channelType =
      "channelType" in props ? props.channelType : props.channel.type;
    const isConnected =
      "channel" in props
        ? props.channel.status === "connected"
        : props.on || true;

    return {
      channelType,
      isConnected,
    };
  }, [props]);

  const channelLogoImage = useMemo(
    () => getChannelLogo(channelType, isConnected, options),
    [channelType, isConnected, options],
  );

  const elementProps = omit(props, ["channel", "channelType", "on"]);
  return (
    <div style={{ ...style }} {...elementProps}>
      {channelLogoImage}
    </div>
  );
};

type ChannelTypeChattingIconProps = ChannelTypeIconProps;

/**
 * Use this channel type logo for chatting features
 */
const ChannelTypeChattingIcon: FC<ChannelTypeChattingIconProps> = (props) => {
  const mergedOptions: Options = useMemo(
    function mergeOptions() {
      return merge(
        {},
        {
          chatting: true,
        },
        props.options,
      );
    },
    [props.options],
  );
  return <ChannelTypeIcon {...props} options={mergedOptions} />;
};

export { ChannelTypeChattingIcon, ChannelTypeIcon };
export type { ChannelTypeChattingIconProps, ChannelTypeIconProps };
