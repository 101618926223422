import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import { useQueryClient } from "@tanstack/react-query";
import { memo } from "@zeffiroso/utils/react/memo";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import { secondsToMilliseconds } from "date-fns";
import { omit, throttle } from "lodash-es";
import { type FC, Fragment, Suspense, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useLegatoEvent } from "@/legato";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
const THROTTLE_MS = secondsToMilliseconds(1);

const InvalidateMemberFromLegato: FC = () => {
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const member = memberQueriesContext.useMember();
  const memberQuery = cantata.member.useGetById(
    {
      params: {
        orgId,
        memberId: member.id,
      },
    },
    {
      enabled: false,
    },
  );
  const invalidateMemberQuery = useHandler(function invalidateMemberQuery() {
    safeInvalidateQuery(memberQuery.key);
  });
  const throttledInvalidateMemberQuery = useMemo(
    () => throttle(invalidateMemberQuery, THROTTLE_MS),
    [invalidateMemberQuery],
  );
  const conversationHistoryQuery = cantata.conversationHistory.useList(
    {
      params: {
        orgId,
        memberId: member.id,
      },
    },
    {
      enabled: false,
    },
  );
  const invalidateConversationHistoryQuery = useHandler(
    function invalidateConversationHistoryQuery() {
      safeInvalidateQuery(conversationHistoryQuery.key);
    },
  );
  const throttledInvalidateConversationHistoryQuery = useMemo(
    () => throttle(invalidateConversationHistoryQuery, THROTTLE_MS),
    [invalidateConversationHistoryQuery],
  );
  useLegatoEvent("member-list-updated", function invalidateQueries(data) {
    if (data.type !== "member-list-updated") return;
    if (data.content.member.id !== member.id) return;
    // Only invalidate if the unreadMessageCount has changed for now.
    if (data.content.member.unreadMessageCount !== member.unreadMessageCount) {
      throttledInvalidateMemberQuery();
    }
    if (data.content.member.processingState !== member.processingState) {
      /**
       * Invalidate the conversation history query if the processing state has
       * changed.
       */
      throttledInvalidateConversationHistoryQuery();
    }
  });
  return null;
};

const UpdateActiveMemberOnLegatoEvent =
  (function declareUpdateActiveMemberOnLegatoEvent() {
    const propertiesToCheck = define<Array<keyof CantataTypes["Member"]>>()([
      "processingState",
      "pinned",
      "starMark",
      "assignmentRelationship",
    ]);
    const UpdateActiveMemberOnLegatoEvent = memo(
      function UpdateActiveMemberOnLegatoEvent() {
        const activeOrgId = useActiveOrgIdStore((state) => state.value);
        const activeMemberId = memberIdUtils.useGet();
        const memberQuery = cantata.member.useGetById(
          {
            params: {
              orgId: activeOrgId,
              memberId: activeMemberId,
            },
          },
          {
            enabled: false,
          },
        );
        const queryClient = useQueryClient();
        useLegatoEvent("member-list-updated", function updateActiveMember(e) {
          if (!activeMemberId) return;
          if (e.content.member.id !== activeMemberId) return;
          if (!memberQuery.isSuccess) return;
          const changed = propertiesToCheck.some((property) => {
            return memberQuery.data[property] !== e.content.member[property];
          });
          if (!changed) return;
          const member: NonNullable<typeof memberQuery.data> = {
            ...memberQuery.data,
            ...omit(e.content.member, [
              // Type Incompatible properties
              "lastMessage",
            ]),
          };
          queryClient.setQueryData(memberQuery.key, member);
        });
        return null;
      },
    );
    return UpdateActiveMemberOnLegatoEvent;
  })();

const Wrapper: FC = () => {
  return (
    <ErrorBoundary fallback={() => <Fragment />}>
      <Suspense fallback={null}>
        <memberQueriesContext.Provider>
          <InvalidateMemberFromLegato />
          <UpdateActiveMemberOnLegatoEvent />
        </memberQueriesContext.Provider>
      </Suspense>
    </ErrorBoundary>
  );
};

export { Wrapper as UpdateMemberFromLegato };
