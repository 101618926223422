import useEnhancedEffect from "@mui/utils/useEnhancedEffect";
import useResizeObserver from "@react-hook/resize-observer";
import type { RefObject } from "react";
import { useState } from "react";

/**
 * To observe the size of an element. If the element is not rendered yet, the
 * return value will be `null`.
 *
 * @example
 *
 * ```ts
 * const target = useRef(null)
 * const size = useSize(target)
 * return (
 *   <pre ref={target}>
 *     {JSON.stringify({width: size.width, height: size.height}, null, 2)}
 *   </pre>
 * )
 * ```
 *
 * @see https://github.com/jaredLunde/react-hook/tree/3c813da/packages/resize-observer#quick-start
 */
function useSize(target: RefObject<HTMLElement>) {
  const [size, setSize] = useState<null | DOMRect>(null);

  useEnhancedEffect(() => {
    if (!target.current) {
      setSize(null);
      return;
    }
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
}

export { useSize };
