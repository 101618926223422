import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";
import { ThemeProvider } from "@zeffiroso/theme/Provider";
import { memo } from "@zeffiroso/utils/react/memo";
import { hoursToMilliseconds } from "date-fns";
import type { ReactNode } from "react";
import { Suspense } from "react";

import { useFeatureFlag } from "@/app/featureFlag";
import { WaveLoading } from "@/components/Loading/WaveLoading";
import { CheckUpdate, RecoverShowUpdateDialog } from "@/internal/CheckUpdate";
import { Helmet } from "@/internal/helmet/Helmet";
import { MessageProvider } from "@/internal/message";
import { PageErrorBoundary } from "@/internal/PageErrorBoundary";
import { RequirePermission } from "@/internal/requirePermission";
import { Sw } from "@/internal/sw";
import { Zendesk } from "@/internal/zendesk/Zendesk";
import { DevMode } from "@/layout/DevMode";
import { queryClient } from "@/lib/react-query/queryClient";
import { MutationErrorEmitter } from "@/lib/react-query/useMutationError";
import { Router } from "@/router/Router";
import { useAuthStatus } from "@/shared/application/authenticate";
import { ApplyI18nFromFeatureFlag } from "@/shared/g11n/i18n";
import { LocaleProvider } from "@/shared/store/locale";

const App = memo(function App() {
  const authStatus = useAuthStatus();

  return (
    <>
      <Router />
      <RecoverShowUpdateDialog
        timeoutDelayMs={
          authStatus.isSignedIn
            ? /* https://www.figma.com/file/I0BYJfDZZTbOiGZrJzKT1u?node-id=507:98129#456511619 */
              hoursToMilliseconds(2)
            : 0
        }
      />
    </>
  );
});

const ReactQueryProvider = memo(function ReactQueryProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const position = useFeatureFlag("reactQueryDevtool");
  return (
    <QueryClientProvider client={queryClient}>
      <MutationErrorEmitter />
      {!position ? null : (
        <ReactQueryDevtools initialIsOpen={false} position={position} />
      )}
      {children}
    </QueryClientProvider>
  );
});

const AppProviders = memo(function AppProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <ThemeProvider>
      <PageErrorBoundary reloadWindow>
        <Suspense fallback={<WaveLoading />}>
          {flutterWebViewAppSdk.isFlutterWebViewApp ? null : <Sw />}
          <MessageProvider>
            <LocaleProvider>
              <ReactQueryProvider>
                <Helmet />
                {children}
                <DevMode />
                <CheckUpdate />
                <Zendesk />
                <ApplyI18nFromFeatureFlag />
                <RequirePermission />
              </ReactQueryProvider>
            </LocaleProvider>
          </MessageProvider>
        </Suspense>
      </PageErrorBoundary>
    </ThemeProvider>
  );
});

export { App, AppProviders };
