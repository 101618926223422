import useChange from "@react-hook/change";
import { memo } from "@zeffiroso/utils/react/memo";
import { hoursToMilliseconds, secondsToMilliseconds } from "date-fns";
import { Fragment, useEffect, useState } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { sentryDebug } from "@/app/sentryDebug";
import { useLegato } from "@/legato";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";

const DisconnectForWhile = (function DisconnectForWhile() {
  const delay = secondsToMilliseconds(20);

  const DisconnectForWhile = memo(function DisconnectForWhile() {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const orgQueriesData = orgQueriesContext.useData();
    const userId = orgQueriesData.me.id;
    const legato = useLegato();
    useEffect(() => {
      if (legato.connected) return;
      const timeout = setTimeout(function log() {
        sentryDebug("disconnectForWhile", { orgId, userId, delay });
      }, delay);
      return function cleanup() {
        clearTimeout(timeout);
      };
    }, [legato.connected, orgId, userId]);
    return null;
  });
  return DisconnectForWhile;
})();

const DisconnectTooMuchTime = (function setupDisconnectTooMuchTime() {
  const MaxDisconnectTime = 10;
  const DisconnectPeriod = hoursToMilliseconds(1);

  const DisconnectTooMuchTime = memo(function DisconnectTooMuchTime() {
    const [disconnectTimes] = useState<Array<Date>>(() => []);
    const orgId = useActiveOrgIdStore((state) => state.value);
    const orgQueriesData = orgQueriesContext.useData();
    const userId = orgQueriesData.me.id;
    const legato = useLegato();
    useChange(legato.connected, function logDisconnect(current) {
      if (current) return;
      // filter out the disconnects that are too old
      const now = Date.now();
      let indexToRemove = disconnectTimes.findIndex(
        (time) => now - time.getTime() > DisconnectPeriod,
      );
      while (indexToRemove !== -1) {
        disconnectTimes.splice(indexToRemove, 1);
        indexToRemove = disconnectTimes.findIndex(
          (time) => now - time.getTime() > DisconnectPeriod,
        );
      }

      disconnectTimes.push(new Date());

      if (disconnectTimes.length === MaxDisconnectTime + 1) {
        sentryDebug("disconnectSeveralTimes", {
          orgId,
          userId,
          times: disconnectTimes,
        });
      }
    });
    return null;
  });
  return DisconnectTooMuchTime;
})();

/**
 * This component is used to debug the legato connection. It will log the
 * disconnects that are too long or too frequent to Sentry.
 */
const LegatoDebugger = memo(function LegatoDebugger() {
  const { shouldConnect } = useLegato();
  return !shouldConnect ? null : (
    <Fragment>
      <DisconnectTooMuchTime />
      <DisconnectForWhile />
    </Fragment>
  );
});

export { LegatoDebugger };
