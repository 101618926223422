import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import { memo } from "@zeffiroso/utils/react/memo";
import type { ComponentProps } from "react";

import type { CantataTypes } from "@/cantata/types";
import { colorMap } from "@/resources/user/onDutyStatus/utils/colorMap";

type DotProps = ComponentProps<"div"> & {
  size?: number | string;
  status?: CantataTypes["UserOnDutyStatus"];
};

const cssVariablesPrefix = `--on-duty-status-dot-${random()}-`;

export const Dot = memo(
  forwardRef<HTMLDivElement, DotProps>(function Dot(
    { size, status, ...props },
    ref,
  ) {
    return !status ? null : (
      <div
        {...props}
        css={css`
          ${cssVariablesPrefix}size: ${size === undefined
            ? "1em"
            : typeof size === "number"
              ? `${size}px`
              : size};

          width: var(${cssVariablesPrefix}size);
          min-width: var(${cssVariablesPrefix}size);
          max-width: var(${cssVariablesPrefix}size);
          height: var(${cssVariablesPrefix}size);
          min-height: var(${cssVariablesPrefix}size);
          max-height: var(${cssVariablesPrefix}size);
          border-radius: calc(var(${cssVariablesPrefix}size) / 2);
          background-color: ${colorMap[status]};
        `}
        ref={ref}
      />
    );
  }),
);
