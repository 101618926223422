const config = {
  text: {
    maxLength: {
      /**
       * @see https://developers.line.biz/en/reference/messaging-api/#text-message
       */
      line: 5000,
      /**
       * @see https://developers.facebook.com/docs/messenger-platform/reference/send-api/
       */
      fb: 2000,
      /**
       * @see https://developers.facebook.com/docs/messenger-platform/instagram/features/send-message#send-api
       */
      ig: 1000,
      wccs: 1000,
    },
  },
};

export { config };
