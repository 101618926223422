import { type FC, useMemo } from "react";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useChannelQueries } from "@/resources/channel/useChannelQueries";

namespace ChannelName {
  export interface Props extends useChannelQueries.Options {}
  export type Type = FC<Props>;
}

const ChannelNameInternal: FC<ChannelName.Props> = ({
  orgId,
  channelId,
  queryOptions,
}) => {
  const queriesOptions = useMemo<Parameters<typeof useChannelQueries>[0]>(
    () => ({
      orgId,
      channelId,
      queryOptions: {
        suspense: true,
        useErrorBoundary: true,
        ...queryOptions,
      },
    }),
    [channelId, orgId, queryOptions],
  );
  const { data } = useChannelQueries(queriesOptions);
  return data ? <>{data.name}</> : null;
};

/**
 * Wrap the component with an error boundary and suspense.
 */
const SafeChannelName: ChannelName.Type = (props) => {
  return (
    <ErrorBoundary.Alert>
      <ChannelNameInternal {...props} />
    </ErrorBoundary.Alert>
  );
};

/**
 * Display the name of a channel.
 *
 * If you want to suspend the component while loading, use `ChannelName.Safe`.
 */
const ChannelName = Object.assign(ChannelNameInternal, {
  Safe: SafeChannelName,
});

export { ChannelName };
