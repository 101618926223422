import {
  AlignLeftOutlined,
  PaperClipOutlined,
  PictureOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { fc } from "@chatbotgang/etude/react/fc";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { Form } from "antd";
import { isEmpty, lowerFirst } from "lodash-es";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Button } from "@/components/Button";
import { NotFoundContent } from "@/components/Empty/NotFoundContent";
import { FormItem } from "@/components/Form";
import { Input } from "@/components/Input";
import { useMessage } from "@/components/message";
import {
  MessagesEditorBody,
  MessagesEditorContainer,
  MessagesEditorHeader,
  ModuleSelectorContainer,
} from "@/components/MessageEditor/MessagesEditor";
import { MessagesLimitCount } from "@/components/MessageEditor/MessagesLimitCount";
import { ModuleButton } from "@/components/MessageEditor/ModuleButton";
import { Select } from "@/components/Select";
import { checkSupportMessageType } from "@/resources/quickTemplate/checkSupportMessageType";
import { routerUtils } from "@/router/routerUtils";
import { useEditorRules } from "@/routes/QuickTemplate/applications/useEditorRules";
import { useQuickTemplateOptions } from "@/routes/QuickTemplate/applications/useQuickTemplateOptions";
import {
  buildFileMessage,
  buildImageMessage,
  buildTextMessage,
  buildVideoMessage,
  omitDraggableId,
} from "@/routes/QuickTemplate/applications/utils";
import {
  LINE_MESSAGES_LIMIT,
  TEMPLATE_NAME_LENGTH_LIMIT,
} from "@/routes/QuickTemplate/constants";
import { SelectedChannelContext } from "@/routes/QuickTemplate/SelectedChannelContext";
import { DeleteTemplateModal } from "@/routes/QuickTemplate/ui/DeleteTemplateModal";
import { EditorFactory } from "@/routes/QuickTemplate/ui/EditorFactory";
import { useInitialValues } from "@/routes/QuickTemplate/ui/InitialValuesProvider";
import { messageSortFactory } from "@/routes/QuickTemplate/ui/messageSortFactory";
import { PreviewFactory } from "@/routes/QuickTemplate/ui/PreviewFactory";
import type { QuickTemplateFormValue } from "@/routes/QuickTemplate/ui/type";

//#region style
const FieldsContainer = styled.div`
  width: 336px;
`;

const FormItemLabel = styled.div`
  color: ${theme.colors.neutral010};
`;

const FieldTitle = styled(FormItemLabel)`
  margin-bottom: 16px;
  color: ${theme.colors.neutral010};
`;

const ControlButtonWrapper = styled.div`
  position: absolute;
  top: 32px;
  right: 40px;
  display: flex;
  gap: 16px;
`;

const FormEditorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 82px;
`;
//#endregion

const DeleteTemplateButton = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const channel = SelectedChannelContext.useContext();
  const channelId = channel.id;
  const templateId: undefined | QuickTemplateFormValue["id"] = Form.useWatch<
    undefined | QuickTemplateFormValue["id"]
  >("id");
  const templateName: undefined | QuickTemplateFormValue["name"] =
    Form.useWatch<undefined | QuickTemplateFormValue["name"]>("name");

  if (templateId === undefined || templateName === undefined) return null;

  return (
    <>
      <DeleteTemplateModal
        channelId={channelId}
        templateId={templateId}
        templateName={templateName}
        visible={visible}
        onSuccess={onSuccess}
        onClose={() => setVisible(false)}
      />
      <Button onClick={() => setVisible(true)}>{t("common.delete")}</Button>
    </>
  );
};

const ModuleButtons = fc(function ModuleButtons() {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const messages = Form.useWatch<
    undefined | CantataTypes["QuickTemplateDetail"]["messages"]
  >("messages");
  const reachedLimit = !messages || messages.length >= LINE_MESSAGES_LIMIT;
  const channel = SelectedChannelContext.useContext();

  const checkSupport = useCallback(
    function checkSupport(
      messageType: CantataTypes["QuickTemplateDetail"]["messages"][number]["type"],
    ) {
      return checkSupportMessageType(channel.type, messageType);
    },
    [channel.type],
  );

  const moduleButtons: Array<
    ComponentProps<typeof ModuleButton> & {
      key: CantataTypes["QuickTemplateDetail"]["messages"][number]["type"];
    }
  > = useMemo(
    function computeModuleButtons() {
      /**
       * Initial state of messages is undefined.
       */
      if (!messages) return [];
      const all: typeof moduleButtons = [
        {
          key: "text",
          icon: <AlignLeftOutlined />,
          children: t("message.module.text"),
          onClick() {
            form.setFieldValue("messages", [...messages, buildTextMessage()]);
          },
        },
        {
          key: "image",
          icon: <PictureOutlined />,
          children: t("message.module.image"),
          onClick() {
            form.setFieldValue("messages", [...messages, buildImageMessage()]);
          },
        },
        {
          key: "video",
          icon: <PlayCircleOutlined />,
          children: t("message.module.video"),
          onClick() {
            form.setFieldValue("messages", [...messages, buildVideoMessage()]);
          },
        },
        {
          key: "file",
          icon: <PaperClipOutlined />,
          children: t("message.module.file"),
          onClick() {
            form.setFieldValue("messages", [...messages, buildFileMessage()]);
          },
        },
      ];

      return all.flatMap<(typeof moduleButtons)[number]>((buttonProps) => {
        if (!checkSupport(buttonProps.key)) return [];
        return {
          ...buttonProps,
          disable: reachedLimit,
        };
      });
    },
    [checkSupport, form, messages, reachedLimit, t],
  );
  return moduleButtons.map((buttonProps) => (
    <ModuleButton {...buttonProps} key={buttonProps.key} />
  ));
});

const CreateOrEditTemplate = memo<{
  quickTemplates: Array<CantataTypes["QuickTemplate"]>;
  channelId: number;
  channelType: CantataTypes["Channel"]["type"];
  templateId?: number;
  onDeleteSuccess?: () => void;
  onCreateSuccess?: (categoryId: number | null, templateId: number) => void;
}>(function CreateOrEditTemplate({
  channelId,
  channelType,
  templateId,
  quickTemplates,
  onDeleteSuccess,
  onCreateSuccess,
}) {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const [form] = Form.useForm<QuickTemplateFormValue>();
  const [isDirty, setIsDirty] = useState(false);
  const message = useMessage();
  const createMutation = cantata.quickTemplate.useCreate(
    {
      params: {
        orgId,
        channelId,
      },
    },
    {
      onSuccess(result) {
        form.setFieldsValue({ id: result.id });
        onCreateSuccess?.(result.categoryId, result.id);
        message.success(t("common.createSuccessfully"));
        setIsDirty(false);
      },
    },
  );

  const updateMutation = cantata.quickTemplate.useUpdate(
    {
      params: {
        orgId,
        channelId,
        quickTemplateId: templateId ?? Number.NaN,
      },
    },
    {
      onSuccess() {
        message.success(t("common.updatedSuccessfully"));
        setIsDirty(false);
      },
    },
  );

  const options = useQuickTemplateOptions(quickTemplates);

  const rules = useEditorRules(channelType);

  const initialValues = useInitialValues();

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      onValuesChange={() => {
        if (!isDirty) setIsDirty(true);
      }}
      onFinishFailed={(errorInfo) => {
        errorInfo.errorFields.forEach((errField) => {
          message.error(errField.errors[0]);
        });
      }}
      onFinish={(formValues) => {
        const isCreate = Number.isNaN(formValues.id);
        const draftTemplate = {
          ...formValues,
          messages: formValues.messages.map(
            omitDraggableId,
          ) as CantataTypes["QuickTemplateDetail"]["messages"],
        };

        if (isCreate) {
          createMutation.mutate(draftTemplate);
          return;
        }

        updateMutation.mutate(draftTemplate);
      }}
      initialValues={initialValues}
    >
      <routerUtils.Blocker block={isDirty} />
      <ControlButtonWrapper>
        <DeleteTemplateButton onSuccess={onDeleteSuccess} />
        <Button
          loading={createMutation.isLoading || updateMutation.isLoading}
          type="primary"
          htmlType="submit"
        >
          {t("quickTemplate.save")}
        </Button>
      </ControlButtonWrapper>
      <FormItem hidden name="id">
        <Input />
      </FormItem>
      <FieldsContainer>
        <FormItem
          data-test="name-field"
          label={
            <FormItemLabel>{t("glossary.quickTemplateName")}</FormItemLabel>
          }
          name="name"
          rules={[
            {
              required: true,
              message: t("validation.pleaseInputField", {
                field: lowerFirst(t("glossary.quickTemplateName")),
              }),
            },
            {
              max: TEMPLATE_NAME_LENGTH_LIMIT,
              message: t("validation.maxCharLength", {
                count: TEMPLATE_NAME_LENGTH_LIMIT,
              }),
            },
          ]}
        >
          <Input placeholder={t("quickTemplate.unnamedTemplates")} />
        </FormItem>
        <FormItem
          data-test="category-field"
          label={
            <FormItemLabel>{t("glossary.quickTemplateCategory")}</FormItemLabel>
          }
          name="categoryId"
          getValueProps={(value) => {
            if (!value) return { value: 0 };
            return { value };
          }}
          normalize={(value) => {
            if (!value) return null;
            return value;
          }}
        >
          <Select<number>
            allowClear={true}
            notFoundContent={
              <NotFoundContent message={t("common.noResults")} />
            }
            optionFilterProp="label"
            options={options}
            placeholder={t("quickTemplate.categoryPlaceholder")}
            showSearch={true}
          />
        </FormItem>
      </FieldsContainer>
      <FieldTitle>{t("glossary.quickTemplateContent")}</FieldTitle>
      <FormEditorWrapper>
        <Form.List
          name="messages"
          rules={[
            {
              message: t("validation.emptyMessages"),
              validator: (_, messages) => {
                if (isEmpty(messages))
                  return Promise.reject(
                    new Error("messages should not be empty."),
                  );

                return Promise.resolve();
              },
            },
          ]}
        >
          {(fields, operation) => (
            <MessagesEditorContainer>
              <MessagesEditorHeader />
              <MessagesEditorBody>
                <EditorFactory
                  fields={fields}
                  operation={operation}
                  channelType={channelType}
                  rules={rules}
                />
                <MessagesLimitCount
                  count={fields.length}
                  limit={LINE_MESSAGES_LIMIT}
                />
                <ModuleSelectorContainer>
                  <ModuleButtons />
                </ModuleSelectorContainer>
              </MessagesEditorBody>
              {fields.length > 1 && messageSortFactory(fields, operation)}
            </MessagesEditorContainer>
          )}
        </Form.List>
        <Form.List name="messages">{PreviewFactory}</Form.List>
      </FormEditorWrapper>
    </Form>
  );
});

export { CreateOrEditTemplate };
