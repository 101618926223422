import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Tooltip } from "@/components/Tooltip";
import { shouldFallbackToMemberCurrentAssignee } from "@/resources/conversation/shouldFallbackToMemberCurrentAssignee";
import { useConversation } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/Context";
import { cssFlexInheritAndFill, cssOneLine } from "@/shared/emotion";

const cssContainer = css([
  cssFlexInheritAndFill,
  css`
    flex-direction: row;
    color: ${theme.colors.neutral007};
    gap: 8px;
    ${cssOneLine}
  `,
]);

const cssSeperator = css`
  &::before {
    content: "-";
  }
`;

const cssItem = css`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Assignee = memo(function Assignee() {
  const { t } = useTranslation();
  const conversation = useConversation();
  const shouldUseMemberAssignee = useMemo(
    () => shouldFallbackToMemberCurrentAssignee(conversation),
    [conversation],
  );
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberQuery = cantata.member.useGetById({
    params: {
      orgId,
      memberId: conversation.memberId,
    },
    enabled: shouldUseMemberAssignee,
  });
  const assignee = useMemo(
    function computedAssignee(): CantataTypes["Conversation"]["resolverAssignmentRelationship"] {
      if (!shouldUseMemberAssignee || !memberQuery.isSuccess)
        return conversation.resolverAssignmentRelationship;
      return memberQuery.data.assignmentRelationship;
    },
    [
      conversation.resolverAssignmentRelationship,
      memberQuery.data?.assignmentRelationship,
      memberQuery.isSuccess,
      shouldUseMemberAssignee,
    ],
  );
  const assigneeData = useMemo(
    function computedAssignee(): Array<string> {
      if (!assignee || (!assignee.team && !assignee.user))
        return [t("glossary.unassigned")];

      return [
        ...(!assignee?.team ? [] : [assignee.team.name]),
        ...(!assignee?.user ? [] : [assignee.user.name]),
      ];
    },
    [assignee, t],
  );
  return (
    <div css={cssContainer}>
      {assigneeData.map((str, i) => {
        return (
          <Fragment key={i}>
            {i === 0 ? null : <div css={cssSeperator} />}
            <Tooltip title={str}>
              <span css={cssItem}>{str}</span>
            </Tooltip>
          </Fragment>
        );
      })}
    </div>
  );
});

export { Assignee };
