import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { theme } from "@zeffiroso/theme";
import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import type { FormInstance } from "antd";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { Input } from "@/components/Input";
import { MotifIcon } from "@/components/MotifIcon";
import { Select } from "@/components/Select";
import { SubText } from "@/components/Typography";
import { UsersCount } from "@/resources/organization/UsersCount";
import { RoleName } from "@/resources/role/RoleName";

type BatchInviteUsersFormValues = {
  emails: string;
  roleId: CantataTypes["Role"]["id"];
};

const EasyForm = createEasyForm<BatchInviteUsersFormValues>();

interface FormProps {
  form: FormInstance<BatchInviteUsersFormValues>;
  verifiedEmailsCount: number | null;
  resetVerifiedEmailsCount: () => void;
  handleFinishForm: (values: BatchInviteUsersFormValues) => void;
}

const Form: FC<FormProps> = ({
  form,
  verifiedEmailsCount,
  resetVerifiedEmailsCount,
  handleFinishForm,
}) => {
  const { t } = useTranslation();
  const queriesData = SetupQueriesContext.useData();
  const usersCount = queriesData.usersCount;
  const orgPlan = queriesData.orgPlan;
  const roles = queriesData.roles;

  const initialValues = useMemo<
    ComponentProps<typeof EasyForm>["initialValues"]
  >(
    () => ({
      roleId: roles[0]?.id || Number.NaN,
      emails: "",
    }),
    [roles],
  );

  const restSeatCount = useMemo(() => {
    const restSeats = orgPlan.seatNum - usersCount;
    return restSeats <= 0 ? 0 : restSeats;
  }, [orgPlan.seatNum, usersCount]);

  const overSeat =
    restSeatCount <= 0 ||
    (verifiedEmailsCount ? verifiedEmailsCount > restSeatCount : false);

  const color = overSeat ? theme.colors.red006 : theme.colors.green006;

  return (
    <EasyForm
      initialValues={initialValues}
      requiredMark={false}
      form={form}
      layout="vertical"
      onFinish={handleFinishForm}
    >
      <EasyForm.Item
        name="emails"
        label={t("organization.pleaseInputEmail")}
        rules={[
          {
            required: true,
            message: t("organization.pleaseInputEmail"),
          },
        ]}
        style={{ marginBottom: 8, fontWeight: 500 }}
        validateStatus={!overSeat ? "" : "error"}
      >
        <Input.TextArea
          placeholder={["foo@mail.com", "bar@mail.com"].join("\n")}
          onChange={() => resetVerifiedEmailsCount()}
        />
      </EasyForm.Item>
      <SubText
        style={{
          display: "block",
          marginBottom: 24,
          color:
            restSeatCount > 0 ? theme.colors.neutral007 : theme.colors.error,
        }}
      >
        {t("organization.restPosition", { count: restSeatCount })}
        {!verifiedEmailsCount ? null : (
          <>
            {` - ${verifiedEmailsCount} = `}
            <span style={{ color }}>
              {restSeatCount - verifiedEmailsCount}
            </span>{" "}
            {overSeat ? (
              <MotifIcon style={{ color }} un-i-motif="circle_cross" />
            ) : (
              <MotifIcon style={{ color }} un-i-motif="check" />
            )}
          </>
        )}
      </SubText>
      <EasyForm.Item
        name="roleId"
        label={t("organization.pleaseSelectRole")}
        style={{ fontWeight: 500 }}
        wrapperCol={{
          span: 8,
        }}
        rules={[
          {
            required: true,
            message: t("organization.pleaseSelectRole.rule.required"),
          },
        ]}
      >
        <Select
          options={roles.map((role) => ({
            key: role.id,
            value: role.id,
            label: <RoleName role={role} />,
          }))}
        />
      </EasyForm.Item>
    </EasyForm>
  );
};

function useSetupQueries() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const rolesQuery = cantata.role.useListRoles(
    {
      params: {
        orgId,
      },
    },
    {
      select: ({ roles }) => roles.filter((role) => role.type !== "owner"),
      useErrorBoundary: true,
      suspense: true,
    },
  );
  const usersCountQuery = UsersCount.useQuery({
    orgId,
    /**
     * Internal users are not counted for seat.
     */
    excludeInternal: true,
    suspense: true,
    useErrorBoundary: true,
  });
  const orgPlanQuery = cantata.orgPlan.useGetById(
    {
      params: {
        orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const queries = useMemo(
    () => ({
      roles: rolesQuery,
      usersCount: usersCountQuery,
      orgPlan: orgPlanQuery,
    }),
    [rolesQuery, usersCountQuery, orgPlanQuery],
  );
  return queries;
}

const SetupQueriesContext =
  createQueriesContext<ReturnType<typeof useSetupQueries>>();

const Wrapped: FC<FormProps> = ({
  form,
  verifiedEmailsCount,
  resetVerifiedEmailsCount,
  handleFinishForm,
}) => {
  const queries = useSetupQueries();
  return (
    <SetupQueriesContext.Provider queries={queries}>
      <Form
        form={form}
        verifiedEmailsCount={verifiedEmailsCount}
        resetVerifiedEmailsCount={resetVerifiedEmailsCount}
        handleFinishForm={handleFinishForm}
      />
    </SetupQueriesContext.Provider>
  );
};

export { EasyForm, Wrapped as Form };
export type { BatchInviteUsersFormValues };
