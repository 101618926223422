import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { SafeStringOptions } from "@chatbotgang/etude/string/safeString";
import { safeString } from "@chatbotgang/etude/string/safeString";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import type { InputProps as AntInputProps, InputRef } from "antd";
import { Input as AntInput } from "antd";
/* eslint-disable-next-line no-restricted-imports -- Non-exported from antd */
import type { TextAreaProps, TextAreaRef } from "antd/es/input/TextArea";
import { flow, merge } from "lodash-es";
import { resolveOnChange } from "rc-input/es/utils/commonUtils";
import type { ChangeEventHandler, ComponentProps, ElementRef } from "react";
import { useMemo, useState } from "react";

import { CopyButton } from "@/components/Button/CopyButton";
import { shouldNotForwardProps } from "@/shared/utils/style/shouldNotForwardProps";

interface CustomInputProps {
  $noSpinner?: boolean;
  safeString?: Partial<SafeStringOptions> | false;
  preSafeString?: (value: string) => string;
  postSafeString?: (value: string) => string;
}

const doNothing = (value: string) => value;

type InputProps = AntInputProps & CustomInputProps;

const inputCss = css`
  &:not(
      .ant-input-affix-wrapper-disabled,
      .ant-input-affix-wrapper-borderless,
      .ant-input-status-error,
      .ant-input-status-warning
    ) {
    border-color: ${theme.colors.neutral003};
  }

  &.ant-input-affix-wrapper:focus,
  &.ant-input-affix-wrapper-focused {
    &:not(
        .ant-input-affix-wrapper-disabled,
        .ant-input-affix-wrapper-borderless,
        .ant-input-status-error,
        .ant-input-status-warning
      ) {
      border-color: ${theme.colors.primaryHover};
      box-shadow: 0 0 0 2px ${theme.colors.primaryOutline};
    }
  }

  &.ant-input-affix-wrapper:not(
      .ant-input-affix-wrapper-disabled,
      .ant-input-status-error,
      .ant-input-status-warning
    ):hover {
    border-color: ${theme.colors.primaryHover};
  }

  &.ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled,
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper,
  &.ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled,
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper:hover {
    border-color: ${theme.colors.errorHover};
  }

  .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled,
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled,
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper-focused {
    border-color: ${theme.colors.errorHover};
    box-shadow: 0 0 0 2px ${theme.colors.errorOutline};
  }

  .ant-input-group-addon {
    background: transparent;
  }

  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &.ant-input:hover,
  &.ant-input:focus,
  &.ant-input-focused {
    border-color: ${theme.colors.primaryHover};
  }
`;

const defaultSafeStringOptions: SafeStringOptions = {
  allowMultipleSpaces: true,
  deburr: false,
  trim: true,
};

const StyledInput = styled(AntInput, {
  shouldForwardProp: shouldNotForwardProps(["$noSpinner"]),
})<{ $noSpinner?: boolean }>`
  ${inputCss}

  ${(props) =>
    props.$noSpinner &&
    props.type === "number" &&
    css`
      /*
       * Add-on styles for cross-browser consistence for Ant input
      */

      /* Chrome, Safari, Edge, Opera */
      input.ant-input[type="number"]::-webkit-outer-spin-button,
      input.ant-input[type="number"]::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
      }

      /* Firefox */
      input.ant-input[type="number"] {
        appearance: textfield;
      }
    `}
`;

type InputTextAreaProps = ComponentProps<typeof AntInput.TextArea> & {
  safeString?: Partial<SafeStringOptions> | false;
  preSafeString?: (value: string) => string;
  postSafeString?: (value: string) => string;
};

/**
 * Use this component instead of Ant Design TextArea.
 *
 * You can also use it with 'Input.TextArea'.
 *
 * @see {@link Input.TextArea}
 *
 * It will automatically format the input value to be safe when blurred.
 *
 * You can configure the safe string options by passing a partial of
 * `SafeStringOptions` to the 'safeString' prop.
 *
 * @see {@link SafeStringOptions}
 */
const InputTextArea = styled(
  forwardRef<TextAreaRef, InputTextAreaProps>(function InputTextArea(
    {
      safeString: safeStringOptions,
      preSafeString = doNothing,
      postSafeString = doNothing,
      ...props
    },
    ref,
  ) {
    const onBlurCapture = useHandler<
      ComponentProps<typeof AntInput.TextArea>["onBlurCapture"]
    >((e) => {
      props.onBlurCapture?.(e);
      if (e.defaultPrevented) return;

      if (!props.onChange) return;

      if (typeof props.value !== "string") return;

      if (safeStringOptions === false) return;

      // Start safe string
      const mergedSafeStringOptions: SafeStringOptions = merge(
        {},
        {
          ...defaultSafeStringOptions,
          trim: false,
        },
        safeStringOptions,
      );
      const value = props.value;
      const formattedString = flow(
        () => value,
        preSafeString,
        (input) => safeString(input, mergedSafeStringOptions),
        postSafeString,
      )();
      if (formattedString === value) return;

      resolveOnChange(e.target, e, props.onChange, formattedString);
    });

    return (
      <AntInput.TextArea ref={ref} {...props} onBlurCapture={onBlurCapture} />
    );
  }),
)`
  ${inputCss}
`;

const InputPassword = styled(AntInput.Password)`
  ${inputCss}
`;

/**
 * Use this styled component instead of Ant Design Input.
 *
 * It will automatically format the input value to be safe when blurred.
 *
 * You can configure the safe string options by passing a partial of
 * `SafeStringOptions` to the 'safeString' prop.
 *
 * @see {@link SafeStringOptions}
 */
const Input = Object.assign(
  forwardRef<InputRef, InputProps>(function Input(
    {
      safeString: safeStringOptions,
      preSafeString = doNothing,
      postSafeString = doNothing,
      ...props
    },
    ref,
  ) {
    const onBlurCapture = useHandler<AntInputProps["onBlurCapture"]>(
      function onBlurCapture(e) {
        props.onBlurCapture?.(e);
        if (e.defaultPrevented) return;

        if (!props.onChange) return;

        if (typeof props.value !== "string") return;

        if (safeStringOptions === false) return;

        // Don't format password input by default
        if (props.type === "password" && safeStringOptions === undefined)
          return;

        // Start safe string
        const mergedSafeStringOptions: SafeStringOptions = merge(
          {},
          defaultSafeStringOptions,
          safeStringOptions,
        );
        const value = props.value;
        const formattedString = flow(
          () => value,
          preSafeString,
          (input) => safeString(input, mergedSafeStringOptions),
          postSafeString,
        )();
        if (formattedString === props.value) return;

        resolveOnChange(e.target, e, props.onChange, formattedString);
      },
    );
    return <StyledInput {...props} onBlurCapture={onBlurCapture} ref={ref} />;
  }),
  {
    TextArea: InputTextArea,
    Password: InputPassword,
  },
);

interface FileInputProps extends ComponentProps<"input"> {
  accept?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const FileInput = memo(
  forwardRef<HTMLInputElement, FileInputProps>(function FileInput(
    { accept, onChange, ...props },
    ref,
  ) {
    const [value, setValue] = useState("");
    return (
      <input
        {...props}
        type="file"
        accept={accept}
        value={value}
        onChange={(e) => {
          onChange?.(e);
          setValue("");
        }}
        ref={ref}
      />
    );
  }),
);

const CopyInput = memo(
  forwardRef<ElementRef<typeof Input>, InputProps>(
    function CopyInput(inputProps, ref) {
      const text = useMemo(() => {
        if (inputProps.value === undefined) return "";
        if (typeof inputProps.value === "string") return inputProps.value;
        return JSON.stringify(inputProps.value);
      }, [inputProps.value]);
      return (
        <Input
          readOnly
          {...inputProps}
          suffix={<CopyButton text={text} size={14} iconSize={14} />}
          ref={ref}
        />
      );
    },
  ),
);

const HighlightedCopyInput = styled(CopyInput)`
  --input-color: ${theme.colors.neutral010};
  --background-color: ${theme.colors.neutral001};

  &.ant-input-affix-wrapper > input.ant-input {
    color: var(--input-color);
  }

  border-color: transparent;
  background: var(--background-color);
  color: var(--input-color);

  .ant-input {
    background: var(--background-color);
  }
`;

export {
  CopyInput,
  FileInput,
  HighlightedCopyInput,
  Input,
  InputPassword,
  InputTextArea,
};
export type {
  FileInputProps,
  InputProps,
  InputRef,
  TextAreaProps,
  TextAreaRef,
};
