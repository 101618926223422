import { CloseOutlined } from "@ant-design/icons";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import type { InputRef } from "antd";
import type { Ref } from "react";
import { useTranslation } from "react-i18next";

import type { NarrowIconButtonProps } from "@/components/Button/NarrowIconButton";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import type { InputProps } from "@/components/Input";
import { Input } from "@/components/Input";
import { ArrowDownIcon } from "@/shared/icons/common/ArrowDown";
import { ArrowUpIcon } from "@/shared/icons/common/ArrowUp";

const SearchInput = styled(Input)`
  &:is(input),
  .ant-input {
    padding: 4px 8px;
    border-bottom-left-radius: ${theme.shape.borderRadius};
    border-top-left-radius: ${theme.shape.borderRadius};
    font-size: 12px;
    line-height: 14px;
  }

  .ant-input-group-addon {
    border-width: 0;
    background-color: ${theme.colors.neutral002};
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: ${theme.colors.neutral009};
  }
`;

const IconButton = styled(NarrowIconButton)`
  color: ${theme.colors.neutral005};
`;

const MessageSearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  border-bottom: 1px solid ${theme.colors.neutral003};
  gap: 8px;
  justify-items: center;
`;

export type SearchBarUiProps = {
  value?: string;
  onChange?: InputProps["onChange"];
  searchInputProps?: Omit<InputProps, "value" | "onChange"> & {
    ref?: Ref<InputRef>;
  };
  index: number;
  onIndexChange: (index: number) => void;
  totalCount: number | undefined;
  onClose: NarrowIconButtonProps["onClick"];
  reverse?: boolean;
  cycle?: boolean;
};

export const SearchBarUi = memo(function SearchBarUi({
  value,
  onChange,
  searchInputProps,
  index,
  onIndexChange,
  totalCount,
  onClose,
  reverse,
  cycle,
}: SearchBarUiProps) {
  const { t } = useTranslation();
  const mergeFormDisabled = useMergeFormDisabled();
  const lastIndex =
    totalCount === undefined ? 0 : totalCount < 1 ? 0 : totalCount - 1;
  function getValidIndex(index: number): number {
    const validIndex =
      totalCount === undefined
        ? 0
        : totalCount === 0
          ? 0
          : index > lastIndex
            ? cycle
              ? 0
              : lastIndex
            : index < 0
              ? cycle
                ? lastIndex
                : 0
              : index;
    return validIndex;
  }

  const unsafePreviousIndex = index + (reverse ? 1 : -1);

  const validPreviousIndex = getValidIndex(unsafePreviousIndex);

  const canGoPrevious =
    totalCount !== undefined && index !== validPreviousIndex;

  const goToPrevious = useHandler(() => {
    if (!onIndexChange) return;
    if (!canGoPrevious) return;
    onIndexChange(validPreviousIndex);
  });

  const unsafeNextIndex = index + (reverse ? -1 : 1);
  const validNextIndex = getValidIndex(unsafeNextIndex);
  const canGoNext = totalCount !== undefined && index !== validNextIndex;
  const goToNext = useHandler(() => {
    if (!onIndexChange) return;
    if (!canGoNext) return;
    onIndexChange(validNextIndex);
  });

  const shouldHideNavigateButton = totalCount === 0;
  const shouldDisabledNavigateButton = totalCount === undefined;

  return (
    <MessageSearchBarContainer>
      <SearchInput
        data-test="search-input"
        value={value}
        onChange={onChange}
        placeholder={t("chat.searchBar.placeholder")}
        addonAfter={
          totalCount === undefined
            ? undefined
            : totalCount === 0
              ? t("chat.searchBar.result", { count: 0 })
              : `${index + 1}/${totalCount}`
        }
        {...searchInputProps}
      />
      {shouldHideNavigateButton ? null : (
        <IconButton
          data-test="previous-btn"
          iconSize={10}
          disabled={mergeFormDisabled(
            shouldDisabledNavigateButton || !canGoPrevious,
          )}
          onClick={goToPrevious}
          icon={<ArrowUpIcon />}
        />
      )}
      {shouldHideNavigateButton ? null : (
        <IconButton
          data-test="next-btn"
          iconSize={10}
          disabled={mergeFormDisabled(
            shouldDisabledNavigateButton || !canGoNext,
          )}
          onClick={goToNext}
          icon={<ArrowDownIcon />}
        />
      )}
      <IconButton
        data-test="close-btn"
        iconSize={14}
        onClick={onClose}
        icon={<CloseOutlined />}
      />
    </MessageSearchBarContainer>
  );
});
