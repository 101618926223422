import { createStack } from "@zeffiroso/utils/react/createStack";
import type { FC } from "react";
import { useBlocker as reactRouterDom_useBlocker } from "react-router-dom";

import { BlockerUi } from "@/components/Form/BlockerUi";

const blockerStack = createStack<boolean>();

/**
 * If you want to block the navigation, you can use this hook.
 */
function useBlocker(block: boolean = true) {
  blockerStack.useItem(block);
}

/**
 * If you want to block the navigation, you can use this component.
 */
const Blocker: FC<{
  block?: boolean;
}> = ({ block = true }) => {
  useBlocker(block);
  return null;
};

const RouterBlocker: FC = () => {
  const blocker = reactRouterDom_useBlocker(
    ({ currentLocation, nextLocation }) =>
      blockerStack.useStore.getState().stack.filter((item) => item.value)
        .length > 0 && currentLocation.pathname !== nextLocation.pathname,
  );
  if (blocker.state !== "blocked") return null;
  return (
    <BlockerUi
      open
      onCancel={(e) => {
        if (e.defaultPrevented) return;
        blocker.reset();
      }}
      onOk={(e) => {
        if (e.defaultPrevented) return;
        blocker.proceed();
      }}
    />
  );
};

export { Blocker, RouterBlocker, useBlocker };
