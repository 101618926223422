import { memo } from "@zeffiroso/utils/react/memo";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";

export const TeamRoutingRuleTypeLabel = memo(function TeamRoutingRuleTypeLabel({
  type,
}: {
  type: CantataTypes["Team"]["routingRule"];
}) {
  if (type === "manual") return <Trans i18nKey="team.listPage.manual" />;

  if (type === "by-queue") return <Trans i18nKey="team.listPage.byQueue" />;

  const shouldBeNever: never = type;
  return shouldBeNever;
});
