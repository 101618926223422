import { memo } from "@zeffiroso/utils/react/memo";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";

export const AutoAssignmentRuleConditionOperatorLabel = memo<{
  operator: CantataTypes["AutoAssignmentRuleCondition"]["operator"];
}>(function AutoAssignmentRuleConditionOperatorLabel({ operator }) {
  if (operator === "contain-all-of")
    return <Trans i18nKey="assignment.containsAllOf" />;

  if (operator === "contain-one-of")
    return <Trans i18nKey="assignment.containsOneOf" />;

  if (operator === "none") return null;

  const shouldBeNever: never = operator;
  return shouldBeNever;
});
