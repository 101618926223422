import { css } from "@emotion/react";
import { memo } from "@zeffiroso/utils/react/memo";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { FormItem } from "@/components/Form";
import { Input } from "@/components/Input";
import { BoldText } from "@/components/Typography";
import { usePageInfo } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/usePageInfo";

export const NameField = memo(function NameField() {
  const { t } = useTranslation();
  const { isUpdating, autoAssignmentRuleId } = usePageInfo();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const autoAssignmentRulesQuery = cantata.autoAssignment.useList({
    params: {
      orgId,
    },
  });
  return (
    <FormItem
      name="name"
      label={<BoldText>{t("assignment.ruleName")}</BoldText>}
      css={css`
        width: 336px;
        max-width: 100%;
      `}
      rules={[
        {
          required: true,
          message: t("assignment.ruleName.placeholder"),
        },
        {
          validator: async (_rule, value) => {
            if (!autoAssignmentRulesQuery.isSuccess) return;

            const isNameDuplicated =
              autoAssignmentRulesQuery.data.autoAssignmentRules.some((rule) =>
                isUpdating
                  ? rule.id !== autoAssignmentRuleId && rule.name === value
                  : rule.name === value,
              );
            if (isNameDuplicated) throw new Error("duplicated");
          },
          message: t("assignment.autoAssignmentRule.edit.error.nameConflict"),
        },
      ]}
    >
      <Input
        maxLength={255}
        disabled={!autoAssignmentRulesQuery.isSuccess || undefined}
      />
    </FormItem>
  );
});
