import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { QueryKey } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { injectAffectedApis } from "@zeffiroso/zodios/react";
import { safeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import { Zodios, type ZodiosEndpointDefinitions } from "@zodios/core";
import type { Narrow } from "@zodios/core/lib/utils.types";
import { ZodiosHooks } from "@zodios/react";

import { cantataAxios } from "@/lib/httpClient";

function createUtils(name: string) {
  const queryKey: QueryKey = [
    {
      api: name,
    },
  ];

  const useInvalidateQueries = () => {
    const queryClient = useQueryClient();

    const invalidateQueries = useHandler(async function invalidateQueries() {
      await safeInvalidateQuery(queryClient, {
        queryKey,
      });
    });

    return invalidateQueries;
  };

  return {
    queryKey,
    useInvalidateQueries,
  };
}

function createApi<Api extends ZodiosEndpointDefinitions>(
  name: string,
  api: Narrow<Api>,
) {
  const client = new Zodios(api, {
    axiosInstance: cantataAxios,
  });
  const hooks = new ZodiosHooks(name, client);
  injectAffectedApis(hooks, [name]);

  const hooksFree = new ZodiosHooks(name, client);

  const utils = createUtils(name);

  return {
    client,
    hooks,
    hooksFree,
    utils,
  };
}

export { createApi };
