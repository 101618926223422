import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import { memo } from "@zeffiroso/utils/react/memo";
import { pick } from "lodash-es";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import type { KeywordsSelectorProps } from "@/routes/Settings/Assignment/pages/RoutingRules/components/KeywordsSelector";
import { KeywordsSelector as OriginalKeywordsSelector } from "@/routes/Settings/Assignment/pages/RoutingRules/components/KeywordsSelector";
import {
  cssColumnField,
  maxFieldWidthCssVariable,
} from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/style";
import type { FormValues } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/type";

type ValueType = (FormValues["conditions"][number] & {
  category: "keywords";
})["keywords"];

const styles = {
  selector: css([
    cssColumnField,
    {
      width: `var(${maxFieldWidthCssVariable})`,
    },
  ]),
} satisfies Record<string, SerializedStyles>;

export const KeywordsSelector = Object.assign(
  memo(function KeywordsSelector({
    value,
    onChange,
    ...props
  }: {
    value?: ValueType;
    onChange?: (value: ValueType) => void;
  } & Omit<KeywordsSelectorProps, "orgId" | "value" | "onChange">) {
    const orgId = useActiveOrgIdStore((state) => state.value);
    return (
      <OriginalKeywordsSelector
        orgId={orgId}
        value={!value ? value : value.map((item) => item.id)}
        onChange={
          !onChange
            ? onChange
            : (value) => onChange(value.map((id) => ({ id })))
        }
        css={styles.selector}
        {...props}
      />
    );
  }),
  pick(OriginalKeywordsSelector, ["rules"]),
);
