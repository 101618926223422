import { type FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import type { CantataTypes } from "@/cantata/types";
import { fakeT } from "@/shared/g11n/fakeT";

/**
 * The record of role type translation key.
 *
 * If the role type is not in this record, the role name will be used.
 */
const roleTypeTranslationKeyRecord = (() => {
  const t = fakeT;
  const record: Record<
    Omit<CantataTypes["Role"]["type"], "custom"> & string,
    string
  > = {
    owner: t("glossary.owner"),
    primary_admin: t("glossary.primaryAdmin"),
    admin: t("glossary.admin"),
    primary_agent: t("glossary.primaryAgent"),
    agent: t("glossary.agent"),
  };
  return record;
})();

/**
 * Get the role name.
 *
 * A hook that returns a function that gets the role name.
 */
function useGetRoleName() {
  const { t } = useTranslation();
  const getRoleName: (
    role: Pick<CantataTypes["Role"], "type" | "name">,
  ) => string = useCallback(
    function getRoleName(role) {
      return role.type in roleTypeTranslationKeyRecord
        ? t(roleTypeTranslationKeyRecord[role.type])
        : role.name || EMPTY_STRING_PLACEHOLDER;
    },
    [t],
  );
  return getRoleName;
}

namespace RoleName {
  export type Props = {
    role: Pick<CantataTypes["Role"], "type" | "name">;
  };
}

const RoleNameInternal: FC<RoleName.Props> = ({ role }) => {
  const getRoleName = useGetRoleName();
  const roleName = useMemo(() => getRoleName(role), [getRoleName, role]);
  return roleName;
};

/**
 * The role name.
 */
const RoleName = Object.assign(RoleNameInternal, {
  roleTypeTranslationKeyRecord,
  useGetRoleName,
});

export { RoleName };
