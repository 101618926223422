import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { isThisYear, isToday } from "date-fns";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { RANGE_STRING_JOINER } from "@/appConstant";
import { Tooltip } from "@/components/Tooltip";
import { useGetIntlDateTimeFormatter } from "@/resources/datetime";
import { useConversation } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/Context";

const formatTimeOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

const Time = memo(function Time() {
  const { t } = useTranslation();
  const conversation = useConversation();
  const getIntlDateTimeFormatter = useGetIntlDateTimeFormatter();
  const formattedCreatedAt = useMemo(
    () =>
      getIntlDateTimeFormatter(formatTimeOptions).format(
        conversation.createdAt,
      ),
    [getIntlDateTimeFormatter, conversation.createdAt],
  );
  const formattedEndedAt = useMemo(
    () =>
      conversation.endedAt
        ? getIntlDateTimeFormatter(formatTimeOptions).format(
            conversation.endedAt,
          )
        : undefined,
    [getIntlDateTimeFormatter, conversation.endedAt],
  );
  const title = useMemo<ComponentProps<typeof Tooltip>["title"]>(
    function declareTitle() {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          `}
        >
          <div>{formattedCreatedAt}</div>
          {conversation.endedAt && (
            <>
              <div>|</div>
              <div>{formattedEndedAt}</div>
            </>
          )}
        </div>
      );
    },
    [conversation.endedAt, formattedCreatedAt, formattedEndedAt],
  );
  const formatTime = useCallback(
    function formatTime(date: Date): string {
      if (isToday(date)) return t("glossary.today");
      const formatter = getIntlDateTimeFormatter({
        month: "short",
        day: "numeric",
        ...(isThisYear(date) ? {} : { year: "numeric" }),
      });
      return formatter.format(date);
    },
    [getIntlDateTimeFormatter, t],
  );
  const children = useMemo<ComponentProps<typeof Tooltip>["children"]>(
    function declareChildren() {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: ${theme.colors.neutral007};
            font-size: 0.75rem;
          `}
        >
          {formatTime(conversation.createdAt) +
            (!conversation.endedAt
              ? ""
              : RANGE_STRING_JOINER + formatTime(conversation.endedAt))}
        </div>
      );
    },
    [conversation.createdAt, conversation.endedAt, formatTime],
  );
  return <Tooltip title={title}>{children}</Tooltip>;
});

export { Time };
