import { css } from "@emotion/react";
import { memo } from "@zeffiroso/utils/react/memo";
import { useTranslation } from "react-i18next";

import { FormItem } from "@/components/Form";
import { Input } from "@/components/Input";
import { BoldText } from "@/components/Typography";

const DESCRIPTION_LENGTH_LIMIT = 100;

const DescriptionField = memo(function DescriptionField() {
  const { t } = useTranslation();

  return (
    <FormItem
      name="description"
      data-test="team-description-field"
      label={<BoldText>{t("team.form.description.label")}</BoldText>}
      css={css`
        width: 336px;
        max-width: 100%;
      `}
      rules={[
        {
          max: DESCRIPTION_LENGTH_LIMIT,
          message: t("validation.maxCharLength", {
            count: DESCRIPTION_LENGTH_LIMIT,
          }),
        },
      ]}
    >
      <Input />
    </FormItem>
  );
});

export { DescriptionField };
