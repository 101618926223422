import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import type { ElementRef, ElementType, ForwardedRef, ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { RANGE_STRING_JOINER } from "@/appConstant";
import { useFormatDateTime } from "@/resources/datetime";

const defaultComponent = "span";

namespace PrizeDatetimeRange {
  export type DefaultRootElement = typeof defaultComponent;
  export interface OwnProps {
    /**
     * The root element of the component.
     */
    component?: ElementType;
    /**
     * The start date of the prize.
     */
    startDate?: Date | null;
    /**
     * The end date of the prize.
     */
    endDate?: Date | null;
  }
  export type Props<TRootElement extends ElementType = DefaultRootElement> =
    Overwrite<ComponentProps<TRootElement>, OwnProps>;
  export interface Type {
    <TRootElement extends ElementType = DefaultRootElement>(
      props: Overwrite<Props<TRootElement>, { component: TRootElement }>,
      ref?: ForwardedRef<ElementRef<TRootElement>>,
    ): ReactNode;
    (
      props: DistributiveOmit<Props, "component">,
      ref?: ForwardedRef<ElementRef<DefaultRootElement>>,
    ): ReactNode;
  }
}

const usePrizeDatetimeRange = (props: PrizeDatetimeRange.Props) => {
  const { t } = useTranslation();
  const formatMessageTimestamp = useFormatDateTime();
  const startDate = useMemo(
    () => (!props.startDate ? null : formatMessageTimestamp(props.startDate)),
    [props.startDate, formatMessageTimestamp],
  );
  const endDate = useMemo(
    () => (!props.endDate ? null : formatMessageTimestamp(props.endDate)),
    [props.endDate, formatMessageTimestamp],
  );

  if (!startDate && !endDate)
    return t("resource.prize.PrizeDatetimeRange.noLimit.label");

  if (startDate && !endDate) {
    return t("resource.prize.PrizeDatetimeRange.onlyStart.label", {
      startDate,
    });
  }

  if (!startDate && endDate) {
    return t("resource.prize.PrizeDatetimeRange.onlyEnd.label", {
      endDate,
    });
  }

  return `${startDate}${RANGE_STRING_JOINER}${endDate}`;
};

const PrizeDatetimeRangeInternal: PrizeDatetimeRange.Type = forwardRef(
  function PrizeDatetimeRangeInternal(
    {
      component: Component = defaultComponent,
      ...props
    }: PrizeDatetimeRange.OwnProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    const message = usePrizeDatetimeRange(props);
    return (
      <Component {...props} ref={ref}>
        {message}
      </Component>
    );
  },
) as unknown as PrizeDatetimeRange.Type;

/**
 * Display the date range of a prize.
 * - [Figma](https://www.figma.com/design/taxRwfDgzLjfhtSzwRtcdW/Chat-Commerce?node-id=8498-27812&node-type=text&m=dev)
 */
const PrizeDatetimeRange = Object.assign(PrizeDatetimeRangeInternal, {
  usePrizeDatetimeRange,
});

assignDisplayName(PrizeDatetimeRange, "PrizeDatetimeRange");

export { defaultComponent, PrizeDatetimeRange };
