import {
  PermissionSchema,
  type PermissionsSchema,
} from "@zeffiroso/cantata/models";

type PermissionName = keyof typeof PermissionsSchema.shape;

function isPermissionName(input: unknown): input is PermissionName {
  return PermissionSchema.safeParse(input).success;
}

export type { PermissionName };

export { isPermissionName };
