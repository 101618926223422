import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@zeffiroso/utils/react/memo";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";

const MemberGenderLabel = memo<{
  gender: CantataTypes["MemberDetail"]["gender"];
}>(function MemberGenderLabel({ gender }) {
  switch (gender) {
    case "male":
      return <Trans i18nKey="chat.male" />;
    case "female":
      return <Trans i18nKey="chat.female" />;
    case "other":
      return <Trans i18nKey="chat.gender.other" />;
    case "unknown":
    case "":
      return <Trans i18nKey="chat.gender.unknown" />;
    default:
      gender satisfies never;
      throw new Error(inspectMessage`Unhandled gender: ${gender}`);
  }
});

export { MemberGenderLabel };
