import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { define } from "@chatbotgang/etude/util/define";
import { API_DEFAULT_LIMIT } from "@zeffiroso/env";
import { memo } from "@zeffiroso/utils/react/memo";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { omit } from "lodash-es";
import { createContext, useContext, useEffect, useMemo } from "react";
import { createWithEqualityFn } from "zustand/traditional";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantataClient } from "@/cantata";
import { useMembersController } from "@/routes/Chat/ui/MembersPanel/controllers/membersController";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { useSelectionController } from "@/routes/Chat/ui/MembersPanel/controllers/selectionController";
import { logError } from "@/shared/application/logger/sentry";

type StoreValue = {
  isLoading: boolean;
  error: null | Error;
  hasNextPage: boolean;
  loadMoreElement: Element | null;
  loadMoreElementInView: boolean;
};

const initialStoreValue: StoreValue = {
  isLoading: false,
  error: null,
  hasNextPage: true,
  loadMoreElement: null,
  loadMoreElementInView: false,
};

function setupController() {
  const useStore = createWithEqualityFn<StoreValue>()(
    () => initialStoreValue,
    shallow,
  );
  function reset() {
    /**
     * We don't reset these values because they are handled by the component
     * ref prop.
     */
    const refRelatedKey = define<Array<keyof StoreValue>>()([
      "loadMoreElement",
    ]);
    useStore.setState(omit(initialStoreValue, refRelatedKey));
  }
  function loadMoreRef(element: Element | null) {
    useStore.setState({
      loadMoreElement: element,
      loadMoreElementInView: false,
    });
  }
  return {
    useStore,
    reset,
    loadMoreRef,
  };
}

const Context = createContext<null | ReturnType<typeof setupController>>(null);

const MembersQueryControllerProvider = memo<
  Omit<ComponentProps<typeof Context.Provider>, "value">
>(function MembersQueryControllerProvider({ children, ...props }) {
  const useMembersQueryController = useMemo(setupController, []);
  return (
    <Context.Provider value={useMembersQueryController} {...props}>
      {children}
    </Context.Provider>
  );
});

function useMembersQueryController() {
  const useMembersQueryController = useContext(Context);
  if (useMembersQueryController === null) {
    throw new Error(
      "useMembersQueryController must be used within MembersQueryControllerProvider",
    );
  }

  return useMembersQueryController;
}

const QueryMembers = memo(function QueryMembers() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channelId = searchController.useStore((state) => state.channelId);
  const membersQueryController = useMembersQueryController();
  const membersController = useMembersController();
  const selectionController = useSelectionController();
  const search = searchController.useStore((state) => state.search);
  const isSearching = searchController.useStore(
    searchController.selectors.isSearching,
  );
  const filter = searchController.useStore((state) => state.filter);

  useEffect(() => {
    let pinned = true;
    let cursor: string | null = null;
    let abortController = new AbortController();
    const tasksToDestroy: Array<() => void> = [];

    const observer = new IntersectionObserver(function (
      intersectionObserverEntryList,
    ) {
      const entry0 = intersectionObserverEntryList[0];
      if (!entry0) return;
      membersQueryController.useStore.setState({
        loadMoreElementInView:
          entry0.isIntersecting || entry0.intersectionRatio > 0,
      });
    });
    tasksToDestroy.push(
      observer.disconnect
        // Prevent TypeError: Illegal invocation
        .bind(observer),
    );

    (function bindObserver() {
      const handler: Parameters<
        typeof membersQueryController.useStore.subscribe
      >[0] = function handler(current, previous) {
        if (current.loadMoreElement === previous.loadMoreElement) return;
        if (previous.loadMoreElement)
          observer.unobserve(previous.loadMoreElement);
        if (current.loadMoreElement) observer.observe(current.loadMoreElement);
      };
      (function firstCall() {
        const element =
          membersQueryController.useStore.getState().loadMoreElement;
        if (!element) return;
        observer.observe(element);
      })();
      const destroy = membersQueryController.useStore.subscribe(handler);
      tasksToDestroy.push(destroy);
    })();

    (function watchLoadMore() {
      const handler: Parameters<
        typeof membersQueryController.useStore.subscribe
      >[0] = function handler(current, previous) {
        if (current.loadMoreElementInView === previous.loadMoreElementInView)
          return;
        if (!current.loadMoreElementInView) return;
        fetchMembers();
      };
      const destroy = membersQueryController.useStore.subscribe(handler);
      tasksToDestroy.push(destroy);
    })();

    /**
     * abort the previous request
     */
    function abort() {
      abortController.abort();
      abortController = new AbortController();
    }
    tasksToDestroy.push(abort);

    /**
     * should fetch members when the list is in view and the list is not loading
     */
    async function fetchMembers() {
      if (
        // prevent load more when the list is loading
        membersQueryController.useStore.getState().isLoading ||
        !membersQueryController.useStore.getState().hasNextPage ||
        !membersQueryController.useStore.getState().loadMoreElementInView
      )
        return;

      membersQueryController.useStore.setState({ isLoading: true });

      /**
       * 1. fetch pinned members util bottom of the list is reached or inView become to false
       * 1. fetch unpinned members util bottom of the list is reached or inView become to false
       * 1. fetch search result if the search query is not empty
       * 1. fetch search result util bottom of the list is reached or inView become to false
       */
      const queries: Parameters<
        typeof cantataClient.member.list
      >[0]["queries"] = {
        ...(!channelId ? null : { channelId }),
        limit: API_DEFAULT_LIMIT,
        // filter with assignmentFilter
        assignmentFilter: filter.assignmentFilter,
        ...(filter.assignmentFilter === "assignee"
          ? { assigneeId: filter.assigneeId }
          : null),
        ...(filter.unread === true
          ? {
              unread: filter.unread,
            }
          : null),
        ...(isSearching
          ? // query with search action and query
            {
              ...(() => {
                const searchQuery = search.query.toString();
                if (!searchQuery) return {};
                return { searchAction: search.action, searchQuery };
              })(),
            }
          : // query with processing state and pinned state
            {
              processingState: filter.processingState,
              pinned,
            }),
        ...(!cursor ? null : { cursor }),
      };

      abort();
      const abortSignal = abortController.signal;

      const result = await safePromise(() =>
        cantataClient.member.list({
          params: {
            orgId,
          },
          queries,
          signal: abortSignal,
        }),
      );

      (function checkResult(): void {
        if (result.isError) {
          if (abortSignal.aborted) return;

          if (!(result.error instanceof Error)) {
            const unexpectedError = new Error(
              `Unexpected error type: ${result.error}`,
            );
            unexpectedError.cause = result.error;
            logError(unexpectedError);
            membersQueryController.useStore.setState({
              error: unexpectedError,
              hasNextPage: false,
            });
            return;
          }

          membersQueryController.useStore.setState({
            error: result.error,
            hasNextPage: false,
          });
          return;
        }

        membersController.addMembers(result.data.members);
        if (
          selectionController.useStore
            // Use `getState` instead of hooks to prevent triggering `useEffect`.
            .getState().isSelecting
        )
          selectionController.addSelectionMembers(result.data.members);

        /**
         * unpinned has no more members, set hasNextPage to false
         */
        if (!pinned && result.data.cursor.after === null) {
          membersQueryController.useStore.setState({ hasNextPage: false });
          cursor = null;
          return;
        }

        /**
         * pinned has no more members, fetch unpinned members
         */
        if (pinned && result.data.cursor.after === null) pinned = false;

        membersQueryController.useStore.setState({ hasNextPage: true });
        cursor = result.data.cursor.after;
      })();

      membersQueryController.useStore.setState({ isLoading: false });

      fetchMembers();
    }

    return function cleanUp() {
      tasksToDestroy.forEach((task) => {
        task();
      });
      membersController.reset();
      selectionController.reset();
      membersQueryController.reset();
    };
  }, [
    channelId,
    filter,
    isSearching,
    membersController,
    membersQueryController,
    orgId,
    search.action,
    search.query,
    selectionController,
  ]);
  return null;
});

export {
  MembersQueryControllerProvider,
  QueryMembers,
  useMembersQueryController,
};
