import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import useChange from "@react-hook/change";
import type { LegatoOnEventTypes } from "@zeffiroso/legato/on";
import { memo } from "@zeffiroso/utils/react/memo";
import { HighResDate } from "@zeffiroso/utils/zod/Iso8601HighResDateSchema";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { secondsToMilliseconds } from "date-fns";
import { omit, uniqBy } from "lodash-es";
import { createContext, useContext, useEffect, useMemo } from "react";
import { createWithEqualityFn } from "zustand/traditional";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { useLegatoEvent } from "@/legato";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { useInvalidateCountQueries } from "@/routes/Chat/ui/MembersPanel/countQueriesHooks";
import { sortByPinnedAndLastMessageAtDesc } from "@/routes/Chat/ui/MembersPanel/sortMembers";
import { useCheckShouldInList } from "@/routes/Chat/ui/MembersPanel/useCheckShouldInList";

/**
 * The cache will be stored in cache for `TRIGGERED_TIME_CACHE_EXPIRE_TIME_MS` ~
 * `TRIGGERED_TIME_CACHE_EXPIRE_TIME_MS` +
 * `TRIGGERED_TIME_CACHE_EXPIRE_CHECK_INTERVAL_MS` ms
 */
const TRIGGERED_TIME_CACHE_EXPIRE_CHECK_INTERVAL_MS = secondsToMilliseconds(10);
const TRIGGERED_TIME_CACHE_EXPIRE_TIME_MS = secondsToMilliseconds(10);

/**
 * Because event trigger time is not accurate, we need to cache the triggered
 * time and compare it with the event trigger time to avoid wrong update.
 */
function setupTriggeredTimeCacheController() {
  const map: Map<
    CantataTypes["Member"]["id"],
    {
      triggeredTime: HighResDate;
      cacheTime: Date;
    }
  > = new Map();
  function clearOutdatedCache() {
    const now = Date.now();
    for (const [key, { cacheTime }] of map.entries()) {
      if (now - cacheTime.getTime() > TRIGGERED_TIME_CACHE_EXPIRE_TIME_MS)
        map.delete(key);
    }
  }
  let interval: ReturnType<typeof setInterval> | null = null;
  function clear() {
    if (!interval) return;
    clearInterval(interval);
    interval = null;
  }
  function setup() {
    clear();
    interval = setInterval(
      clearOutdatedCache,
      TRIGGERED_TIME_CACHE_EXPIRE_CHECK_INTERVAL_MS,
    );
  }
  /**
   * Setup `useEffect`.
   */
  function useTriggeredTimeEffect() {
    useEffect(() => {
      setup();
      return clear;
    }, []);
  }

  /**
   * Validate the event and return true if the event is not outdated.
   */
  function validate(
    memberId: CantataTypes["Member"]["id"],
    e: Pick<LegatoOnEventTypes["member-list-updated"], "triggerTime">,
  ): boolean {
    const now = new Date();
    const cache = map.get(memberId);
    if (cache && HighResDate.compare(cache.triggeredTime, e.triggerTime) >= 0)
      return false;

    map.set(memberId, {
      triggeredTime: e.triggerTime,
      cacheTime: now,
    });
    return true;
  }
  const triggeredTimeCacheController = {
    useTriggeredTimeEffect,
    validate,
  };
  return triggeredTimeCacheController;
}

const UpdatedMembersFromLegato = memo(function UpdatedMembersFromLegato() {
  const membersController = useMembersController();
  const invalidateCountQueries = useInvalidateCountQueries();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channelId = searchController.useStore((state) => state.channelId);
  const myTeamsQuery = cantata.team.useListMine(
    {
      params: {
        orgId,
      },
    },
    {
      useErrorBoundary: true,
      suspense: true,
    },
  );

  const currentMemberId = memberIdUtils.useGet();
  const currentMemberQuery = cantata.member.useGetById(
    {
      params: {
        orgId,
        memberId: currentMemberId,
      },
    },
    {
      enabled: !Number.isNaN(currentMemberId),
    },
  );

  const orgQueriesData = orgQueriesContext.useData();
  const me = orgQueriesData.me;

  const checkShouldInList = useCheckShouldInList();
  const isSearching = searchController.useStore(
    searchController.selectors.isSearching,
  );
  const filter = searchController.useStore((state) => state.filter);

  const triggeredTimeCacheController = useMemo(
    setupTriggeredTimeCacheController,
    [],
  );
  triggeredTimeCacheController.useTriggeredTimeEffect();

  useLegatoEvent("member-list-updated", function updateMemberList(event) {
    if (!myTeamsQuery.isSuccess) return;

    const myTeams = myTeamsQuery.data.teams;

    /**
     * When searching, we only update the member if it is in the list.
     */
    if (isSearching) {
      const currentMembers = membersController.useStore.getState().members;
      const memberInList = currentMembers.find(
        (member) => member.id === event.content.member.id,
      );
      if (!memberInList) return;
      // Update the member if it is in the list
      const nextMember = {
        ...memberInList,
        ...omit(event.content.member, [
          /**
           * Omit this or search will be crashed.
           */
          "matchedMessage",
        ]),
      };
      const nextMembers = currentMembers.map((member) =>
        member.id === nextMember.id ? nextMember : member,
      );
      membersController.useStore.setState({ members: nextMembers });
      return;
    }
    const content = event.content;
    const member = content.member;

    triggeredTimeCacheController.validate(member.id, event);

    const shouldInThisList = checkShouldInList({
      channelId,
      processingState: filter.processingState,
      assignmentFilter: filter.assignmentFilter,
      unread: filter.unread,
      me,
      myTeams,
      member,
    });

    const beforeMembersCount =
      membersController.useStore.getState().members.length;

    if (shouldInThisList) membersController.addMembers([member]);
    else membersController.removeMemberById(member.id);

    const afterMembersCount =
      membersController.useStore.getState().members.length;

    /**
     * If the count of members is changed, we should invalidate the count
     * queries.
     */
    if (beforeMembersCount !== afterMembersCount) invalidateCountQueries();
  });

  useChange(
    currentMemberQuery.data,
    function removeMemberIfShouldNotInThisListWhenLeaveChatRoom(
      _current,
      prev,
    ) {
      if (
        !prev ||
        /**
         * When searching, we don't remove the member if it is in the list.
         * Asana: [Conversation disappeared after searching by name](https://app.asana.com/0/1201173638593204/1207920532281843/f)
         */
        isSearching
      )
        return;
      /**
       * If prev.id !== currentMemberId, it means the previous member is not in
       * the current chat room, which also means leaving the chat room.
       */
      if (prev.id === currentMemberId) return;
      if (!myTeamsQuery.isSuccess) return;
      const myTeams = myTeamsQuery.data.teams;

      const shouldInThisList = checkShouldInList({
        channelId,
        processingState: filter.processingState,
        assignmentFilter: filter.assignmentFilter,
        unread: filter.unread,
        me,
        myTeams,
        member: prev,
      });
      if (!shouldInThisList) {
        membersController.removeMemberById(prev.id);
        invalidateCountQueries();
      }
    },
  );

  return null;
});

type Store = {
  members: Array<CantataTypes["Member"]>;
};

const initialValue: Store = {
  members: [],
};

function setupStore() {
  const useStore = createWithEqualityFn<Store>()(() => initialValue, shallow);

  function addMembers(members: Array<CantataTypes["Member"]>) {
    useStore.setState(({ members: prev }) => ({
      members: uniqBy([...members, ...prev], "id").sort(
        sortByPinnedAndLastMessageAtDesc,
      ),
    }));
  }
  function removeMemberById(memberId: CantataTypes["Member"]["id"]) {
    useStore.setState(({ members }) => ({
      members: members.filter(({ id }) => id !== memberId),
    }));
  }
  function useMembers() {
    return useStore((state) => state.members);
  }
  function reset() {
    useStore.setState(initialValue);
  }
  return {
    useStore,
    useMembers,
    addMembers,
    removeMemberById,
    reset,
  };
}

const Context = createContext<null | ReturnType<typeof setupStore>>(null);

const MembersControllerProvider = memo<
  Omit<ComponentProps<typeof Context.Provider>, "value">
>(function MembersControllerProvider({ children, ...props }) {
  const useMembersController = useMemo(setupStore, []);
  return (
    <Context.Provider value={useMembersController} {...props}>
      <UpdatedMembersFromLegato />
      {children}
    </Context.Provider>
  );
});

function useMembersController() {
  const useMembersController = useContext(Context);
  if (useMembersController === null) {
    throw new Error(
      "useMembersController must be used within MembersControllerProvider",
    );
  }

  return useMembersController;
}

export { MembersControllerProvider, useMembersController };
