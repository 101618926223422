import filenamify from "filenamify/browser";

namespace downloadFile {
  export type Options = {
    obj: Parameters<typeof URL.createObjectURL>[0];
    fileName: string;
  };
}

function downloadFile(options: downloadFile.Options) {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(options.obj);
  a.download = filenamify(options.fileName);
  a.click();
  URL.revokeObjectURL(a.href);
}

export { downloadFile };
