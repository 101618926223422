/**
 * Declare the text area ref context to allow components to access the text area
 * element.
 */

import { memo } from "@zeffiroso/utils/react/memo";
// eslint-disable-next-line no-restricted-imports  -- This is not exported from `antd`.
import type { TextAreaRef } from "antd/es/input/TextArea";
import type { ReactNode, RefObject } from "react";
import { createContext, useContext, useRef } from "react";

const TextAreaRefContext = createContext<RefObject<TextAreaRef> | undefined>(
  undefined,
);

const TextAreaRefProvider = memo(function TextAreaRefProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const textareaRef = useRef<TextAreaRef>(null);
  return (
    <TextAreaRefContext.Provider value={textareaRef}>
      {children}
    </TextAreaRefContext.Provider>
  );
});

function useTextAreaRef() {
  const ref = useContext(TextAreaRefContext);
  if (!ref)
    throw new Error("useTextAreaRef must be used within a TextAreaRefProvider");

  return ref;
}

export { TextAreaRefProvider, useTextAreaRef };
