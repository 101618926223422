import { injectAffectedApis } from "@zeffiroso/zodios/react";

import * as cantataClient from "@/cantata/sdk/client";
import * as cantata from "@/cantata/sdk/hooks";
import * as cantataFree from "@/cantata/sdk/hooksFree";
import * as cantataUtils from "@/cantata/sdk/utils";

/**
 * Define the affected APIs for each API.
 *
 * For example, we can invalidate the cache of the `conversationHistory` API
 * when the `member` mutations are called with the following code:
 *
 * ```ts
 * defineAffectedApis({
 *   member: {
 *     conversationHistory: true,
 *   },
 * });
 * ```
 */
function defineAffectedApis(affects: {
  [K in keyof typeof cantata]?: Partial<
    Record<Exclude<keyof typeof cantata, K>, true>
  >;
}) {
  for (const [k, v] of Object.entries(affects) as Array<
    [keyof typeof affects, (typeof affects)[keyof typeof affects]]
  >) {
    if (!v) continue;
    const affectedApiNames = Object.keys(v) as Array<keyof typeof cantata>;
    injectAffectedApis(
      /**
       * We don't check the type to avoid the following error:
       *
       * ```
       * Type instantiation is excessively deep and possibly infinite.ts(2589)
       * ```
       */
      cantata[k] as never,
      affectedApiNames,
    );
  }
}

export {
  cantata,
  cantataClient,
  cantataFree,
  cantataUtils,
  defineAffectedApis,
};
