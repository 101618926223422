import { memo } from "@zeffiroso/utils/react/memo";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import type { ComponentProps } from "react";
import { createContext, useContext, useMemo } from "react";
import { createWithEqualityFn } from "zustand/traditional";

type Store = {
  result: {
    total: number;
    success: number;
    failed: number;
  } | null;
};

const initialValue: Store = {
  result: null,
};

function setupStore() {
  const useStore = createWithEqualityFn<Store>()(() => initialValue, shallow);

  function reset() {
    useStore.setState(initialValue);
  }

  function useBulkReassignResult() {
    return useStore((store) => store.result);
  }

  function openReportModal(result: NonNullable<Store["result"]>) {
    useStore.setState({ result });
  }

  const closeReportModal = reset;

  return {
    reset,
    useBulkReassignResult,
    openReportModal,
    closeReportModal,
  };
}

const Context = createContext<null | ReturnType<typeof setupStore>>(null);

const BulkReassignControllerProvider = memo<
  Omit<ComponentProps<typeof Context.Provider>, "value">
>(function BulkReassignControllerProvider(props) {
  const useBulkReassignController = useMemo(setupStore, []);
  return <Context.Provider value={useBulkReassignController} {...props} />;
});

function useBulkReassignController() {
  const useBulkReassignController = useContext(Context);
  if (useBulkReassignController === null) {
    throw new Error(
      "useBulkReassignController must be used within BulkReassignController",
    );
  }

  return useBulkReassignController;
}

export { BulkReassignControllerProvider, useBulkReassignController };
