import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { memo } from "@zeffiroso/utils/react/memo";
import { Skeleton } from "antd";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import type { FormProps } from "@/components/Form";
import { Form, FormItem } from "@/components/Form";
import { Input } from "@/components/Input";
import { useMessage } from "@/components/message";

type FormValues = {
  name: string;
};

export const OrgName = memo(function OrgName() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const message = useMessage();
  const query = cantata.org.useGetById({
    params: {
      orgId,
    },
  });

  const mutation = cantata.org.usePartialUpdate(
    {
      params: {
        orgId,
      },
    },
    {
      onSuccess() {
        message.success(t("common.updatedSuccessfully"));
      },
    },
  );

  const handleSubmit = useHandler<FormProps<FormValues>["onFinish"]>(
    function onSubmit(values) {
      const { name } = values;
      if (!query.data || name === query.data.name) return;

      mutation.mutate({ name });
    },
  );

  if (query.isLoading) return <Skeleton.Input />;

  if (query.isError)
    return <Alert type="error" message={query.error.message} />;

  return (
    <Form
      form={form}
      routerPromptOptions={{ disabled: true }}
      initialValues={{ name: query.data.name }}
      disabled={mutation.isLoading}
      onFinish={handleSubmit}
    >
      <FormItem
        name="name"
        rules={[
          {
            required: true,
            message: t("validation.pleaseInputName"),
          },
          {
            max: 20,
            message: t("validation.maxCharLength", { count: 20 }),
          },
        ]}
        style={{ marginBottom: 0 }}
      >
        <Input onBlur={form.submit} />
      </FormItem>
    </Form>
  );
});
