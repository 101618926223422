import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef, ReactNode } from "react";

import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  ResultListItemHeader: css({
    fontSize: "1rem",
    lineHeight: "normal",
    color: theme.colors.neutral009,
  }),
});

namespace ResultListItemHeader {
  export type Ref = ElementRef<"div">;
  interface OwnProps {
    children: ReactNode;
  }
  export type Props = Overwrite<ComponentProps<"div">, OwnProps>;
}

const ResultListItemHeader = forwardRef<
  ResultListItemHeader.Ref,
  ResultListItemHeader.Props
>(function ResultListItemHeader(props, ref) {
  return <div css={styles.ResultListItemHeader} ref={ref} {...props} />;
});

/**
 * A styled `ResultList.Item.Header` component.
 */
assignDisplayName(ResultListItemHeader, "ResultList.Item.Header");

export { ResultListItemHeader };
