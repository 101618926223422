import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import styled from "@emotion/styled";
import { memo } from "@zeffiroso/utils/react/memo";
import type { ComponentPropsWithoutRef, ComponentPropsWithRef } from "react";

const StyledSvg = styled.svg`
  aspect-ratio: 80 / 64;
`;

export type EmptyProps = ComponentPropsWithRef<"svg">;

export const Empty = memo(
  forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
    function Empty(props, ref) {
      return (
        <StyledSvg
          height="1em"
          fill="none"
          viewBox="0 0 80 64"
          {...props}
          ref={ref}
        >
          <path
            fill="#E3EEFF"
            d="M37.589 63.685L74.127 52.18a.676.676 0 00.345-.24.694.694 0 00-.309-1.067l-8.661-3.264-27.913 16.076z"
          ></path>
          <path
            fill="#fff"
            d="M65.392 51.63l-27.68 11.985L9.854 51.63l27.678-17.583 27.86 17.583z"
          ></path>
          <path
            fill="#3B6BEF"
            d="M37.713 63.926a.292.292 0 01-.119-.025L9.734 51.922a.306.306 0 01-.184-.262.316.316 0 01.142-.288l27.68-17.588a.3.3 0 01.322 0l27.86 17.582a.308.308 0 01.144.288.314.314 0 01-.186.262l-27.68 11.985a.315.315 0 01-.119.025zM10.515 51.577l27.198 11.7 27.018-11.699-27.197-17.164-27.019 17.163z"
          ></path>
          <path
            fill="#fff"
            d="M65.327 51.677L37.714 63.615 9.854 51.63l27.68-17.583 27.793 17.629z"
          ></path>
          <path
            fill="#3B6BEF"
            d="M37.713 63.926a.293.293 0 01-.119-.025L9.734 51.922a.306.306 0 01-.184-.262.316.316 0 01.142-.288l27.68-17.589a.301.301 0 01.323 0l27.794 17.63a.308.308 0 01.143.288.314.314 0 01-.185.262L37.833 63.901a.319.319 0 01-.12.025zM10.515 51.577l27.198 11.7 26.953-11.65-27.132-17.213-27.019 17.163z"
          ></path>
          <path
            fill="#fff"
            d="M9.941 22.83l27.625-9.746L37.532 39 9.854 51.63l.087-28.8z"
          ></path>
          <path
            fill="#3B6BEF"
            d="M9.856 51.942a.3.3 0 01-.217-.091.31.31 0 01-.09-.22l.089-28.801a.316.316 0 01.205-.295l27.623-9.744a.302.302 0 01.372.152.317.317 0 01.034.143L37.838 39a.314.314 0 01-.18.283L9.978 51.915a.307.307 0 01-.122.027zm.39-28.887l-.085 28.097L37.227 38.8l.033-25.277-27.014 9.532z"
          ></path>
          <path
            fill="#fff"
            d="M37.47 13.059l27.859 9.772-.088 28.8L37.38 37.63l.089-24.571z"
          ></path>
          <path
            fill="#3B6BEF"
            d="M65.241 51.942a.305.305 0 01-.136-.033l-27.86-14a.307.307 0 01-.17-.28l.089-24.571a.316.316 0 01.128-.254.302.302 0 01.277-.04l27.855 9.772a.308.308 0 01.207.294l-.085 28.797a.313.313 0 01-.305.315zM37.688 37.437l27.248 13.694.087-28.083-27.25-9.553-.085 23.942z"
          ></path>
          <path
            fill="#BAD3FF"
            d="M37.801 34.815l-.089 28.8L9.854 51.63l.087-28.8 27.86 11.984z"
          ></path>
          <path
            fill="#3B6BEF"
            d="M37.713 63.926a.292.292 0 01-.119-.024l-27.86-11.98a.307.307 0 01-.186-.286l.088-28.801a.317.317 0 01.137-.259.303.303 0 01.288-.027l27.86 11.98a.307.307 0 01.186.287l-.088 28.8a.315.315 0 01-.09.219.304.304 0 01-.216.091zm-27.553-12.5l27.249 11.72.086-28.126L10.246 23.3l-.087 28.126z"
          ></path>
          <path
            fill="#BAD3FF"
            d="M37.801 34.815l-.089 28.8L9.854 51.63l.087-28.8 27.86 11.984z"
          ></path>
          <path
            fill="#3B6BEF"
            d="M37.713 63.926a.293.293 0 01-.119-.025L9.734 51.922a.308.308 0 01-.186-.286l.088-28.801a.317.317 0 01.137-.259.303.303 0 01.288-.027l27.86 11.979a.308.308 0 01.186.288l-.088 28.8a.315.315 0 01-.09.219.304.304 0 01-.216.09zm-27.553-12.5l27.249 11.72.086-28.126L10.246 23.3l-.087 28.125z"
          ></path>
          <path
            fill="#3B6BEF"
            d="M37.678 34.884L65.358 22.9l-.09 28.8-27.68 11.985.09-28.8z"
          ></path>
          <path
            fill="#3B6BEF"
            d="M37.382 64l.09-29.26 28.093-12.164-.09 29.26L37.382 64zm.503-28.98l-.087 28.343 27.264-11.806.087-28.34L37.885 35.02z"
          ></path>
          <path
            fill="#3B6BEF"
            d="M37.679 34.884L65.359 22.9l-.09 28.8-27.68 11.985.09-28.8z"
          ></path>
          <path
            fill="#3B6BEF"
            d="M37.382 64l.09-29.26 28.093-12.164-.09 29.26L37.382 64zm.503-28.98l-.087 28.343 27.264-11.806.087-28.34L37.885 35.02z"
          ></path>
          <path
            fill="#E3EEFF"
            d="M57.956 8.344l3.028.884s-.38 2.86-3.72 4.097l-2.51-1.473s2.974-.875 3.202-3.508zM59.096 17.088l3.416 1.263s-1.35 3.347-4.839 4.636l-2.613-1.53s3.799-1.629 4.036-4.37z"
          ></path>
          <path
            fill="#6CA0FF"
            d="M79.084 15.13a.16.16 0 01-.114-.048.165.165 0 01-.047-.116v-1.513a.165.165 0 01.051-.108.16.16 0 01.27.108v1.513a.166.166 0 01-.046.116.158.158 0 01-.114.048z"
          ></path>
          <path
            fill="#6CA0FF"
            d="M79.827 14.373H78.34a.16.16 0 01-.106-.052.165.165 0 010-.223.16.16 0 01.106-.052h1.487a.16.16 0 01.16.098.166.166 0 01-.095.22.16.16 0 01-.065.009zM69.748 16.641a.857.857 0 01-.482-.149.88.88 0 01-.32-.396.899.899 0 01.188-.963.863.863 0 01.946-.192c.159.067.295.18.39.325a.895.895 0 01-.108 1.116.86.86 0 01-.614.26zm0-1.445a.556.556 0 00-.538.668.56.56 0 00.431.438.54.54 0 00.563-.237.566.566 0 00-.07-.703.544.544 0 00-.386-.162v-.004zM13.462 1.856a.158.158 0 01-.114-.048.164.164 0 01-.047-.115V.177a.165.165 0 01.096-.163.158.158 0 01.183.039.165.165 0 01.043.124V1.69a.167.167 0 01-.046.117.16.16 0 01-.115.049z"
          ></path>
          <path
            fill="#6CA0FF"
            d="M14.206 1.1H12.72a.159.159 0 01-.122-.043.164.164 0 01-.039-.186.164.164 0 01.095-.09.159.159 0 01.066-.008h1.486a.159.159 0 01.122.043.164.164 0 01.038.186.164.164 0 01-.095.09.159.159 0 01-.065.008zM19.963 9.793a.857.857 0 01-.482-.149.88.88 0 01-.32-.396.898.898 0 01.188-.963.864.864 0 01.946-.192c.159.067.294.18.39.326a.895.895 0 01-.108 1.115.86.86 0 01-.614.26zm0-1.445a.543.543 0 00-.508.345.57.57 0 00.12.61.547.547 0 00.598.122.567.567 0 00.178-.913.546.546 0 00-.388-.164z"
          ></path>
          <path
            fill="#E3EEFF"
            d="M48.194 30.337s-.75-1.225-6.073-.503c-5.324.723-3.763 2.062-6.625 1.375-4.347-1.054-6.533-.305-6.533-.305l8.839 3.915 10.392-4.482z"
          ></path>
          <path
            fill="#E3EEFF"
            d="M37.678 34.884l9.999 13.82 27.048-13.688L65.357 22.9l-27.68 11.984z"
          ></path>
          <path
            fill="#3B6BEF"
            d="M47.673 49.014a.3.3 0 01-.246-.127L37.43 35.07a.312.312 0 01.127-.47l27.68-11.985a.302.302 0 01.36.098l9.367 12.117a.313.313 0 01-.105.47L47.813 48.982a.292.292 0 01-.14.032zm-9.52-13.997l9.616 13.291 26.482-13.4-8.99-11.628-27.109 11.737z"
          ></path>
          <path
            fill="#BAD3FF"
            d="M12.933 24.04v28.554l6.314 2.689V26.729l-6.314-2.689zM21.11 27.524v28.554l3.726 1.587V29.111l-3.726-1.587z"
          ></path>
          <path
            fill="#E3EEFF"
            d="M37.678 34.885l-9.56 12.574L.305 34.395 9.942 22.83l27.736 12.054z"
          ></path>
          <path
            fill="#3B6BEF"
            d="M28.117 47.77a.297.297 0 01-.128-.028L.177 34.677a.307.307 0 01-.17-.215.317.317 0 01.065-.269L9.71 22.63a.304.304 0 01.353-.085L37.797 34.6a.303.303 0 01.176.207.316.316 0 01-.054.27l-9.56 12.573a.303.303 0 01-.242.121zM.798 34.284l27.227 12.789 9.164-12.06-27.16-11.806L.8 34.284z"
          ></path>
        </StyledSvg>
      );
    },
  ),
);
