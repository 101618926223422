import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@zeffiroso/utils/react/memo";
import type { ReactNode } from "react";
import { createContext, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import type { cantataClient } from "@/cantata";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { BarLoading } from "@/components/Loading/BarLoading";
import { assignDraggableId } from "@/routes/QuickTemplate/applications/utils";
import type { QuickTemplateFormValue } from "@/routes/QuickTemplate/ui/type";

const InitialValuesContext = createContext<QuickTemplateFormValue | undefined>(
  undefined,
);

function useInitialValues(): QuickTemplateFormValue {
  const initialValues = useContext(InitialValuesContext);
  if (initialValues === undefined) {
    throw new Error(
      "useInitialValues must be used within InitialValuesProvider",
    );
  }

  return initialValues;
}

const DefaultFormValues = memo(function DefaultFormValues({
  children,
  categoryId,
}: {
  categoryId: number | null;
  children: ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <InitialValuesContext.Provider
      value={{
        id: Number.NaN,
        name: t("quickTemplate.unnamedTemplates"),
        categoryId,
        messages: [],
      }}
    >
      {children}
    </InitialValuesContext.Provider>
  );
});

const GetById = memo(function GetById({
  channelId,
  quickTemplateId,
  children,
}: {
  channelId: number;
  quickTemplateId: number;
  children: ReactNode;
}) {
  const orgId = useActiveOrgIdStore((state) => state.value);

  const query = cantata.quickTemplate.useGetById(
    {
      params: {
        orgId,
        channelId,
        quickTemplateId,
      },
    },
    {
      select: useCallback(
        (
          data: Awaited<ReturnType<typeof cantataClient.quickTemplate.getById>>,
        ) => {
          return {
            id: data.id,
            name: data.name,
            categoryId: data.categoryId,
            messages: data.messages.map(assignDraggableId),
          };
        },
        [],
      ),
    },
  );

  if (query.isLoading) return <BarLoading />;

  if (query.isError) {
    return (
      <Alert
        type="error"
        message={inspectMessage`query error: ${query.error}`}
      />
    );
  }

  return (
    <InitialValuesContext.Provider value={query.data}>
      {children}
    </InitialValuesContext.Provider>
  );
});

const InitialValuesProvider = memo(function InitialValuesProvider({
  channelId,
  categoryId,
  quickTemplateId,
  children,
}: {
  channelId: number;
  categoryId: number | null;
  quickTemplateId: number;
  children: ReactNode;
}) {
  return Number.isNaN(quickTemplateId) ? (
    <DefaultFormValues categoryId={categoryId}>{children}</DefaultFormValues>
  ) : (
    <GetById
      key={quickTemplateId}
      channelId={channelId}
      quickTemplateId={quickTemplateId}
    >
      {children}
    </GetById>
  );
});

export { InitialValuesProvider, useInitialValues };
