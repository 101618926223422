import type { NumberFormatOwnProps } from ".";

/**
 * A map of preset options for the Intl.NumberFormat constructor
 * These are loosely based on the utility functions found under `utils/number`
 * Some sensible defaults have been chosen
 */
const numberFormatPresetMap: Record<
  NonNullable<NumberFormatOwnProps["numberFormatPreset"]>,
  Intl.NumberFormatOptions
> = {
  count: {
    style: "decimal",
  },
  percent: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "percent",
  },
  /**
   * Note: in the existing implementation revenue is just a count with some default options
   * Revenue isn't `currency` as we aren't currently displaying actual currencies in the UI
   */
  revenue: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: "decimal",
  },
  currency: {
    style: "currency",
    minimumFractionDigits: 0,
  },
};

/**
 * Check whether the value should be formatted at all; will return the fallback text if not
 */
const shouldFormatNumber = (value: NumberFormatOwnProps["value"]) => {
  return !Number.isNaN(value) && Number.isFinite(value);
};

export { numberFormatPresetMap, shouldFormatNumber };
