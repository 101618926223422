import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import objectInspect from "object-inspect";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { useFeatureControl } from "@/resources/organization/featureControl";
import { Collapse } from "@/routes/Chat/ui/MemberProfilePanel/Collapse.styled";
import { Engagement } from "@/routes/Chat/ui/MemberProfilePanel/Engagement";
import { GroupMembers } from "@/routes/Chat/ui/MemberProfilePanel/GroupMembersPanel/GroupMembers";
import { GroupMemberTitle } from "@/routes/Chat/ui/MemberProfilePanel/GroupMembersPanel/GroupMembersTitle";
import { Membership } from "@/routes/Chat/ui/MemberProfilePanel/Membership";
import { NamePanel } from "@/routes/Chat/ui/MemberProfilePanel/NamePanel";
import { UnifiedNotes } from "@/routes/Chat/ui/MemberProfilePanel/NotePanel";
import { ProfilePanel } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel";
import { TagPanel } from "@/routes/Chat/ui/MemberProfilePanel/TagPanel";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";

declare namespace MemberProfilePanel {
  export type Props = {
    showCloseDrawerButton: boolean;
    onClose: ComponentProps<typeof NamePanel>["onClose"];
  };
}

const styles = defineStyles({
  MemberProfilePanel: css([
    cssFlexInheritAndFill,
    {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      overflow: "auto",
    },
  ]),
  profile: css({
    display: "flex",
    gap: 12,
    flexDirection: "column",
    padding: 16,
    borderBottom: `1px solid ${theme.colors.neutral003}`,
  }),
});

const MemberProfilePanel: FC<MemberProfilePanel.Props> = ({
  showCloseDrawerButton,
  onClose,
}) => {
  const { t } = useTranslation();
  const member = memberQueriesContext.useMember();
  const enabledCommerceProfile = useFeatureControl(
    "commerceProfileAvailability",
  );

  const items = useMemo<ComponentProps<typeof Collapse>["items"]>(
    function declareItems(): ComponentProps<typeof Collapse>["items"] {
      return (
        [
          ...(member.type !== "line_group"
            ? []
            : [
                {
                  label: <GroupMemberTitle />,
                  children: <GroupMembers />,
                },
              ]),
          ...(!enabledCommerceProfile || member.type === "line_group"
            ? []
            : [
                {
                  label: t("chat.memberProfilePanel.items.membership.label"),
                  children: <Membership />,
                },
              ]),
          {
            label: t("chat.notes"),
            children: <UnifiedNotes />,
          },
          {
            label: t("common.tags"),
            children: <TagPanel />,
          },
          {
            label: t("chat.memberProfilePanel.tabs.engagement.label"),
            children: <Engagement />,
          },
        ] satisfies ComponentProps<typeof Collapse>["items"]
      ).flatMap<NonNullable<ComponentProps<typeof Collapse>["items"]>[number]>(
        (item, index) => ({
          key: typeof item.label === "string" ? item.label : index,
          ...item,
          children: <ErrorBoundary.Alert>{item.children}</ErrorBoundary.Alert>,
        }),
      );
    },
    [enabledCommerceProfile, member.type, t],
  );

  const defaultActiveKey = useMemo<
    ComponentProps<typeof Collapse>["defaultActiveKey"]
  >(
    function getAllKeys() {
      return (items ?? []).map((item) =>
        typeof item.key === "string" || typeof item.key === "number"
          ? item.key
          : item.key === undefined
            ? objectInspect(item)
            : objectInspect(item.key),
      );
    },
    [items],
  );

  return (
    <>
      <div css={styles.profile}>
        <NamePanel
          showCloseDrawerButton={showCloseDrawerButton}
          onClose={onClose}
        />
        <ProfilePanel />
      </div>
      <Collapse defaultActiveKey={defaultActiveKey} items={items} />
    </>
  );
};

const Wrapped: FC<MemberProfilePanel.Props> = (props) => {
  const activeMemberId = memberIdUtils.useGet();
  return (
    <div css={styles.MemberProfilePanel}>
      <ErrorBoundary.Alert fullSize>
        {Number.isNaN(activeMemberId) ? null : (
          <memberQueriesContext.Provider>
            <MemberProfilePanel {...props} />
          </memberQueriesContext.Provider>
        )}
      </ErrorBoundary.Alert>
    </div>
  );
};

assignDisplayName(Wrapped, "MemberProfilePanel");

export { Wrapped as MemberProfilePanel };
