import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { memo } from "@zeffiroso/utils/react/memo";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Form, FormItem } from "@/components/Form";
import { Select } from "@/components/Select";
import { AutoAssignmentRuleConditionOperatorLabel } from "@/routes/Settings/Assignment/pages/RoutingRules/components/condition/AutoAssignmentRuleConditionOperatorLabel";
import { CategorySelector } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/fields/ConditionsField/ConditionField/CategorySelector";
import { KeywordsSelector } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/fields/ConditionsField/ConditionField/KeywordsSelector";
import { DelButton } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/fields/DelButton";
import { createDefaultFormValues } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/InitialValuesProvider";
import { cssColumnField } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/style";
import type {
  AutoAssignmentRuleConditionRequest,
  FormValues,
} from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/type";
import { usePageInfo } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/usePageInfo";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  ConditionFieldCore: css({
    flexDirection: "column",
    gap: 8,
  }),
  inputs: css({
    flexDirection: "row",
    gap: 12,
  }),
  fields: css({
    flexDirection: "row",
    gap: 12,
  }),
});

type ConditionFieldType = FormValues["conditions"][number];

function fallbackToContainOneOfIfIsNoneOperator<
  Operator extends CantataTypes["AutoAssignmentRuleCondition"]["operator"],
>(operator: Operator): Exclude<Operator, "none"> {
  return (operator === "none" ? "contain-one-of" : operator) as Exclude<
    Operator,
    "none"
  >;
}

const ConditionFieldCore: FC<{
  value: ConditionFieldType;
  onChange: (value: ConditionFieldType) => void;
  onDel: () => void;
  index: number;
}> = ({ value, onChange, onDel, index }) => {
  const { t } = useTranslation();
  const pageInfo = usePageInfo();
  const handleCategoryChange = useHandler(
    (category: ConditionFieldType["category"] | undefined) => {
      if (!value || !onChange)
        throw new Error("value and onChange must be provided");

      if (!category) throw new Error("category must be provided");

      if (category === value.category) return;

      if (category === "all-messages") {
        onChange({
          key: value.key,
          category,
          operator: "none",
        });
        return;
      }
      if (category === "keywords") {
        onChange({
          key: value.key,
          category,
          operator: fallbackToContainOneOfIfIsNoneOperator(
            "operator" in value ? value.operator : "contain-all-of",
          ),
          keywords: [],
        });
        return;
      }
      if (category === "tags") {
        onChange({
          key: value.key,
          category,
          operator: fallbackToContainOneOfIfIsNoneOperator(
            "operator" in value ? value.operator : "contain-all-of",
          ),
          tags: [],
        });
        return;
      }
      const shouldBeNever: never = category;
      return shouldBeNever;
    },
  );
  const handleOperatorChange = useHandler(
    (operator: CantataTypes["AutoAssignmentRuleCondition"]["operator"]) => {
      if (!value || !onChange)
        throw new Error("value and onChange must be provided");

      if (!value.category) throw new Error("category must be provided");

      if (operator === value.operator) return;

      if (value.category === "all-messages")
        throw new Error("all-messages category cannot change operator");

      onChange({
        ...value,
        operator: fallbackToContainOneOfIfIsNoneOperator(operator),
      });
    },
  );
  return (
    <Flex css={styles.ConditionFieldCore}>
      <Flex css={styles.inputs}>
        <Flex css={styles.fields}>
          <FormItem
            name={["conditions", index, "category"]}
            rules={[
              {
                required: true,
                message: t("assignment.conditionCategoryRequired"),
              },
            ]}
          >
            <CategorySelector onChange={handleCategoryChange} />
          </FormItem>
          {value.category === null ||
          value.category === "all-messages" ? null : value.category ===
              "keywords" || value.category === "tags" ? (
            <Select<AutoAssignmentRuleConditionRequest["operator"]>
              css={cssColumnField}
              options={[
                {
                  value: "contain-all-of",
                  label: (
                    <AutoAssignmentRuleConditionOperatorLabel operator="contain-all-of" />
                  ),
                },
                {
                  value: "contain-one-of",
                  label: (
                    <AutoAssignmentRuleConditionOperatorLabel operator="contain-one-of" />
                  ),
                },
              ]}
              value={value.operator}
              onChange={handleOperatorChange}
            />
          ) : (
            (() => {
              const shouldBeNever: never = value;
              return shouldBeNever;
            })()
          )}
          {!(value.category === "keywords") ? null : (
            <FormItem
              name={["conditions", index, "keywords"]}
              rules={[KeywordsSelector.rules.required]}
            >
              <KeywordsSelector />
            </FormItem>
          )}
        </Flex>
        {pageInfo.isView || value.category === null ? null : (
          <DelButton onClick={onDel} />
        )}
      </Flex>
      {!(value.category === "keywords") ? null : (
        <Alert
          backgroundVariant="transparent"
          type="primary"
          message={t("assignment.condition.options.keywords.helperText")}
        />
      )}
    </Flex>
  );
};

export const ConditionField = memo(function ConditionField({
  conditions,
  setConditions,
  index,
}: {
  conditions: Array<ConditionFieldType>;
  setConditions: (conditions: Array<ConditionFieldType>) => void;
  index: number;
}) {
  const form = Form.useFormInstance<FormValues>();
  const value = conditions[index];
  const onChange = useHandler((value: ConditionFieldType) => {
    setConditions(
      conditions.map((condition, i) => (i === index ? value : condition)),
    );
  });
  const onDel = useHandler(() => {
    const draft = conditions.filter((_condition, i) => i !== index);
    form.setFieldValue(
      "conditions",
      draft.length > 0 ? draft : createDefaultFormValues().conditions,
    );
  });

  return (
    <ConditionFieldCore
      key={value.key}
      value={value}
      onChange={onChange}
      onDel={onDel}
      index={index}
    />
  );
});
