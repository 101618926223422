import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { delay } from "@chatbotgang/etude/timer/delay";
import type { FormProps } from "antd";
import { useEffect, useRef, useState } from "react";

import { getInternalHooks } from "@/components/Form/createEasyForm";

type HasFieldsErrorOptions<Values = any> = {
  formProps?: FormProps<Values>;
};

/**
 * This is a workaround to get the error state from Ant Design Form because it
 * does not expose the state of them. It's not a really good practice to use
 * React state.
 *
 * @example
 * ```tsx
 * const {
 *   hasFieldsError,
 *   injectFormProps,
 * } = useHasFieldsError({ formProps });
 * return <Form {...injectFormProps} />;
 * ```
 */
export function useHasFieldsError<Values = any>({
  formProps,
}: HasFieldsErrorOptions<Values> = {}) {
  const form = formProps?.form;
  /**
   * This is a workaround to sync state from Ant Design Form because it does not
   * expose the state of them. It's not a really good practice to use React
   * state.
   */
  const [hasFieldsError, setHasFieldsError] = useState(false);
  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return function cleanup() {
      mountedRef.current = false;
    };
  }, []);
  const syncHasFieldsError = useHandler(async function syncHasFieldsError() {
    if (!mountedRef.current) return;
    /**
     * Wait for the next tick to obtain the correct error state. Thanks, React
     * and Ant Design. 💩
     */
    await delay(1);
    if (!mountedRef.current) return;
    setHasFieldsError(
      !form
        ? false
        : form
            .getFieldsError()
            .some((fieldError) => fieldError.errors.length > 0),
    );
  });
  const internalHooks = !form ? form : getInternalHooks(form);
  useEffect(
    function internalWatch() {
      let cancel = false;
      const destroy = internalHooks?.registerWatch(
        function internalSubscription() {
          if (cancel) return;
          syncHasFieldsError();
        },
      );
      return function cleanup() {
        cancel = true;
        destroy?.();
      };
    },
    [internalHooks, syncHasFieldsError],
  );
  useEffect(() => {
    if (form) {
      syncHasFieldsError();
      return;
    }
    setHasFieldsError(false);
  }, [form, syncHasFieldsError]);
  return { hasFieldsError };
}
