import { TagOutlined } from "@ant-design/icons";
import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safeString } from "@chatbotgang/etude/string/safeString";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import type { ReactNode } from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { FormItem } from "@/components/Form";
import { defineRules } from "@/components/Form/defineRules";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import { useMessage } from "@/components/message";
import type { SelectProps } from "@/components/Select";
import { Select } from "@/components/Select";

type OptionType = {
  key: string;
  value: number;
  label: ReactNode;
};

export type KeywordsSelectorProps = Omit<
  SelectProps<Array<number>, OptionType>,
  "options" | "mode"
> & {
  orgId: number;
  allowCreate?: boolean;
};

const rules = defineRules({
  required: {
    validator: async (_, value) => {
      if (!value || value.length === 0)
        return Promise.reject(
          new Error(inspectMessage`value is required, value: ${value}`),
        );
    },
    message: <Trans i18nKey="feature.keywordsSelector.rule.required" />,
  },
} as const);

/**
 * The keyword selector component.
 * @param orgId The organization id.
 * @param allowCreate Whether to allow creating new keyword. default: true.
 * @param props The rest props.
 *
 * You can use `KeywordSelector.rules` for Ant Design Form rules.
 */
export const KeywordsSelector = Object.assign(
  memo<KeywordsSelectorProps>(function KeywordsSelector({
    orgId,
    allowCreate = true,
    ...props
  }) {
    const { t } = useTranslation();
    const query = cantata.keyword.useList({
      params: {
        orgId,
      },
    });
    const [currentSearchValue, setCurrentSearchValue] = useState<string>("");
    const formattedSearchValue = useMemo(
      () => safeString(currentSearchValue, { trim: true }),
      [currentSearchValue],
    );
    const mutation = cantata.keyword.useCreate(
      {
        params: {
          orgId,
        },
      },
      {
        onSuccess(data) {
          message.success(
            t("common.createSuccessfully", {
              keyword: formattedSearchValue,
            }),
          );
          if (!Array.isArray(props.value) || !props.onChange) return;
          props.onChange([...props.value, data.id], []);
        },
        onSettled() {
          setCurrentSearchValue("");
        },
      },
    );
    const message = useMessage();
    const createKeyword = useHandler(function createKeyword() {
      mutation.mutate({ text: formattedSearchValue });
    });

    const showCreateOption = useMemo(() => {
      if (!allowCreate) return false;

      if (!query.isSuccess) return false;

      if (!formattedSearchValue) return false;

      return !query.data.keywords.some(
        (option) => option.text === formattedSearchValue,
      );
    }, [
      allowCreate,
      formattedSearchValue,
      query.data?.keywords,
      query.isSuccess,
    ]);
    const options = useMemo<
      SelectProps<Array<number>, OptionType>["options"]
    >(() => {
      if (!query.isSuccess) return [];

      const createOptions: OptionType[] = !showCreateOption
        ? []
        : [
            {
              key: "create",
              label: (
                <ItemWithIcon
                  startIcon={
                    <TagOutlined style={{ color: theme.colors.neutral006 }} />
                  }
                >
                  {t("feature.keywordsSelector.create.label", {
                    keyword: formattedSearchValue,
                  })}
                </ItemWithIcon>
              ),
              value: Number.NaN,
            },
          ];
      return [
        ...query.data.keywords
          .map((option) => ({
            key: `option-${option.id}`,
            value: option.id,
            label: option.text,
          }))
          .filter((option) =>
            option.label
              .toLowerCase()
              .includes(formattedSearchValue.toLowerCase()),
          ),
        ...createOptions,
      ];
    }, [
      formattedSearchValue,
      query.data?.keywords,
      query.isSuccess,
      showCreateOption,
      t,
    ]);
    const onSearch = useHandler<
      SelectProps<Array<number>, OptionType>["onSearch"]
    >((value) => {
      props.onSearch?.(value);
      setCurrentSearchValue(value);
    });
    const onDropdownVisibleChange = useHandler<
      SelectProps<Array<number>, OptionType>["onDropdownVisibleChange"]
    >((...args) => {
      props.onDropdownVisibleChange?.(...args);
      const [open] = args;
      if (open) return;

      setCurrentSearchValue("");
    });
    const onSelect = useHandler<
      SelectProps<Array<number>, OptionType>["onSelect"]
    >((value, option) => {
      const args: Parameters<
        NonNullable<SelectProps<Array<number>, OptionType>["onSelect"]>
      >[number][] = [value, option];
      props.onSelect?.(
        // Not sure why ts error: A spread argument must either have a tuple type or be passed to a rest parameter.
        // @ts-expect-error -- As above.
        ...args,
      );
      if (!Number.isNaN(value)) {
        // Antd default behavior is to clear the search value when an option is selected.d
        setCurrentSearchValue("");
        return;
      }
      if (option.key === "create") createKeyword();
    });
    const onChange = useHandler<
      SelectProps<Array<number>, OptionType>["onChange"]
    >((value, option) => {
      const nanValuesIndexes = value
        .filter((v) => Number.isNaN(v))
        .map((_, i) => i);
      const nonNanValues = value.filter((v) => !Number.isNaN(v));
      const nonNanOptions = (Array.isArray(option) ? option : [option]).filter(
        (_, i) => !nanValuesIndexes.includes(i),
      );
      props.onChange?.(nonNanValues, nonNanOptions);
    });

    if (query.isLoading) return <Select {...props} loading disabled />;

    if (query.isError) {
      return (
        <FormItem
          help={inspectMessage`query error: ${query.error}`}
          validateStatus="error"
        >
          <Select {...props} loading disabled />
        </FormItem>
      );
    }
    return (
      <Select<Array<number>, OptionType>
        placeholder={t("feature.keywordsSelector.placeholder")}
        {...props}
        mode="multiple"
        options={options}
        filterOption={false}
        onSearch={onSearch}
        disabled={props.disabled || mutation.isLoading || undefined}
        onDropdownVisibleChange={onDropdownVisibleChange}
        onSelect={onSelect}
        onChange={onChange}
      />
    );
  }),
  {
    rules,
  },
);
