/**
 * All things about direct chat in one file.
 */

import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { createZustandContextStore } from "@zeffiroso/utils/react/createZustandContextStore";
import { memo } from "@zeffiroso/utils/react/memo";
import { useZodSearchParams } from "@zeffiroso/utils/react/useZodSearchParams";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { omitBy } from "lodash-es";
import type { ReactNode } from "react";
import { useEffect } from "react";
import { z } from "zod";

import { setActiveOrgId, useActiveOrgIdStore } from "@/activeOrgId/store";
import { createFullInternalUrl } from "@/app/url/createFullInternalUrl";
import { cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { BarLoading } from "@/components/Loading/BarLoading";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import {
  SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_CHANNEL_ID,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_CHANNEL_TYPE,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_MEMBER_ID,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_ORG_UUID,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_CHANNEL_ID,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_MEMBER_ID,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_ORG_ID,
} from "@/routes/Chat/constants";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { logError } from "@/shared/application/logger/sentry";

const searchCategories = ["externalMemberId"] as const;
type SearchCategory = (typeof searchCategories)[number];

const externalMemberSearchParamsSchema = z
  .object({
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_ORG_UUID]: z.string(),
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_CHANNEL_TYPE]:
      z.enum(searchCategories),
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_CHANNEL_ID]: z.string(),
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_MEMBER_ID]: z.string(),
  })
  .passthrough();

const internalMemberSearchParamsSchema = z
  .object({
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_ORG_ID]: z.coerce.number(),
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_CHANNEL_ID]: z.coerce.number(),
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_MEMBER_ID]: z.coerce.number(),
  })
  .passthrough();

function isExternalParams(
  params:
    | z.infer<typeof externalMemberSearchParamsSchema>
    | z.infer<typeof internalMemberSearchParamsSchema>
    | undefined,
): params is z.infer<typeof externalMemberSearchParamsSchema> {
  return Boolean(
    params && SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_MEMBER_ID in params,
  );
}

function isInternalParams(
  params:
    | z.infer<typeof externalMemberSearchParamsSchema>
    | z.infer<typeof internalMemberSearchParamsSchema>
    | undefined,
): params is z.infer<typeof internalMemberSearchParamsSchema> {
  return Boolean(
    params && SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_CHANNEL_ID in params,
  );
}

async function searchInternal(
  params: z.infer<typeof internalMemberSearchParamsSchema>,
  { signal }: { signal?: AbortSignal } = {},
): Promise<
  | {
      org: CantataTypes["Org"];
      channel: CantataTypes["Channel"];
      member: CantataTypes["MemberDetail"];
    }
  | undefined
> {
  signal?.throwIfAborted();
  if (
    !params ||
    !params[SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_CHANNEL_ID] ||
    !params[SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_MEMBER_ID] ||
    !params[SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_ORG_ID]
  )
    return;
  const orgs = (
    await cantataClient.org.list({
      ...(!signal ? undefined : { signal }),
    })
  ).organizations;
  const org = orgs.find(
    (org) => org.id === params[SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_ORG_ID],
  );
  if (!org) return;
  const channel = (
    await cantataClient.channel.list({
      params: {
        orgId: org.id,
      },
      ...(!signal ? undefined : { signal }),
    })
  ).channels.find(
    (channel) =>
      channel.id === params[SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_CHANNEL_ID],
  );
  if (!channel) return;
  const member = await cantataClient.member.getById({
    params: {
      orgId: org.id,
      memberId: params[SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_MEMBER_ID],
    },
    ...(!signal ? undefined : { signal }),
  });
  if (!member) return;
  return {
    org,
    channel,
    member,
  };
}

async function searchExternal(
  params: z.infer<typeof externalMemberSearchParamsSchema>,
  { signal }: { signal?: AbortSignal } = {},
): Promise<
  | {
      org: CantataTypes["Org"];
      channel: CantataTypes["Channel"];
      member: CantataTypes["DirectChatSearchMember"];
    }
  | undefined
> {
  signal?.throwIfAborted();
  const orgs = (await cantataClient.org.list(!signal ? undefined : { signal }))
    .organizations;
  const externalParams = params;
  const org = orgs.find(
    (org) =>
      org.uuid ===
      externalParams[SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_ORG_UUID],
  );
  if (!org) return;
  const channels = (
    await cantataClient.channel.list({
      params: {
        orgId: org.id,
      },
      ...(!signal ? undefined : { signal }),
    })
  ).channels;
  const channel = channels.find(
    (channel) =>
      channel.externalChannelId ===
      externalParams[SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_CHANNEL_ID],
  );
  if (!channel) return;
  const member = (
    await cantataClient.member.directChatSearch(
      {
        channelType: "line",
        externalChannelId: channel.externalChannelId,
        externalMemberId:
          externalParams[SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_MEMBER_ID],
      },
      {
        params: {
          orgId: org.id,
        },
        ...(!signal ? undefined : { signal }),
      },
    )
  ).members[0];
  if (!member) return;
  return {
    org,
    channel,
    member,
  };
}

const {
  Provider: SearchParamFromDirectChatProvider,
  useStore: useSearchParamFromDirectChatStore,
  useUseStore: useUseGetSearchParamFromDirectChatUseStore,
} = createZustandContextStore<{
  search:
    | undefined
    | {
        category: SearchCategory;
        query: string;
      };
}>()(
  () => ({
    search: undefined,
  }),
  shallow,
);

assignDisplayName(
  SearchParamFromDirectChatProvider,
  "SearchParamFromDirectChatProvider",
);

const DirectChatProvider = memo<{ children: ReactNode }>(
  function DirectChatProvider({ children }) {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const channelId = searchController.useStore((state) => state.channelId);
    const activeMemberId = memberIdUtils.useGet();
    const setActiveMemberId = memberIdUtils.useSet();
    const { params, setParams } = useZodSearchParams(
      z.union([
        externalMemberSearchParamsSchema,
        internalMemberSearchParamsSchema,
      ]),
      z.record(z.string(), z.any()),
    );
    const useGetSearchParamFromDirectChatUseStore =
      useUseGetSearchParamFromDirectChatUseStore();
    const updateSearch = useHandler(function updateSearch() {
      if (!isExternalParams(params)) return;
      useGetSearchParamFromDirectChatUseStore.setState({
        search: {
          category: params[SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_CHANNEL_TYPE],
          query: params[SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_MEMBER_ID],
        },
      });
    });
    const clearParams = useHandler(function clearParams() {
      if (!params) return;
      if (isExternalParams(params)) {
        const draftParams = omitBy(params, (_value, key) =>
          Object.keys(externalMemberSearchParamsSchema.shape).includes(key),
        );
        setParams(draftParams, undefined, {
          replace: true,
        });
      } else if (isInternalParams(params)) {
        const draftParams = omitBy(params, (_value, key) =>
          Object.keys(internalMemberSearchParamsSchema.shape).includes(key),
        );
        setParams(draftParams, undefined, {
          replace: true,
        });
      } else {
        const shouldBeNever: never = params;
        throw new Error(`Unexpected params: ${shouldBeNever}`);
      }
    });

    useEffect(
      function execDirectChat() {
        const abortController = new AbortController();
        (async () => {
          try {
            const result = await (async function getDirectChatParams() {
              if (!params) return;
              if (isExternalParams(params)) {
                return searchExternal(params, {
                  signal: abortController.signal,
                });
              } else if (isInternalParams(params)) {
                return searchInternal(params, {
                  signal: abortController.signal,
                });
              } else {
                const shouldBeNever: never = params;
                throw new Error(`Unexpected params: ${shouldBeNever}`);
              }
            })();
            abortController.signal.throwIfAborted();

            if (!result) {
              clearParams();
              return;
            }
            if (orgId !== result.org.id) {
              setActiveOrgId(result.org.id);
              return;
            }
            if (channelId !== result.channel.id) {
              searchController.setState({ channelId: result.channel.id });
              return;
            }
            if (activeMemberId !== result.member.id)
              setActiveMemberId(result.member.id);

            updateSearch();
            clearParams();
          } catch (e) {
            if (abortController.signal.aborted) return;
            clearParams();
            logError(e);
          }
        })();
        return function cleanup() {
          abortController.abort();
        };
      },
      [
        activeMemberId,
        channelId,
        clearParams,
        orgId,
        params,
        setActiveMemberId,
        updateSearch,
      ],
    );

    if (
      isExternalParams(params) ||
      (isInternalParams(params) &&
        (params[SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_ORG_ID] !== orgId ||
          params[SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_CHANNEL_ID] !==
            channelId))
    )
      return <BarLoading />;

    return <>{children}</>;
  },
);

const WrappedDirectChatProvider = memo(function WrappedDirectChatProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <SearchParamFromDirectChatProvider>
      <DirectChatProvider>{children}</DirectChatProvider>
    </SearchParamFromDirectChatProvider>
  );
});

const directChatPageUrl = "chat";
const internalBase = `/${directChatPageUrl}`;
const fullBase = createFullInternalUrl("/chat");

function createInternalDirectChatUrl({
  full,
  orgId,
  channelId,
  memberId,
}: {
  full?: boolean;
  orgId: CantataTypes["Org"]["id"];
  channelId: CantataTypes["Channel"]["id"];
  memberId: CantataTypes["Member"]["id"];
}): string {
  const base = full ? fullBase : internalBase;
  const params: Record<string, string> = {
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_ORG_ID]: String(orgId),
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_CHANNEL_ID]: String(channelId),
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_MEMBER_ID]: String(memberId),
  };
  return `${base}?${new URLSearchParams(params).toString()}`;
}

function createExternalDirectChatUrl({
  full,
  orgUuid,
  externalChannelId,
  externalMemberId,
}: {
  full?: boolean;
  orgUuid: CantataTypes["Org"]["uuid"];
  externalChannelId: CantataTypes["Channel"]["externalChannelId"];
  externalMemberId: CantataTypes["MemberDetail"]["externalMemberId"];
}): string {
  const base = full ? fullBase : internalBase;
  const params: Record<string, string> = {
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_ORG_UUID]: orgUuid,
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_CHANNEL_TYPE]: searchCategories[0],
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_CHANNEL_ID]: externalChannelId,
    [SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_MEMBER_ID]: externalMemberId,
  };
  return `${base}?${new URLSearchParams(params).toString()}`;
}

export {
  createExternalDirectChatUrl,
  createInternalDirectChatUrl,
  WrappedDirectChatProvider as DirectChatProvider,
  useSearchParamFromDirectChatStore,
};
