import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { useTranslation } from "react-i18next";

import { Empty as EmptyImage } from "@/components/assets/Empty";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  container: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colors.neutral007,
    fontSize: "0.75rem",
    gap: 16,
    textAlign: "center",
  }),
  icon: css({
    fontSize: "64px",
  }),
});

export const Empty = memo(function Empty() {
  const { t } = useTranslation();
  return (
    <div css={styles.container}>
      <EmptyImage css={styles.icon} />
      <div>{t("chat.pinMessage.dropdown.empty")}</div>
    </div>
  );
});
