import useSwitch from "@react-hook/switch";
import { memo } from "@zeffiroso/utils/react/memo";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { message } from "@/components/message";
import { Modal } from "@/components/Modal";
import { DeleteOutlined } from "@/shared/icons/common/DeleteOutlined";

const RemoveTeamUserModal = memo(function RemoveTeamUserModal({
  teamId,
  userId,
  userName,
  open,
  onClose,
}: {
  teamId: number;
  userId: number;
  userName: string;
  open: boolean;
  onClose?: () => void;
}) {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { mutate, isLoading } = cantata.team.useRemoveUser(
    {
      params: {
        orgId,
        teamId,
        userId,
      },
    },
    {
      onSuccess() {
        message.success(
          t("team.editPage.removeMember.success", { agentName: userName }),
        );
        onClose?.();
      },
      onError() {
        message.error(t("team.editPage.removeMember.failed"));
      },
    },
  );

  return (
    <Modal
      title={t("team.editPage.removeMember.title")}
      open={open}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          type="default"
          onClick={(e) => {
            e.stopPropagation();
            onClose?.();
          }}
        >
          {t("common.cancel")}
        </Button>,
        <Button
          key="submit"
          data-test="confirm-remove-team-member-button"
          danger={true}
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            mutate(undefined);
          }}
          disabled={isLoading}
          loading={isLoading}
        >
          {t("common.delete")}
        </Button>,
      ]}
    >
      <Trans i18nKey="team.editPage.removeMember.description" />
    </Modal>
  );
});

const RemoveTeamUser = memo(function RemoveTeamUser({
  teamId,
  userId,
  userName,
}: {
  teamId: number;
  userId: number;
  userName: string;
}) {
  const [open, toggle] = useSwitch(false);

  return (
    <>
      <NarrowIconButton
        data-test={`delete-team-member-${userId}-button`}
        onClick={toggle.on}
        size={20}
        icon={<DeleteOutlined />}
      />
      <RemoveTeamUserModal
        teamId={teamId}
        userId={userId}
        userName={userName}
        open={open}
        onClose={toggle.off}
      />
    </>
  );
});

export { RemoveTeamUser };
