import { SmileOutlined } from "@ant-design/icons";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { memo } from "@zeffiroso/utils/react/memo";
import type { PickerProps as EmojiPickerProps } from "emoji-picker-react";
import EmojiPicker, { Categories, EmojiStyle } from "emoji-picker-react";
import insertTextAtCursor from "insert-text-at-cursor";
import { useTranslation } from "react-i18next";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Trigger } from "@/components/Trigger";
import { useTextAreaRef } from "@/routes/Chat/ui/ChatPanel/Editor/Old/textAreaRef";

export const EmojiPickerPopover = memo(function EmojiPickerButton() {
  const { t } = useTranslation();
  const textAreaRef = useTextAreaRef();
  const handleEmojiClick = useHandler<EmojiPickerProps["onEmojiClick"]>(
    function handleEmojiClick(emojiClickData) {
      if (!textAreaRef.current || !textAreaRef.current.resizableTextArea)
        return;

      insertTextAtCursor(
        textAreaRef.current.resizableTextArea.textArea,
        emojiClickData.emoji,
      );
    },
  );
  return (
    <Trigger
      content={
        <EmojiPicker
          searchDisabled
          onEmojiClick={handleEmojiClick}
          emojiStyle={EmojiStyle.NATIVE}
          categories={[
            {
              category: Categories.SUGGESTED,
              name: t("message.editor.emoji.frequentlyUsed"),
            },
            {
              category: Categories.SMILEYS_PEOPLE,
              name: t("message.editor.emoji.people"),
            },
            {
              category: Categories.ANIMALS_NATURE,
              name: t("message.editor.emoji.nature"),
            },
            {
              category: Categories.FOOD_DRINK,
              name: t("message.editor.emoji.food"),
            },
            {
              category: Categories.ACTIVITIES,
              name: t("message.editor.emoji.activities"),
            },
            {
              category: Categories.TRAVEL_PLACES,
              name: t("message.editor.emoji.travel"),
            },
            {
              category: Categories.OBJECTS,
              name: t("message.editor.emoji.objects"),
            },
            {
              category: Categories.SYMBOLS,
              name: t("message.editor.emoji.symbols"),
            },
            {
              category: Categories.FLAGS,
              name: t("message.editor.emoji.flags"),
            },
          ]}
          width={280}
          height={380}
        />
      }
      trigger={["click"]}
      placement="topLeft"
    >
      <NarrowIconButton icon={<SmileOutlined />} />
    </Trigger>
  );
});
