import { css } from "@emotion/react";
import { memo } from "@zeffiroso/utils/react/memo";

import type { CantataTypes } from "@/cantata/types";
import { Action } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/Action";
import { Assignee } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/Assignee";
import { ChannelType } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/ChannelType";
import { ConversationProvider } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/Context";
import { State } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/State";
import { Time } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/Time";
import { Timeline } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/Timeline";
import { cssFlexInheritAndFill, cssInheritGap } from "@/shared/emotion";

const Conversation = memo(function Conversation() {
  return (
    <div
      css={css([
        cssFlexInheritAndFill,
        css`
          flex-direction: column;
        `,
      ])}
    >
      <div>
        <Time />
      </div>
      <div
        css={css([
          cssFlexInheritAndFill,
          css`
            flex-direction: row;
            align-items: stretch;
          `,
        ])}
      >
        <div
          css={css`
            display: flex;
          `}
        >
          <Timeline />
        </div>
        <div
          css={css([
            cssFlexInheritAndFill,
            css`
              flex-direction: column;
              padding: 4px 8px 24px;
              gap: 4px;
            `,
          ])}
        >
          <div
            css={css([
              cssFlexInheritAndFill,
              css`
                padding: 4px 6px;
              `,
            ])}
          >
            <State />
          </div>
          <div
            css={css([
              cssFlexInheritAndFill,
              cssInheritGap,
              css`
                align-items: flex-start;
                padding: 0 6px;
              `,
            ])}
          >
            <div
              css={css([
                cssFlexInheritAndFill,
                cssInheritGap,
                css`
                  flex-direction: row;
                  align-items: center;
                `,
              ])}
            >
              <div
                css={css([
                  cssFlexInheritAndFill,
                  css`
                    flex: 0 0 fit-content;
                  `,
                ])}
              >
                <ChannelType />
              </div>
              <div css={cssFlexInheritAndFill}>
                <Assignee />
              </div>
            </div>
            <div
              css={css([
                cssFlexInheritAndFill,
                css`
                  &:not(:has(a)) {
                    display: none;
                  }
                `,
              ])}
            >
              <Action />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const Wrapped = memo<{
  conversation: CantataTypes["Conversation"];
}>(function WrappedConversation({ conversation }) {
  return (
    <ConversationProvider value={conversation}>
      <Conversation />
    </ConversationProvider>
  );
});

export { Wrapped as Conversation };
