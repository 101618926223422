/* eslint-disable ts/ban-types -- inherit from MUI */
import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import classNames from "classnames";
import type { ElementType } from "react";

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

interface PhIconOwnProps {
  /**
   * Phosphor Icons attribute value.
   *
   * Look up the available Phosphor Icons in the [Phosphor Icons official
   * website](https://phosphoricons.com/).
   */
  "un-i-ph": ComponentProps<DefaultComponent>["un-i-ph"] & string;

  /**
   * The component used for the root node.
   */
  component?: ElementType;
}

interface PhIconTypeMap<
  AdditionalProps = {},
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & PhIconOwnProps;
  defaultComponent: RootComponent;
}

type PhIconProps<
  RootComponent extends ElementType = PhIconTypeMap["defaultComponent"],
  AdditionalProps = {},
> = OverrideProps<
  PhIconTypeMap<AdditionalProps, RootComponent>,
  RootComponent
> & {
  component?: ElementType;
};

const basicClassNames: Array<string> = ["anticon"];

const plugins = {
  defaultComponent,
};

type PhIconComponentPureType = OverridableComponent<PhIconTypeMap>;
type PhIconComponentType = PhIconComponentPureType & typeof plugins;

/**
 * An Phosphor Icon component compatible with Ant Design.
 */
const PhIcon = forwardRef(function PhIcon<
  TComponent extends
    keyof JSX.IntrinsicElements = PhIconTypeMap["defaultComponent"],
>(
  {
    component: Component = "div" as TComponent,
    "un-i-ph": phIconAttr,
    ...props
  }: {
    component?: TComponent;
  } & PhIconOwnProps,
  ref: ComponentProps<TComponent>["ref"],
) {
  const additionalClassName: string | undefined =
    !("className" in props) || typeof props.className !== "string"
      ? undefined
      : props.className;
  const className = classNames(...basicClassNames, additionalClassName);
  return (
    <Component
      {...props}
      {...({
        children: (
          <div
            // eslint-disable-next-line no-restricted-syntax -- This is a helper component that inherits the prop.
            un-i-ph={phIconAttr}
          />
        ),
        className,
        ref,
      } as any)}
    />
  );
}) as PhIconComponentPureType as PhIconComponentType;

Object.assign(PhIcon, plugins);

export { PhIcon };
export type { PhIconOwnProps, PhIconProps, PhIconTypeMap };
