import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Progress } from "@/components/Progress";
import { cssOneLine, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 8,
  }),
  progress: css({
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  }),
  count: css([
    cssOneLine,
    {
      fontSize: "0.75rem",
      overflow: "visible",
    },
  ]),
  success: css({
    color: theme.colors.success,
  }),
  warning: css({
    color: theme.colors.warning,
  }),
  error: css({
    color: theme.colors.error,
  }),
  reports: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
  reportItem: css({
    fontSize: "0.875rem",
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
  reportItemDescription: css({
    fontSize: "0.75rem",
  }),
});

type TaskState = "pending" | "success" | "exist" | "error";

type Task = {
  email: string;
  state: TaskState;
};

interface LoaderProps {
  tasks: Task[];
}

const Loader: FC<LoaderProps> = ({ tasks }) => {
  const { t } = useTranslation();
  const loading = useMemo<ComponentProps<typeof Progress>["loading"]>(
    () => tasks.some((task) => task.state === "pending"),
    [tasks],
  );
  const state = useMemo<ComponentProps<typeof Progress>["state"]>(
    () =>
      tasks.some((task) => task.state === "error")
        ? "error"
        : tasks.some((task) => task.state === "exist")
          ? "warning"
          : tasks.some((task) => task.state === "success")
            ? "success"
            : undefined,
    [tasks],
  );
  const total = tasks.length;
  const value = tasks.filter((task) => task.state !== "pending").length;
  const successTasks = tasks.filter((task) => task.state === "success");
  const errorTasks = tasks.filter((task) => task.state === "error");
  const existTasks = tasks.filter((task) => task.state === "exist");
  const pendingTasks = tasks.filter((task) => task.state === "pending");

  return (
    <div css={styles.root}>
      <div css={styles.progress}>
        <Progress total={total} value={value} state={state} loading={loading} />
        <div
          css={css([
            styles.count,
            state === "success"
              ? styles.success
              : state === "warning"
                ? styles.warning
                : state === "error"
                  ? styles.error
                  : undefined,
          ])}
        >
          {value} / {total}
        </div>
      </div>
      {pendingTasks.length !== total && (
        <div css={styles.reports}>
          {successTasks.length === 0 ? null : (
            <div css={css([styles.reportItem, styles.success])}>
              <div>
                {t("page.settings.users.invite.batch.result.success.title", {
                  count: successTasks.length,
                })}
              </div>
            </div>
          )}
          {existTasks.length === 0 ? null : (
            <div css={css([styles.reportItem, styles.warning])}>
              <div>
                {t("page.settings.users.invite.batch.result.existing.title", {
                  count: existTasks.length,
                })}
              </div>
              <div css={styles.reportItemDescription}>
                {existTasks.map((task) => task.email).join(", ")}
              </div>
            </div>
          )}
          {errorTasks.length === 0 ? null : (
            <div css={css([styles.reportItem, styles.error])}>
              <div>
                {t("page.settings.users.invite.batch.result.failed.title", {
                  count: errorTasks.length,
                })}
              </div>
              <div css={styles.reportItemDescription}>
                {errorTasks.map((task) => task.email).join(", ")}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { Loader };
export type { Task, TaskState };
