import type { QueryOptions } from "@zeffiroso/utils/zodios/types";
import { useMemo } from "react";

import { cantata, type cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";

namespace useChannelQueries {
  export interface Options {
    orgId: CantataTypes["Org"]["id"];
    channelId: CantataTypes["Channel"]["id"];
    queryOptions?: Omit<QueryOptions<QueryFnData, Data | undefined>, "select">;
  }
}

type QueryFnData = Awaited<ReturnType<typeof cantataClient.channel.list>>;
type Data = QueryFnData["channels"][number];

/**
 * Query channel by orgId and channelId. Use list query first, then fallback to
 * getById query.
 */
function useChannelQueries(options: useChannelQueries.Options) {
  const listQuerySelect = useMemo(
    () =>
      function select(
        data: Awaited<ReturnType<typeof cantataClient.channel.list>>,
      ) {
        return data.channels.find(
          (channel) => channel.id === options.channelId,
        );
      },
    [options.channelId],
  );

  const listQuery = cantata.channel.useList(
    {
      params: {
        orgId: options.orgId,
      },
    },
    {
      ...options.queryOptions,
      select: listQuerySelect,
    },
  );
  // Fallback to getByIdQuery if channel is not found in listQuery
  const getByIdQuery = cantata.channel.useGetById(
    {
      params: {
        orgId: options.orgId,
        channelId: options.channelId,
      },
    },
    {
      ...options.queryOptions,
      enabled: listQuery.isSuccess && !listQuery.data,
    },
  );
  const channel = listQuery.data || getByIdQuery.data;
  const queries = useMemo(
    () => ({
      list: listQuery,
      getById: getByIdQuery,
    }),
    [getByIdQuery, listQuery],
  );
  const result = useMemo(() => {
    if (channel) {
      return {
        isSuccess: true,
        isLoading: false,
        isError: false,
        data: channel,
        queries,
      } as const;
    }
    if (listQuery.isLoading || getByIdQuery.isLoading) {
      return {
        isSuccess: false,
        isLoading: true,
        isError: false,
        data: undefined,
        queries,
      } as const;
    }
    return {
      isSuccess: false,
      isLoading: false,
      isError: true,
      data: undefined,
      queries,
    };
  }, [channel, getByIdQuery.isLoading, listQuery.isLoading, queries]);
  return result;
}

export { useChannelQueries };
