import { crescendoLabBirthDay } from "@chatbotgang/etude/dummy";

import {
  defineConfig,
  defineTestAccount,
} from "@/internal/featureFlag/defineConfig";
import { availableLocales } from "@/shared/g11n/config";

const configUtils = (function iife() {
  const enabled = {
    autoEnableAt: crescendoLabBirthDay,
  } as const;

  const disabled = {
    autoEnableAt: undefined,
  } as const;

  const configUtils = {
    enabled,
    disabled,
  };

  return configUtils;
})();

const debugFeatureFlags = defineConfig({
  debugDivider: {
    type: "divider",
    label: "Debug Tools",
  },
  keepFeatureFlags: {
    type: "toggle",
    label: "Don't clear feature flags when last email changed",
  },
  alwaysHideDevModeFab: {
    type: "toggle",
    label: "Always hide Dev Mode FAB",
  },
  quickI18n: {
    label: "Quick switch language",
    type: "singleSelect",
    options: availableLocales.map((locale) => ({
      value: locale.code,
      label: locale.displayName,
    })),
  },
  overrideConversationHistoryAction: {
    label: "Chat: Override conversation history action",
    type: "singleSelect",
    options: [
      {
        value: "jumpTo",
        label: "Go to chat",
      },
      {
        value: "viewHistory",
        label: "View history",
      },
    ],
  },
  reactQueryDevtool: {
    label: "Enable react-query devtool",
    type: "singleSelect",
    options: [
      {
        value: "bottom-right",
        label: "Bottom right",
      },
      {
        value: "top-left",
        label: "Top left",
      },
      {
        value: "top-right",
        label: "Top right",
      },
      {
        value: "bottom-left",
        label: "Bottom left",
      },
    ],
  },
  renderMemberId: {
    type: "toggle",
    label: "Render member ID in the profile panel",
  },
  markAsRead: {
    type: "singleSelect",
    label: "Chat: Mark as read",
    options: [
      {
        value: "always",
      },
      {
        value: "never",
      },
    ],
  },
});

const nextFeatureFlags = defineConfig({
  NextDivider: {
    type: "divider",
    label: "Next Features",
  },
});

/**
 * Please do not remove these feature flags; they are used for disabling
 * features temporarily.
 */
const releasedFeatureFlags = defineConfig({
  releasedFeatures: {
    type: "divider",
    label: "Released Features",
  },
  aiQuickTemplate: {
    type: "toggle",
    label: "Chat: AI quick template",
    ...configUtils.enabled,
  },
});

/**
 * Feature flags that are still in development. They can be temporarily enabled
 * for specific purposes.
 */
const experimentalFeatureFlags = defineConfig({
  experimentalFeatures: {
    type: "divider",
    label: "Experimental Features",
  },
  /**
   * Try `metadata.backupMediaUrl` first, then `originUrl` by default.
   *
   * If the feature flag `mediaOriginUrlOnly` is enabled, only `originUrl` will
   * be used.
   *
   * Slack:
   * [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1708360164026869?thread_ts=1707981664.646579&cid=C02R6ETJMEY)
   */
  originUrlOnly: {
    type: "toggle",
    label: "Chat: Use `originUrl` only, don't use `metadata.backup_media_url`",
  },
  deletedMessageFallback: {
    type: "toggle",
    label:
      "Chat: Show deleted message fallback instead of the original message",
  },
  notificationEmail: {
    type: "toggle",
    label: "Notification: email (TBD)",
  },
});

const featureFlags = defineConfig({
  ...debugFeatureFlags,
  ...nextFeatureFlags,
  ...releasedFeatureFlags,
  ...experimentalFeatureFlags,
});

const testAccounts = defineTestAccount(featureFlags)([
  /**
   * Meta review
   */
  {
    email: "crescender+meta@cresclab.com",
    feature: ["alwaysHideDevModeFab"],
  },
]);

export { featureFlags, testAccounts };
