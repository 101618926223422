import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { memo } from "@zeffiroso/utils/react/memo";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import { createWithEqualityFn } from "zustand/traditional";

import { cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { useMessage } from "@/internal/message";
import { Layout } from "@/routes/Chat/ui/Layout";
import { useHandleErrorAndShowMessage } from "@/shared/application/error/handleError";

type StoreValue = {
  memberId: number | null;
  target: null | {
    cursor: {
      before: string | null;
      after: string | null;
    } | null;
    message: CantataTypes["MessageDetail"];
  };
};

function setupStore({
  handleErrorAndShowMessage,
  messageError,
}: {
  handleErrorAndShowMessage: ReturnType<typeof useHandleErrorAndShowMessage>;
  messageError: ReturnType<typeof useMessage>["error"];
}) {
  const useJumpToMessageStore = createWithEqualityFn<StoreValue>()(
    () => ({
      memberId: null,
      target: null,
    }),
    shallow,
  );
  let abortController: AbortController = new AbortController();
  function abort() {
    abortController.abort();
    abortController = new AbortController();
  }
  async function setupSearch({
    orgId,
    memberId,
    query,
  }: {
    orgId: CantataTypes["Org"]["id"];
    memberId: CantataTypes["Member"]["id"];
    query: string;
  }) {
    abort();
    const localAbortController = abortController;
    useJumpToMessageStore.setState({ memberId, target: null });
    const searchResult = await safePromise(async function search() {
      return cantataClient.message.searchFromAMember(
        {
          query,
        },
        {
          params: {
            orgId,
            memberId,
          },
          signal: localAbortController.signal,
        },
      );
    });
    if (localAbortController.signal.aborted) return;
    let target: StoreValue["target"] = null;
    (() => {
      if (searchResult.isError) {
        handleErrorAndShowMessage(searchResult.error);
        return;
      }
      if (searchResult.data.matchedMessages.length === 0) {
        // Edge case.
        messageError("No result found");
        return;
      }
      const nextTarget = searchResult.data.matchedMessages[0];
      // Unexpected case.
      if (!nextTarget) throw new Error("target not found");
      target = nextTarget;
    })();
    useJumpToMessageStore.setState({
      memberId: null,
      target,
    });
  }
  async function setupConversation({
    orgId,
    memberId,
    conversationId,
  }: {
    orgId: CantataTypes["Org"]["id"];
    memberId: CantataTypes["Member"]["id"];
    conversationId: CantataTypes["Conversation"]["conversationId"];
  }) {
    abort();
    const localAbortController = abortController;
    useJumpToMessageStore.setState({ memberId, target: null });
    const searchResult = await safePromise(async function search() {
      return cantataClient.message.getLastConversationMessage({
        params: {
          orgId,
          memberId,
          conversationId,
        },
        signal: localAbortController.signal,
      });
    });
    if (localAbortController.signal.aborted) return;
    let target: StoreValue["target"] = null;
    (() => {
      if (searchResult.isError) {
        handleErrorAndShowMessage(searchResult.error);
        return;
      }
      target = searchResult.data;
    })();
    useJumpToMessageStore.setState({
      memberId: null,
      target,
    });
  }
  function setup(message: StoreValue["target"]) {
    useJumpToMessageStore.setState({ memberId: null, target: message });
  }
  /**
   * Manually clear the state when the task is done.
   */
  function clear() {
    abort();
    const currentState = useJumpToMessageStore.getState();
    if (currentState.target || currentState.memberId) {
      useJumpToMessageStore.setState({ memberId: null, target: null });
    }
  }
  function useTarget() {
    return useJumpToMessageStore((state) => state.target);
  }
  function useEffect() {
    const destoryTasks: Array<() => void> = [];
    destoryTasks.push(
      /**
       * Should always close the drawer when the target is changed.
       */
      useJumpToMessageStore.subscribe((state, prevState) => {
        if (!state.target || state.target === prevState.target) return;
        Layout.profileDrawer.close();
      }),
    );
    return function cleanup() {
      abort();
      destoryTasks.forEach((task) => task());
    };
  }
  return {
    setup,
    setupSearch,
    setupConversation,
    clear,
    useTarget,
    abort,
    useStore: useJumpToMessageStore,
    useEffect,
  };
}

const JumpToMessageEventEmitterContext = createContext<ReturnType<
  typeof setupStore
> | null>(null);

const JumpToMessageProvider = memo(function JumpToMessageProvider({
  children,
}: {
  children: ReactNode;
}) {
  const handleErrorAndShowMessage = useHandleErrorAndShowMessage();
  const message = useMessage();
  const messageError = useHandler<(typeof message)["error"]>(
    function messageError(...args) {
      return message.error(...args);
    },
  );
  const jumpToMessageController = useMemo(
    () => setupStore({ handleErrorAndShowMessage, messageError }),
    // Make sure the dependencies are stable.
    [handleErrorAndShowMessage, messageError],
  );
  jumpToMessageController.useEffect();
  return (
    <JumpToMessageEventEmitterContext.Provider value={jumpToMessageController}>
      {children}
    </JumpToMessageEventEmitterContext.Provider>
  );
});

function useJumpToMessageController(): ReturnType<typeof setupStore> {
  const contextValue = useContext(JumpToMessageEventEmitterContext);
  if (!contextValue) {
    throw new Error(
      "useJumpToMessageController must be used inside a JumpToMessageProvider",
    );
  }

  return contextValue;
}

export { JumpToMessageProvider, useJumpToMessageController };
