import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { random } from "@chatbotgang/etude/string/random";
import { delay } from "@chatbotgang/etude/timer/delay";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useChange from "@react-hook/change";
import useSwitch from "@react-hook/switch";
import { API_DEFAULT_LIMIT } from "@zeffiroso/env";
import type { LegatoOnEventTypes } from "@zeffiroso/legato/on";
import { isScrolledToBottom } from "@zeffiroso/utils/dom/isScrolledToBottom";
import { memo } from "@zeffiroso/utils/react/memo";
import { UsePortalSwitchHook } from "@zeffiroso/utils/react-lib/usePortal";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import { secondsToMilliseconds } from "date-fns";
import { debounce, isEqual, mapValues, pick, throttle } from "lodash-es";
import objectInspect from "object-inspect";
import type { ElementRef } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { mergeRefs } from "react-merge-refs";
import { createWithEqualityFn } from "zustand/traditional";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { useFeatureFlag } from "@/app/featureFlag";
import { cantata, cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { BarLoading } from "@/components/Loading/BarLoading";
import { useLegato, useLegatoEvent } from "@/legato";
import { uuid } from "@/lib/uuid";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import {
  getMessageTimeWindowEndAt,
  useChatState,
} from "@/resources/member/useChatState";
import { useMarkReadMutation } from "@/resources/member/useMarkReadMutation";
import { messages } from "@/resources/message/messages";
import { sortMessageCompareFn } from "@/resources/message/utils";
import { GoToBottomButton } from "@/routes/Chat/ui/ChatPanel/History/GoToBottomButton";
import { Messages } from "@/routes/Chat/ui/ChatPanel/History/Messages";
import type { SendingMessagesReq } from "@/routes/Chat/ui/ChatPanel/sendingMessages";
import { useSendingMessagesController } from "@/routes/Chat/ui/ChatPanel/sendingMessages";
import { useJumpToMessageController } from "@/routes/Chat/ui/jumpToMessage";
import { emotionMedia } from "@/shared/utils/style/emotionMedia";

const Outer = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
`;

const ScrollList = styled.div`
  position: relative;
  flex: 1;
  padding: 12px 16px;
  background-color: #fff;
  overflow-y: auto;

  /* This calculation uses "window.innerHeight" rather than "100vh" to prevent inaccurate values on different mobile devices. */

  /* See https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html */
  ${emotionMedia(
    String.raw,
    "<=mobile",
    (css) => css`
      height: ${() => `calc(${window.innerHeight}px - 181px)`};
    `,
  )}
`;

const cssPreviousAndNextLoadingBar = css`
  position: relative;
  display: flex;
  width: 100%;
  height: max(5rem, 50dvh);
`;

const parseMsgEventToMessage = (
  msgEvent: LegatoOnEventTypes["messages"],
  member: Pick<CantataTypes["Member"], "avatar" | "displayName">,
  user: Pick<CantataTypes["User"], "avatar" | "chatName"> | undefined,
): CantataTypes["MessageDetail"] => {
  const content = msgEvent.content;
  const isUserMessage = content.senderType === "user";
  const isMemberMessage = content.senderType === "member";
  const isUserOrMemberMessage = isUserMessage || isMemberMessage;

  let userDisplayName = null;
  if (isUserMessage)
    userDisplayName = user ? user.chatName : content.senderName;

  return {
    id: content.id,
    uuid: uuid(),
    channelId: content.channelId,
    memberId: content.memberId,
    memberAvatar: member.avatar,
    memberDisplayName: member.displayName,
    userId: !isUserMessage ? null : content.senderId,
    userName: userDisplayName ?? "",
    userAvatar: !user ? null : user.avatar,
    userStatus: "active",
    senderType: content.senderType,
    ...(!isUserOrMemberMessage
      ? {
          replyTo: null,
          speakerId: null,
          quotable: false,
        }
      : {
          replyTo: content.replyTo,
          speakerId: content.speakerId,
          quotable: content.quotable,
        }),
    type: content.contentType,
    text: !("text" in content) ? "" : content.text,
    previewUrl:
      !("previewUrl" in content) || !content.previewUrl
        ? null
        : content.previewUrl,
    originUrl: !("originUrl" in content) ? null : content.originUrl,
    lineFlexContent: !("lineFlexContent" in content)
      ? null
      : content.lineFlexContent,
    metadata: !("metadata" in content) ? null : content.metadata,
    read: false,
    pinned: false,
    createdAt: content.createdAt,
    updatedAt: content.createdAt,
    deletedAt: null,
  };
};

const sendingMessagesUtilities = (function sendingMessagesUtilities() {
  /**
   * This component is used to observe the new requests changes.
   */
  const Observer = memo(function Observer({
    /**
     * Callback when a new request is added.
     */
    onAfterAdded,
  }: {
    onAfterAdded: (
      requests: ReturnType<
        ReturnType<typeof useSendingMessagesController>["useRequests"]
      >,
    ) => void;
  }) {
    const sendingMessagesController = useSendingMessagesController();
    const requests = sendingMessagesController.useRequests();
    useChange(requests, async (current, previous) => {
      const newRequests = current.filter(
        (request) =>
          !previous.some(
            (r) => r.sendingMessageId === request.sendingMessageId,
          ),
      );
      if (newRequests.length === 0) return;
      await delay(1);
      onAfterAdded(newRequests);
    });
    return null;
  });
  const Item = memo(function Item({
    request,
  }: {
    request: ReturnType<
      ReturnType<typeof useSendingMessagesController>["useRequests"]
    >[0];
  }) {
    return <div>{objectInspect(request)}</div>;
  });
  const List = memo(function List() {
    const sendingMessagesController = useSendingMessagesController();
    const requests = sendingMessagesController.useRequests();
    return (
      <>
        {requests.map((request) => (
          <Item key={request.sendingMessageId} request={request} />
        ))}
      </>
    );
  });
  return {
    Observer,
    List,
  };
})();

/**
 * Return a function that can get sending messages to render from a sending
 * messages request.
 */
function useGetSendingMessagesReqMessages() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const meQuery = cantata.user.useGetMe({
    params: {
      orgId,
    },
  });
  const member = memberQueriesContext.useMember();
  const messagesController = messages.useController();
  const messagesState = messagesController.useStore((state) => ({
    messages: state.data,
  }));
  const getSendingMessagesReqMessages = useCallback(
    function getSendingMessagesReqMessages(
      request: SendingMessagesReq,
    ): Array<CantataTypes["MessageDetail"]> {
      if (!meQuery.isSuccess) return [];
      return request.messages.flatMap<CantataTypes["MessageDetail"]>(
        ({ body: message }) => {
          // Do not append the message if it's already in the store.
          if (messagesState.messages.some((m) => m.uuid === message.uuid))
            return [];
          const ret: CantataTypes["MessageDetail"] = {
            id: Number.NaN,
            type: message.type,
            uuid: message.uuid,
            channelId: member.channelId,
            memberId: member.id,
            memberAvatar: member.avatar,
            memberDisplayName: member.displayName,
            userId: meQuery.data.id,
            userName: meQuery.data.chatName || meQuery.data.name,
            userAvatar: meQuery.data.avatar,
            userStatus: meQuery.data.status,
            senderType: "user",
            // TODO: add replyTo and speakerId and quotable
            speakerId: null,
            replyTo: null,
            quotable: false,
            read: false,
            pinned: false,
            text: message.text,
            previewUrl: "previewUrl" in message ? message.previewUrl : null,
            originUrl: "originUrl" in message ? message.originUrl : null,
            metadata: "metadata" in message ? message.metadata : null,
            lineFlexContent:
              "lineFlexContent" in message
                ? JSON.stringify(message.lineFlexContent)
                : null,
            createdAt: new Date(Number.NaN),
            updatedAt: new Date(Number.NaN),
          };
          return [ret];
        },
      );
    },
    [
      meQuery.data?.avatar,
      meQuery.data?.chatName,
      meQuery.data?.id,
      meQuery.data?.name,
      meQuery.data?.status,
      meQuery.isSuccess,
      member.avatar,
      member.channelId,
      member.displayName,
      member.id,
      messagesState.messages,
    ],
  );
  return getSendingMessagesReqMessages;
}

type MessagesDisplayProps = {
  displayMessages?: (message: {
    history: Array<CantataTypes["MessageDetail"]>;
  }) => Array<CantataTypes["MessageDetail"]>;
} & Pick<ComponentProps<typeof Messages>, "rootEl">;

const MessagesDisplay = memo<MessagesDisplayProps>(function MessagesDisplay({
  displayMessages,
  ...props
}) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const member = memberQueriesContext.useMember();
  const controller = messages.useController();
  const state = controller.useStore((state) => ({
    messages: state.data,
    isLoading: state.isPreviousLoading || state.isNextLoading,
  }));
  const getSendingMessagesReqMessages = useGetSendingMessagesReqMessages();
  const sendingMessagesController = useSendingMessagesController();
  const requests = sendingMessagesController.useRequests();
  const mergedMessages = useMemo(
    function computeMergedMessages() {
      const history = state.messages;
      const sending = requests
        .filter(
          (request) =>
            request.params.orgId === orgId &&
            request.params.memberId === member.id,
        )
        .flatMap<CantataTypes["MessageDetail"]>((request) => {
          return getSendingMessagesReqMessages(request);
        });
      return [
        ...(!displayMessages
          ? history
          : displayMessages({ history }).toSorted(sortMessageCompareFn)),
        ...sending,
      ];
    },
    [
      state.messages,
      requests,
      displayMessages,
      orgId,
      member.id,
      getSendingMessagesReqMessages,
    ],
  );
  return (
    <Messages
      showMoreButton
      messages={mergedMessages}
      isLoading={state.isLoading}
      {...pick(props, "rootEl")}
    />
  );
});

const ChatPanelHistory = memo(function ChatPanelHistory() {
  const orgQueriesData = orgQueriesContext.useData();
  const { me } = orgQueriesData;
  const [containerEl, setContainerEl] = useState<ElementRef<"div"> | null>(
    null,
  );
  const containerRef = useHandler(function containerRef(
    el: typeof containerEl,
  ) {
    setContainerEl(el);
  });
  const [messagesEl, setMessagesEl] = useState<ElementRef<"div"> | null>(null);
  const messagesRef = useHandler(function messagesRef(el: typeof messagesEl) {
    setMessagesEl(el);
  });
  const member = memberQueriesContext.useMember();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const [showingScrollToBottomButton, toggleShowingScrollToBottomButton] =
    useSwitch(false);
  const showScrollToBottomButton = useHandler(
    function showScrollToBottomButton() {
      toggleShowingScrollToBottomButton.on();
    },
  );
  const hideScrollToBottomButton = useHandler(
    function hideScrollToBottomButton() {
      toggleShowingScrollToBottomButton.off();
    },
  );
  const activeMember = memberQueriesContext.useMember();
  const usersQuery = cantata.user.useList({
    params: {
      orgId,
    },
  });

  const safeInvalidateQuery = useSafeInvalidateQuery();
  const getUser = useCallback(
    function getUser(userId: CantataTypes["User"]["id"]) {
      const user =
        usersQuery.data?.users.find((user) => user.id === userId) ?? null;
      if (!user) {
        /**
         * User not found. Invalidate the query to refetch the latest users.
         */
        safeInvalidateQuery(usersQuery.key);
        return;
      }
      return user;
    },
    [safeInvalidateQuery, usersQuery],
  );
  const sendingMessagesController = useSendingMessagesController();

  const localController = useMemo(() => {
    const useStore = createWithEqualityFn<{
      isScrolledToBottom: boolean;
    }>()(
      () => ({
        isScrolledToBottom: true,
      }),
      shallow,
    );
    return {
      useStore,
    };
  }, []);
  const useLocalStore = localController.useStore;
  const localState = useLocalStore((state) =>
    pick(state, ["isScrolledToBottom"]),
  );

  const messagesController = messages.useController();
  const messagesState = messagesController.useStore((state) => ({
    messages: state.data,
    isLoadingPrevious: state.isPreviousLoading,
    isLoadingNext: state.isNextLoading,
    ...mapValues(
      pick(messagesController.selectors, [
        "canTryPrevious",
        "canTryNext",
        "isLoading",
      ]),
      (selector) => selector(state),
    ),
  }));

  const jumpToMessageController = useJumpToMessageController();
  const jumpToMessageTarget = jumpToMessageController.useTarget();

  const [localJumpToMessageTarget, setLocalJumpToMessageTarget] =
    useState(jumpToMessageTarget);

  const canTryPrevious = useMemo(
    () =>
      !localJumpToMessageTarget
        ? /**
           * We always cannot try previous if there is no local jump to message
           */
          false
        : messagesState.canTryPrevious,
    [localJumpToMessageTarget, messagesState.canTryPrevious],
  );

  const isScrolledToMessageBottom = useMemo(
    () => localState.isScrolledToBottom && !messagesState.canTryPrevious,
    [localState.isScrolledToBottom, messagesState.canTryPrevious],
  );

  const scrollTo = useHandler((options?: ScrollToOptions) => {
    if (!containerEl) return;

    containerEl.scrollTo({
      ...options,
    });
  });

  /**
   * Scroll to bottom might trigger "scroll" event, which might change the
   * `isScrolledToBottom` state. We need to prevent this by using a timeout to
   * make sure it sticks to the bottom.
   */
  const stickingToBottomTimeout = useRef<ReturnType<typeof setTimeout> | null>(
    null,
  );
  const clearStickingToBottomTimeout = useHandler(
    function clearStickingToBottomTimeout() {
      if (stickingToBottomTimeout.current === null) return;
      clearTimeout(stickingToBottomTimeout.current);
      stickingToBottomTimeout.current = null;
    },
  );
  useEffect(
    function cleanupStickingToBottomTimeout() {
      return clearStickingToBottomTimeout;
    },
    [clearStickingToBottomTimeout],
  );
  const setupStickingToBottomTimeout = useHandler(
    function setupStickingToBottomTimeout() {
      clearStickingToBottomTimeout();
      stickingToBottomTimeout.current = setTimeout(
        clearStickingToBottomTimeout,
        100,
      );
    },
  );

  const scrollToBottom = useHandler(function scrollToBottom(
    options?: ScrollToOptions,
  ) {
    if (!containerEl) return;
    if (options?.behavior !== "smooth") setupStickingToBottomTimeout();
    if (!isScrolledToBottom(containerEl)) {
      scrollTo({
        top: containerEl.scrollHeight,
        ...options,
      });
    }
    if (!useLocalStore.getState().isScrolledToBottom) {
      useLocalStore.setState({
        isScrolledToBottom: true,
      });
    }
  });

  const goToMessagesBottom = useHandler(function goToMessagesBottom() {
    if (!canTryPrevious) {
      scrollToBottom({
        behavior: "smooth",
      });
      return;
    }
    /**
     * Clear the local jump to message target to refetch the latest
     * messages.
     */
    setLocalJumpToMessageTarget(null);
  });

  useLegatoEvent("messages", (data) => {
    const { content } = data;
    if (content.channelId !== activeMember.channelId) return;
    if (content.memberId !== activeMember.id) return;

    const userId =
      /**
       * `userId` refers to the ID of the message receiver. It's commented
       * out in the Legato SDK to prevent potential misuse.
       */
      content.senderType === "user"
        ? content.senderId
        : "userId" in content
          ? content.userId
          : content.senderId;
    const user = userId !== null ? getUser(userId) : undefined;
    const incomingMessage = parseMsgEventToMessage(data, activeMember, user);
    if (!canTryPrevious) messagesController.addMessages([incomingMessage]);
  });

  useLegatoEvent("message-update", function updateMessage(data) {
    const { content } = data;
    const messages = messagesController.useStore.getState().data;
    const currentMessage = messages.find((m) => m.id === content.id);
    if (!currentMessage) return;
    const updatedMessage: typeof currentMessage = {
      ...currentMessage,
      ...pick(content, Object.keys(currentMessage)),
    };
    if (isEqual(updatedMessage, currentMessage)) return;
    messagesController.addMessages([updatedMessage]);
  });

  const legato = useLegato();
  useEffect(
    /**
     * If the connection is reconnected, we should try to fetch the latest
     * messages because we might have missed some messages when the connection
     * was lost.
     *
     * Slack:
     * [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1709171850068879?thread_ts=1708677003.831669&cid=C02R6ETJMEY)
     */
    function fixLastMessagesIfReconnected() {
      if (!legato.connected) return;
      if (canTryPrevious) return;
      const abortController = new AbortController();
      (async () => {
        if (abortController.signal.aborted) return;
        const result = await safePromise(() =>
          cantataClient.message.list({
            params: {
              orgId,
              memberId: activeMember.id,
            },
            queries: {
              limit: API_DEFAULT_LIMIT,
            },
            signal: abortController.signal,
          }),
        );
        if (abortController.signal.aborted) return;
        if (result.isError) throw result.error;
        messagesController.addMessages(result.data.messages);
      })();
      return function cleanup() {
        abortController.abort();
      };
    },
    [
      activeMember.id,
      canTryPrevious,
      legato.connected,
      messagesController,
      orgId,
    ],
  );
  /**
   * `undefined` means the user is not fetched yet.
   */
  const isInternalUser: undefined | boolean = !usersQuery.isSuccess
    ? undefined
    : usersQuery.data.users.find((user) => user.id === me.id)?.isInternalUser;
  const markReadFeatureFlag = useFeatureFlag("markAsRead");
  const shouldMarkRead = useMemo(() => {
    if (markReadFeatureFlag === "always") return true;
    if (markReadFeatureFlag === "never") return false;
    if (isInternalUser === undefined || isInternalUser) return false;
    return true;
  }, [isInternalUser, markReadFeatureFlag]);
  const markReadMutation = useMarkReadMutation();
  const markReadMutate = useHandler(function markReadMutate() {
    if (!shouldMarkRead) return;
    markReadMutation.mutate({
      orgId,
      memberId: activeMember.id,
    });
  });
  /**
   * Debounce the mark read mutation to prevent unnecessary requests because
   * it's called frequently.
   */
  const debouncedMarkReadMutate = useMemo(
    function debouncedMarkReadMutate() {
      return debounce(markReadMutate, secondsToMilliseconds(1));
    },
    [markReadMutate],
  );
  const readAll = useHandler(() => {
    if (!activeMember) return;
    if (!shouldMarkRead) return;
    debouncedMarkReadMutate();
  });

  useEffect(
    function sendReadAllIfScrolledToMessageBottom() {
      if (!isScrolledToMessageBottom) return;
      readAll();
    },
    [isScrolledToMessageBottom, readAll],
  );

  // Unread + 1 if not scrolled to bottom
  useLegatoEvent("messages", (data) => {
    if (
      data.content.senderType !== "member" ||
      data.content.channelId !== member.channelId ||
      data.content.memberId !== member.id
    )
      return;
    if (localState.isScrolledToBottom) readAll();
  });

  /**
   * Scroll to the message target if it's set.
   *
   * If executed, return `true`, otherwise, return `false`.
   */
  const scrollToJumpToMessageTarget = useHandler(
    function scrollToJumpToMessageTarget(): boolean {
      const jumpToMessageState = jumpToMessageController.useStore.getState();
      const jumpToMessageTarget = jumpToMessageState.target;
      if (!jumpToMessageTarget || !containerEl || !messagesEl) return false;
      const targetMessageElement = messagesEl.querySelector(
        `[data-message-id="${jumpToMessageTarget.message.id}"]`,
      );
      if (!targetMessageElement) return false;
      const messagesRect = messagesEl.getBoundingClientRect();
      const messageRect = targetMessageElement.getBoundingClientRect();
      if (messageRect.height > containerEl.clientHeight) {
        // Stick to the top of the message if the message is too large.
        const nextTop = messageRect.top - messagesRect.top;
        scrollTo({
          top: nextTop,
        });
        return true;
      }
      // Stick to center of the message if the message is not too large.
      const nextTop =
        messageRect.top -
        messagesRect.top -
        containerEl.clientHeight / 2 +
        messageRect.height / 2;
      scrollTo({
        top: nextTop,
      });
      return true;
    },
  );

  useEffect(
    function scrollToJumpToMessageTargetIfNeeded() {
      if (!jumpToMessageTarget) return;
      if (scrollToJumpToMessageTarget()) return;
      setLocalJumpToMessageTarget(jumpToMessageTarget);
    },
    [scrollToJumpToMessageTarget, jumpToMessageTarget],
  );

  useEffect(
    function clearJumpToMessageTargetWhenStable() {
      if (
        !jumpToMessageTarget ||
        !containerEl ||
        !messagesEl ||
        messagesState.isLoading
      )
        return;
      const destoryTasks: Array<() => void> = [];
      /**
       * Wait for a while to clear the jump to message target when the
       * layout is stable.
       */
      const clearJumpToMessageTimeoutMs = secondsToMilliseconds(0.5);
      /**
       * The maximum time to clear the jump to message target.
       */
      const maxClearJumpToMessageTimeoutMs = secondsToMilliseconds(2);
      let timeout: null | ReturnType<typeof setTimeout> = null;
      function clear() {
        if (!timeout) return;
        clearTimeout(timeout);
        timeout = null;
      }
      destoryTasks.push(clear);
      function finish() {
        jumpToMessageController.clear();
        clear();
        clearMaxTimeout();
      }
      function resetTimeout() {
        clear();
        timeout = setTimeout(finish, clearJumpToMessageTimeoutMs);
      }
      resetTimeout();
      const resizeObserver = new ResizeObserver(resetTimeout);
      resizeObserver.observe(containerEl);
      resizeObserver.observe(messagesEl);
      destoryTasks.push(() => {
        resizeObserver.disconnect();
      });
      window.addEventListener("scroll", resetTimeout);
      destoryTasks.push(() => {
        window.removeEventListener("scroll", resetTimeout);
      });
      const maxTimeout = setTimeout(finish, maxClearJumpToMessageTimeoutMs);
      function clearMaxTimeout() {
        clearTimeout(maxTimeout);
      }
      destoryTasks.push(clearMaxTimeout);
      return function cleanup() {
        destoryTasks.forEach((task) => task());
      };
    },
    [
      containerEl,
      jumpToMessageController,
      jumpToMessageController.clear,
      jumpToMessageTarget,
      messagesEl,
      messagesState.isLoading,
    ],
  );

  useEffect(
    function fixScrollPosition() {
      return (function iife(options): undefined | (() => void) {
        if (!options.containerEl || !options.messagesEl) return;
        let cancel = false;
        const containerEl = options.containerEl;
        const messagesEl = options.messagesEl;
        const destroyTasks: Array<() => void> = [];
        function getIsScrolledToBottomFromStore() {
          return localController.useStore.getState().isScrolledToBottom;
        }
        function getCanTryPrevious() {
          return !localJumpToMessageTarget
            ? false
            : messagesController.selectors.canTryPrevious(
                messagesController.useStore.getState(),
              );
        }
        function getIsScrolledToMessagesBottom() {
          return getIsScrolledToBottomFromStore() && !getCanTryPrevious();
        }
        function updateIsScrolledToBottom() {
          if (cancel) return;
          const isScrolledToBottomFromStore = getIsScrolledToBottomFromStore();
          if (stickingToBottomTimeout.current) {
            scrollToBottom();
            return;
          }
          const isScrolledToBottomInDom = isScrolledToBottom(containerEl);
          const nextIsScrolledToBottom = isScrolledToBottomInDom;

          if (isScrolledToBottomFromStore !== nextIsScrolledToBottom) {
            localController.useStore.setState({
              isScrolledToBottom: nextIsScrolledToBottom,
            });
          }
        }
        destroyTasks.push(function resetAllState() {
          localController.useStore.setState({
            isScrolledToBottom: true,
          });
        });
        function fixScroll() {
          if (cancel) return;
          if (scrollToJumpToMessageTarget()) {
            return;
          }
          if (stickingToBottomTimeout.current) {
            scrollToBottom();
            return;
          }
          const isSendingMessages =
            sendingMessagesController.useStore.getState().requests.length > 0;
          if (
            getIsScrolledToMessagesBottom() ||
            /**
             * If there was no message in the view, we should scroll to the bottom.
             */
            isSendingMessages
          ) {
            scrollToBottom();
            return;
          }

          updateIsScrolledToBottom();
        }
        const resizeObserver = new ResizeObserver(fixScroll);
        resizeObserver.observe(containerEl);
        resizeObserver.observe(messagesEl);
        destroyTasks.push(() => {
          resizeObserver.unobserve(containerEl);
          resizeObserver.unobserve(messagesEl);
          resizeObserver.disconnect();
        });
        /**
         * The scrollToBottom execution should be throttled to prevent
         * recursive calls to the `scroll` event.
         */
        const throttledScrollToBottom = throttle(scrollToBottom, 10);
        /**
         * Initial call to update the state.
         */
        updateIsScrolledToBottom();
        fixScroll();
        function scrollHandler() {
          if (cancel) return;
          if (scrollToJumpToMessageTarget()) {
            return;
          }
          if (stickingToBottomTimeout.current) {
            /**
             * Reset the timeout to stick to the bottom.
             */
            setupStickingToBottomTimeout();
            localController.useStore.setState({
              isScrolledToBottom: true,
            });
            throttledScrollToBottom();
            return;
          }
          updateIsScrolledToBottom();
        }
        containerEl.addEventListener("scroll", scrollHandler);
        destroyTasks.push(() =>
          containerEl.removeEventListener("scroll", scrollHandler),
        );
        destroyTasks.push(
          (() => {
            let showScrollToBottomButtonTimeout: ReturnType<
              typeof setTimeout
            > | null = null;
            function clearShowScrollToBottomButtonTimeout() {
              if (showScrollToBottomButtonTimeout === null) return;
              clearTimeout(showScrollToBottomButtonTimeout);
              showScrollToBottomButtonTimeout = null;
            }
            function setShowScrollToBottomButtonTimeout() {
              clearShowScrollToBottomButtonTimeout();
              showScrollToBottomButtonTimeout = setTimeout(() => {
                clearShowScrollToBottomButtonTimeout();
                showScrollToBottomButton();
              }, 333);
            }
            let shouldShowScrollToBottomButton = false;
            function exec() {
              const jumpToMessageIsSearching = Boolean(
                jumpToMessageController.useStore.getState().memberId,
              );
              const nextShouldShowScrollToBottomButton =
                !localController.useStore.getState().isScrolledToBottom &&
                !messagesController.computedValues.isLoading() &&
                !jumpToMessageIsSearching;
              if (
                nextShouldShowScrollToBottomButton ===
                shouldShowScrollToBottomButton
              )
                return;
              shouldShowScrollToBottomButton =
                nextShouldShowScrollToBottomButton;
              if (shouldShowScrollToBottomButton) {
                setShowScrollToBottomButtonTimeout();
              } else {
                hideScrollToBottomButton();
                clearShowScrollToBottomButtonTimeout();
              }
            }
            const unsubscribeLocalStore =
              localController.useStore.subscribe(exec);
            const unsubscribeMessagesStore =
              messagesController.useStore.subscribe(exec);
            return function cleanup() {
              unsubscribeLocalStore();
              unsubscribeMessagesStore();
            };
          })(),
        );
        return function cleanup() {
          cancel = true;
          destroyTasks.forEach((task) => task());
        };
      })({
        containerEl,
        messagesEl,
      });
    },
    [
      containerEl,
      hideScrollToBottomButton,
      jumpToMessageController.useStore,
      localController.useStore,
      localJumpToMessageTarget,
      messagesController.computedValues,
      messagesController.selectors,
      messagesController.useStore,
      messagesEl,
      scrollToBottom,
      scrollToJumpToMessageTarget,
      sendingMessagesController.useStore,
      setupStickingToBottomTimeout,
      showScrollToBottomButton,
    ],
  );

  const fixScrollPositionHook = useMemo(() => {
    function before() {
      const jumpToMessageState = jumpToMessageController.useStore.getState();
      const jumpToMessageTarget = jumpToMessageState.target;
      if (jumpToMessageTarget) return;
      if (!containerEl || !messagesEl) return;
      const scrolledToBottom = isScrolledToBottom(containerEl);
      const containerRect = containerEl.getBoundingClientRect();
      const messagesRect = messagesEl.getBoundingClientRect();
      const messageElements = [
        ...messagesEl.querySelectorAll("[data-message-id]"),
      ];
      type ElementWithRect = {
        el: Element;
        rect: DOMRect;
      };
      const firstElement: null | ElementWithRect = !messageElements[0]
        ? null
        : {
            el: messageElements[0],
            rect: messageElements[0].getBoundingClientRect(),
          };
      let lastElementPartiallyInViewAbove: null | ElementWithRect = null;
      let firstElementInView: null | ElementWithRect = null;
      // If there is no element fully or partially in view, we should fix the
      // scroll position by detecting this.
      let fallbackElement: null | ElementWithRect = null;
      for (const messageElement of messageElements) {
        const rect = messageElement.getBoundingClientRect();
        if (
          rect.top >= containerRect.top &&
          rect.bottom <= containerRect.bottom
        ) {
          // The element is fully in view.
          firstElementInView = {
            el: messageElement,
            rect,
          };
          // Once we find the first element that is fully in view, we can
          // break.
          break;
        }
        if (
          rect.bottom >= containerRect.top &&
          rect.bottom <= containerRect.bottom
        ) {
          // Get the first element that is partially in view (above the view).
          // If got another, overwrite the previous one.
          lastElementPartiallyInViewAbove = {
            el: messageElement,
            rect,
          };
          continue;
        }

        const isAbove = rect.bottom < containerRect.top;
        const isBelow = rect.top > containerRect.bottom;
        // Use the last element that is above the view or the first element
        // that is below the view (if there is no element above the view)
        //  as the fallback element.
        if (isAbove || (!lastElementPartiallyInViewAbove && isBelow))
          fallbackElement = {
            el: messageElement,
            rect,
          };
        if (isBelow) break;
      }
      return {
        scrolledToBottom,
        firstElement,
        firstElementInView,
        lastElementPartiallyInViewAbove,
        fallbackElement,
        containerRect,
        messagesRect,
      };
    }
    const after: (prev: ReturnType<typeof before>) => void = (prev) => {
      if (scrollToJumpToMessageTarget()) {
        return;
      }
      if (!prev || !containerEl || !messagesEl) return;
      if (
        prev.scrolledToBottom &&
        !messagesController.computedValues.canTryPrevious() &&
        !localJumpToMessageTarget
      ) {
        scrollToBottom();
        return;
      }
      if (prev.firstElementInView) {
        const currentRect = prev.firstElementInView.el.getBoundingClientRect();
        if (prev.firstElementInView.rect.top === currentRect.top) return;
        scrollTo({
          top:
            containerEl.scrollTop -
            (prev.firstElementInView.rect.top - currentRect.top),
        });
        return;
      }
      if (prev.lastElementPartiallyInViewAbove) {
        const currentRect =
          prev.lastElementPartiallyInViewAbove.el.getBoundingClientRect();
        if (prev.lastElementPartiallyInViewAbove.rect.top === currentRect.top)
          return;
        scrollTo({
          top:
            containerEl.scrollTop -
            (prev.lastElementPartiallyInViewAbove.rect.top - currentRect.top),
        });
        return;
      }
      if (prev.fallbackElement) {
        const currentRect = prev.fallbackElement.el.getBoundingClientRect();
        if (prev.fallbackElement.rect.top === currentRect.top) return;
        scrollTo({
          top:
            containerEl.scrollTop -
            (prev.fallbackElement.rect.top - currentRect.top),
        });
        return;
      }
      // If there is no first element, try to keep the scroll position by
      // detecting the height change of the content.
      const messagesRect = messagesEl.getBoundingClientRect();
      if (messagesRect.height !== prev.messagesRect.height) {
        const nextTop =
          containerEl.scrollTop +
          (messagesRect.height - prev.messagesRect.height);
        scrollTo({
          top: nextTop,
        });
      }
    };
    return {
      before,
      after,
    };
  }, [
    containerEl,
    scrollToJumpToMessageTarget,
    jumpToMessageController.useStore,
    localJumpToMessageTarget,
    messagesController.computedValues,
    messagesEl,
    scrollTo,
    scrollToBottom,
  ]);

  // Fix scroll position when messages are prepended.
  messagesController.useMutationHook({
    before: fixScrollPositionHook.before,
    after: fixScrollPositionHook.after,
  });

  const setupOptions = useMemo<Parameters<typeof messages.useSetup>[0]>(
    () => ({
      ...(!localJumpToMessageTarget
        ? null
        : {
            initialData: [localJumpToMessageTarget.message],
            previousCursor: localJumpToMessageTarget.cursor?.after ?? undefined,
            nextCursor: localJumpToMessageTarget.cursor?.before ?? undefined,
          }),
      orgId,
      memberId: member.id,
      limit: API_DEFAULT_LIMIT,
    }),
    [localJumpToMessageTarget, member.id, orgId],
  );

  messages.useSetup(setupOptions);

  const chatState = useChatState(activeMember);
  const { t } = useTranslation();

  const displayMessages = useMemo<MessagesDisplayProps["displayMessages"]>(
    function computeAdditionalMessages() {
      const isWccsType = activeMember.type === "wccs";

      if (
        canTryPrevious ||
        activeMember.lastMemberMessageSend === null ||
        !(
          activeMember.type === "ig" ||
          activeMember.type === "fb" ||
          isWccsType
        ) ||
        chatState !== "CannotSendAnyContent"
      )
        return undefined;

      const getDisplayMessages: MessagesDisplayProps["displayMessages"] = ({
        history,
      }) => {
        const messageTimeWindowEndAt =
          activeMember.lastMemberMessageSend === null
            ? new Date(Number.NaN)
            : getMessageTimeWindowEndAt(
                activeMember.lastMemberMessageSend,
                isWccsType ? 30 : 7,
              );
        return [
          ...history,
          {
            id: Number.NaN,
            uuid: random(),
            channelId: activeMember.channelId,
            memberId: activeMember.id,
            memberDisplayName: activeMember.displayName,
            memberAvatar: activeMember.avatar,
            userId: null,
            userName: null,
            userStatus: null,
            userAvatar: null,
            senderType: "processing_state",
            speakerId: null,
            replyTo: null,
            quotable: false,
            type: "text",
            text: isWccsType
              ? t(
                  "chat.conversationHistoryMessages.exceedMessageWindow.alert.wccs.message",
                )
              : t(
                  "chat.conversationHistoryMessages.exceedMessageWindow.alert.message",
                ),
            previewUrl: null,
            originUrl: null,
            lineFlexContent: null,
            metadata: null,
            read: true,
            pinned: false,
            createdAt: messageTimeWindowEndAt,
            updatedAt: messageTimeWindowEndAt,
          },
        ];
      };
      return getDisplayMessages;
    },
    [
      activeMember.avatar,
      activeMember.channelId,
      activeMember.displayName,
      activeMember.id,
      activeMember.lastMemberMessageSend,
      activeMember.type,
      canTryPrevious,
      chatState,
      t,
    ],
  );

  return (
    <Outer>
      {!containerEl ? null : (
        <UsePortalSwitchHook
          element={containerEl}
          before={fixScrollPositionHook.before}
          after={fixScrollPositionHook.after}
        />
      )}
      <ScrollList
        ref={mergeRefs([containerRef, messagesController.containerRef])}
      >
        <div
          css={css({
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          })}
          ref={messagesRef}
        >
          {!messagesState.canTryNext ? null : (
            <BarLoading
              css={cssPreviousAndNextLoadingBar}
              ref={messagesController.nextRef}
            />
          )}
          <MessagesDisplay
            displayMessages={displayMessages}
            {...(!containerEl
              ? null
              : {
                  rootEl: containerEl,
                })}
          />
          {!canTryPrevious ? null : (
            <BarLoading
              css={cssPreviousAndNextLoadingBar}
              ref={messagesController.previousRef}
            />
          )}
        </div>
      </ScrollList>
      <sendingMessagesUtilities.Observer onAfterAdded={goToMessagesBottom} />
      <GoToBottomButton
        active={showingScrollToBottomButton}
        onClick={goToMessagesBottom}
      />
    </Outer>
  );
});

const WrappedHistory = memo(function WrappedHistory() {
  return (
    <messages.Provider>
      <ChatPanelHistory />
    </messages.Provider>
  );
});

export { WrappedHistory as ChatPanelHistory };
