import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { Badge } from "@/components/Badge";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import type { TabsProps } from "@/components/Tabs";
import { Tabs } from "@/components/Tabs";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { BeforeIntegrate } from "@/routes/Chat/ui/MemberProfilePanel/Membership/BeforeIntegrate";
import { Member } from "@/routes/Chat/ui/MemberProfilePanel/Membership/Member";
import { Prizes } from "@/routes/Chat/ui/MemberProfilePanel/Membership/Prizes";
import { SyncMembership } from "@/routes/Chat/ui/MemberProfilePanel/Membership/SyncMembership";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  Tabs: css({
    ".ant-tabs-tab": {
      padding: "8px 0 4px",
    },
    ".ant-tabs-tab + .ant-tabs-tab": {
      marginLeft: "12px",
    },
    ".ant-tabs-nav": {
      marginBottom: "8px",
    },
  }),
  Badge: css({
    ".ant-tabs-tab.ant-tabs-tab-active &": {
      backgroundColor: theme.colors.blue005,
      color: theme.colors.white,
    },
  }),
});

const TabBadge: FC<Omit<ComponentProps<typeof Badge>, "$variant">> = (
  props,
) => {
  return <Badge {...props} $variant="plain" css={styles.Badge} />;
};

namespace Membership {
  export interface OwnProps {}
  export type Props = Overwrite<Omit<TabsProps, "items">, OwnProps>;
}

const MembershipInternal: FC<Membership.Props> = (props) => {
  const { t } = useTranslation();
  const orgData = orgQueriesContext.useData();
  const memberId = memberIdUtils.useGet();
  const ecType = orgData.org.ecType;
  const isEcPlatformIntegrated = ecType !== null;

  const prizesQuery = cantata.commerceProfile.usePrizes(
    {
      params: {
        orgId: orgData.org.id,
        memberId,
      },
    },
    {
      enabled: isEcPlatformIntegrated,
      suspense: isEcPlatformIntegrated,
      useErrorBoundary: isEcPlatformIntegrated,
    },
  );

  const prizes = useMemo(
    () => (!prizesQuery.isSuccess ? [] : prizesQuery.data),
    [prizesQuery.data, prizesQuery.isSuccess],
  );

  const items = useMemo<TabsProps["items"]>(
    function declareItems() {
      let key = 0;
      const items: TabsProps["items"] = [
        {
          key: String(key++),
          label: t(
            "chat.memberProfilePanel.items.membership.tabs.member.label",
          ),
          children: <Member />,
        },
        {
          key: String(key++),
          label: (
            <ItemWithIcon
              gap={4}
              endIcon={
                !isEcPlatformIntegrated && !prizesQuery.isSuccess ? null : (
                  <TabBadge>{prizes.length}</TabBadge>
                )
              }
            >
              <Trans i18nKey="chat.memberProfilePanel.items.membership.tabs.prizes.label" />
            </ItemWithIcon>
          ),
          children: !isEcPlatformIntegrated ? (
            <BeforeIntegrate />
          ) : (
            <Prizes prizes={prizes} />
          ),
        },
      ];
      return items;
    },
    [isEcPlatformIntegrated, prizes, prizesQuery.isSuccess, t],
  );
  return <Tabs css={styles.Tabs} {...props} items={items} />;
};

const Wrapped: FC = () => {
  return (
    <ErrorBoundary.Alert>
      <MembershipInternal />
      <SyncMembership />
    </ErrorBoundary.Alert>
  );
};

/**
 * Present the membership information of a contact.
 */
const Membership = Object.assign(Wrapped, {
  styles,
});

assignDisplayName(Membership, "Membership");

export { Membership };
