import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { isInvalidDate } from "@chatbotgang/etude/pitch-shifter/date";
import { durationString } from "@zeffiroso/utils/debug/durationString";
import { memo } from "@zeffiroso/utils/react/memo";
import { Skeleton, Typography } from "antd";
import { format } from "date-fns";
import { type ReactNode, useMemo } from "react";

import { fbSdk } from "@/app/fbSdk";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { Alert } from "@/components/Alert";
import { CopyInput } from "@/components/Input";
import {
  expireDurationMs,
  updateIntervalMs,
  useLastActiveTime,
} from "@/init/autoExpireToken";
import { fcm } from "@/internal/firebase/firebaseMessaging";

const dateFormat = "yyyy-MM-dd HH:mm:ss.SSS";

const Item = memo(function Item({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) {
  return (
    <>
      <Typography.Title level={5}>{label}</Typography.Title>
      <Typography.Text>{value}</Typography.Text>
    </>
  );
});

const Version = memo(function Version() {
  return (
    <>
      <Item label="Version" value={<CopyInput value={__VERSION} />} />
    </>
  );
});

const FcmToken = memo(function FcmToken() {
  const query = fcm.useFcmTokenQuery();
  const requesting = fcm.useDeviceNotificationRequesting();
  return (
    <Item
      label="FCM Token"
      value={
        query.isError ? (
          <Alert type="error" message={inspectMessage`${query.error}`} />
        ) : requesting ? (
          <Skeleton.Input active />
        ) : !query.data ? (
          <>{EMPTY_STRING_PLACEHOLDER}</>
        ) : (
          <CopyInput value={query.data} />
        )
      }
    />
  );
});

const ActiveTime = memo(function ActiveTime() {
  const lastActiveTime = useLastActiveTime();
  const isValid = useMemo(
    () => !isInvalidDate(lastActiveTime),
    [lastActiveTime],
  );
  const lastActiveTimeStr = !isValid
    ? EMPTY_STRING_PLACEHOLDER
    : format(lastActiveTime, dateFormat);
  const expireTimeStr = !isValid
    ? EMPTY_STRING_PLACEHOLDER
    : format(lastActiveTime.getTime() + expireDurationMs, dateFormat);
  return (
    <Item
      label="Last Active Time"
      value={
        <div>
          <div>
            {lastActiveTimeStr}{" "}
            <i>(Update: per {durationString(updateIntervalMs)} )</i>
          </div>
          <div>
            Expired in {durationString(expireDurationMs)} ({expireTimeStr})
          </div>
        </div>
      }
    />
  );
});

const FbToken = memo(function FbToken() {
  const loginStatusQuery = fbSdk.hooks.queries.useLoginStatusQuery();
  const authResponse = fbSdk.hooks.queries.useAuthResponse();

  return (
    <Item
      label="FB Token"
      value={
        loginStatusQuery.isError ? (
          <Alert
            type="error"
            message={inspectMessage`${loginStatusQuery.error}`}
          />
        ) : loginStatusQuery.isLoading ? (
          <Skeleton.Input active />
        ) : !authResponse ? (
          <>{EMPTY_STRING_PLACEHOLDER}</>
        ) : (
          <CopyInput value={authResponse.accessToken} />
        )
      }
    />
  );
});

const Info = memo(function Info() {
  return (
    <>
      <Version />
      <ActiveTime />
      <FcmToken />
      <FbToken />
    </>
  );
});

export { Info };
