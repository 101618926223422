import { css } from "@emotion/react";
import { Skeleton } from "antd";
import { uniqBy } from "lodash-es";
import { type FC, Fragment, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { Avatar } from "@/components/Avatar";
import { Text } from "@/components/Typography";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  owners: css({
    display: "grid",
    gridTemplateColumns: "min-content auto auto",
    alignItems: "center",
    gap: 24,
    "&>*": {
      minWidth: 0,
    },
  }),
});

const OwnerInfo: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.user.useList({
    params: {
      orgId,
    },
  });

  const owners = useMemo(() => {
    if (!query.isSuccess) return [];
    return uniqBy(
      query.data.users.filter(
        (user) => user.status === "active" && user.roleType === "owner",
      ),
      (user) => user.id,
    );
  }, [query.data?.users, query.isSuccess]);

  if (query.isError)
    return <Alert type="error" message={query.error.message} />;

  return (
    <div css={styles.owners}>
      {query.isLoading ? (
        <>
          <Skeleton.Avatar size={32} />
          <Skeleton.Input size="small" />
          <Skeleton.Input size="small" />
        </>
      ) : owners.length === 0 ? (
        <Text>{EMPTY_STRING_PLACEHOLDER}</Text>
      ) : (
        owners.map((owner) => (
          <Fragment key={owner.id}>
            <Avatar src={owner.avatar || ""} />
            <Text>{owner.name}</Text>
            <Text>{owner.email}</Text>
          </Fragment>
        ))
      )}
    </div>
  );
};

export { OwnerInfo };
