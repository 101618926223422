import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef, ReactNode } from "react";

import { defineStyles } from "@/shared/emotion";
import { Empty as EmptyIcon } from "@/shared/icons/deprecated/Empty";

const styles = defineStyles({
  NotFoundContent: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
    textAlign: "center",
    fontSize: "0.75rem",
    color: theme.colors.neutral007,
  }),
});

namespace NotFoundContent {
  export type Ref = ElementRef<"div">;
  export interface OwnProps {
    message: ReactNode;
  }
  export type Props = Overwrite<ComponentProps<"div">, OwnProps>;
}

const NotFoundContentInternal = forwardRef<
  NotFoundContent.Ref,
  NotFoundContent.Props
>(function NotFoundContent({ message, ...props }, ref) {
  return (
    <div css={styles.NotFoundContent} ref={ref} {...props}>
      <div>
        <EmptyIcon />
      </div>
      <div>{message}</div>
    </div>
  );
});

const NotFoundContent = Object.assign(NotFoundContentInternal, {
  styles,
});

assignDisplayName(NotFoundContent, "NotFoundContent");

export { NotFoundContent };
