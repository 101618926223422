import { type FC, Fragment, type ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMatches } from "react-router-dom";

import { ErrorFallbackPage } from "@/internal/PageErrorBoundary/ErrorFallbackPage";
import { useCheckRoutePermission } from "@/router/utils";

/**
 * Should matched all permission and feature flags to view the page.
 */
const PermissionProtected: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const checkPermission = useCheckRoutePermission();
  const matches = useMatches();
  const permitted = useMemo(
    () =>
      matches.length === 0 ||
      matches.every((match) => checkPermission(match.pathname)),
    [checkPermission, matches],
  );
  if (!permitted)
    return (
      <ErrorFallbackPage
        title={t("errorBoundary.title.permissionDeny")}
        description={Fragment}
        action={null}
      />
    );
  return children;
};

export { PermissionProtected };
